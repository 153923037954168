/* ---------------------------------- desktop ---------------------------------- */

.loading {
  span {
    font-size: 20px;
  }
}

.loading-container {
  position: fixed;
  top: 50%;
  left: 60%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-size: 20px;
  text-transform: none;
  font-weight: bold;

  transform: translate(-50%, -50%);

  & > span {
    width: 70%;

    text-transform: lowercase;
    text-align: center;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.loading__spinner div {
  position: absolute;
  top: 67.10000000000001px;
  left: 106.7px;

  width: 6.6000000000000005px;
  height: 15.400000000000002px;

  background: #1672ec;
  border-radius: 3.3000000000000003px / 4.620000000000001px;

  animation: loading__spinner linear 1s infinite;
  transform-origin: 3.3000000000000003px 42.900000000000006px;
}

.loading__spinner div:nth-child(1) {
  background: #1672ec;

  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
}

.loading__spinner div:nth-child(2) {
  background: #1672ec;
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;

}.loading__spinner div:nth-child(3) {
  background: #1672ec;

  transform: rotate(60deg);
  animation-delay: -0.75s;
}

.loading__spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #1672ec;
}

.loading__spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #1672ec;
}

.loading__spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #1672ec;
}

.loading__spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #1672ec;
}

.loading__spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #1672ec;

}.loading__spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #1672ec;
}

.loading__spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #1672ec;
}

.loading__spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #1672ec;
}

.loading__spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #1672ec;
}

.loading__circle {
  width: 220px;
  height: 220px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.loading__spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; 
}

.loading__spinner div { 
  box-sizing: content-box; 
}

@keyframes loading__spinner {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .loading-container {
    position: fixed;
    top: 80%;
    left: 50%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  
    font-size: 20px;
    text-transform: none;
    font-weight: bold;
  
    transform: translate(-50%, -50%);
  
    & > span {
      width: 70%;
      text-align: center;
    }
  }
}
