/* ---------------------------------- desktop ---------------------------------- */

.header {
  --searchSize: 35px;

  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
  padding: 15px 25px;

  border-bottom: 2px solid $color-popup-border;
  background-color: rgba(9%, 45%, 93%, 0.05);
}

.header__menu-button {
  display: none;
}

.header__heading {
  position: absolute;
  left: 25px;
  top: 50%;

  display: block;
  width: fit-content;
  padding: 0;
  margin: 0;
  margin-right: 50px;

  color: $color-font-interactive;
  font-size: 24px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: $default-transition-settings;
  transform: translateY(-50%);

  &--hidden {
    opacity: 0;
  }
}

.header__interactive-container {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  margin-right: 32px;
}

.header__search-container {
  flex-grow: 1;
  margin-right: 20px;
}

.header__search-bar {
  position: relative;

  display: flex;
  align-items: center;
  width: calc(var(--searchSize) + 5px);
  height: calc(var(--searchSize) + 5px);
  padding-right: 2px;
  margin-left: auto;

  border: 2px solid transparent;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;

  &:focus-within {
    width: 100%;

    border: 2px solid $color-font-aside;
    background-color: #fff;

    .header__search-button {
      background-color: $color-font-interactive;

      svg {
        stroke: #fff;
      }

      &:hover,
      &:focus {
        box-shadow: 0 0 5px rgba(7, 11, 212, 0.65);
      }

      &:active {
        box-shadow: 0 0 6px rgba(7, 11, 212, 0.95);
      }
    }

    .header__search {
      &:hover + .header__search-button {
        svg {
          stroke: #ffffff;
        }
      }
    }
  }
}

.header__search {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  height: var(--searchSize);
  width: 85%;
  padding: 0 1em;

  font-size: inherit;
  line-height: var(--searchSize);

  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover + .header__search-button {
    svg {
      stroke: $color-font-interactive;
    }
  }

  &:focus {
    outline: none;
    cursor: initial;
  }
}

.header__search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--searchSize) - 5px);
  height: calc(var(--searchSize) - 5px);
  margin-left: auto;

  font-size: 0;

  border: 0;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  transition: all 150ms ease-in-out;

  svg {
    stroke: $color-icon-common;
    transition: all 150ms ease-in-out;
  }
}

.header__language-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 32px;

  &::after {
    content: "";

    position: absolute;
    right: 0;
    top: 50%;

    display: block;
    width: 1px;
    height: 32px;
    background-color: $color-background-disabled;

    transform: translateY(-50%);
  }
}

.header__language-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  min-width: 115px;
  padding: 0;
  margin: 0;

  color: #000;
  word-wrap: normal;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: $default-transition-settings;

  svg {
    height: 25px;
    width: auto;

    margin-right: 10px;
    transition: $default-transition-settings;
  }

  &--active {
    svg {
      fill: $color-font-interactive;
    }
  }

  &:hover,
  &:focus {
    color: $color-font-interactive;

    svg {
      fill: $color-font-interactive;
    }
  }
}

.header__language-selector {
  position: absolute;
  top: 50%;
  right: -20%;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 4px;
  background-color: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  transform: translate(-50%, -50%);
  z-index: 1;

  button {
    height: auto;

    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(9%, 45%, 93%, 0.1);
    }

    &:disabled {
      color: #ffffff;
      background-color: $color-font-interactive;
    }
  }
}

.header__user-popup-button {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  padding: 0;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  span {
    font-size: 14px;
  }

  &:hover,
  &:focus {
    .header__user-name {
      color: $color-font-interactive;
    }

    .header__user-avatar {
      border-color: $color-font-interactive;
    }
  }
}

.header__user-name {
  margin-right: 14px;

  transition: $default-transition-settings;
}

.header__user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  padding: 5px;

  border: 2px solid $color-font-passive;
  border-radius: 50%;
  background-color: #ffffff;
  overflow: hidden;

  transition: $default-transition-settings;

  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
}

.header__user-dialog-container {
  position: absolute;
  top: 85px;
  right: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 350px;
  padding: 25px 0;

  border-radius: 10px;
  background-color: #fff;
  box-shadow: (0px 0px 12px rgba(0, 0, 0, 0.42));
  z-index: 46;

  & > .header__user-avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
  }

  & > .header__user-name {
    margin: 0;
    margin-bottom: 5px;
    font-weight: bold;
  }

  & > .header__user-email {
    max-width: 100%;
    margin-bottom: auto;
    padding: 0 20px;

    word-break:break-all;
    text-align: center;
  }

  & > .header__buttons-container {
    width: 100%;

    border-top: 1px solid $color-popup-border;
  }

  .header__user-button,
  .header__user-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;

    background-color: #fff;
  }

  .header__user-link {
    color: $color-font-aside;

    transition: $default-transition-settings;

    &:hover,
    &:focus {
      background-color: $color-popup-border;
    }

    &:active {
      background-color: $color-teacher-timetable-border;
    }
  }

  .header__user-button {
    width: 60%;
    margin: 0 auto;
    margin-top: 1rem;

    border-radius: 8px;
    border: 1px solid $color-popup-border;
    outline: none;
    cursor: pointer;

    transition: $default-transition-settings;

    &:hover,
    &:focus {
      background-color: $color-popup-border;
      border-color: $color-timetable-border;
    }

    &:active {
      background-color: $color-teacher-timetable-border;
    }
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .header__heading {
    max-width: 263px;

    white-space: nowrap;
    overflow: hidden;
    font-size: 20px;
    text-overflow: ellipsis;

    transition: $default-transition-settings;

    &--hidden {
      opacity: 0;
    }
  }

  .header__language-container {
    padding-right: 10px;
  }

  .header__interactive-container {
    margin-right: 10px;
  }

}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .header {
    position: relative;

    justify-content: flex-start;
    padding: 15px;
    margin-bottom: 0;

    background-color: $color-background-card;
  }

  .header__heading {
    position: relative;
    left: 0;

    min-width: 120px;
    max-width: 40%;
    margin-right: auto;

    font-size: 16px;

    text-overflow: initial;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header__menu-button {
    display: block;
    width: 22px;
    height: 22px;
    padding: 0;

    font-size: 0;

    border: none;
    background-color: transparent;
    outline: none;
  }

  .header__interactive-container {
    width: auto;
    flex-grow: 0;
    margin: 0;
  }

  .header__notification-container {
    margin: 0;
    margin-right: 1rem;
  }

  .header__language-container {
    padding: 0;
    margin-right: 1rem;
  }

  .header__language-button {
    height: 30px;
    width: 30px;
    min-width: auto;
    margin: 0;
    padding: 0;

    font-size: 0;

    svg {
      height: 28px;
      width: 28px;
      margin: 0;
    }
  }

  .header__language-selector {
    left: 35%;

    width: 35px;
  }

  .header__search-container {
    display: none;
  }

  .header__user-button {
    margin-right: 1rem;
  }

  .header__user-name {
    display: none;
  }

  .header__user-dialog-container {
    .header__user-name {
      display: block;
    }
  }

  .header__user-avatar {
    width: 30px;
    height: 30px;
  }
}