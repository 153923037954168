// screen dimensions
//---------------------------------

$viewport--sm: 320px;
$viewport--md: 768px;
$viewport--lg: 1024px;
$viewport--xl: 1440px;

// retina
//---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

// primary colors
//---------------------------------

$color-font-main: #363740;
$color-font-additional: #5b5b5b;
$color-font-interactive: #1672ec;
$color-font-interactive-dark: #0e51aa;
$color-font-interactive-hover: #ecf4fd;
$color-font-aside: #6a6c7e;
$color-font-passive: #bdbdbd;
$color-font-success: #2ab930;
$color-font-error: #b92a2a;
$color-font-warning: #e16c00;
$color-font-popup: #555555;
$color-font-disabled: #1b1b1b;
$color-font-warning-header: #414141;
$color-font-warning-main: #818181;
$color-font-calendar-disabled: #e4e2e2;
$color-sample-font: #2c2c2c;

$color-background-success: #d3eed4;
$color-background-error: #eed3d3;
$color-background-salary: #e0eaf2;
$color-background-input: #eeeef8;
$color-background-disabled: #ededed;
$color-background-table-hours: #C6CAEE;
$color-background-table-day-header: #363740;
$color-background-table-class-header: #8A8CA3;
$color-background-table-confirm-btn: #00CE46;
$color-background-datepicker: #f5f5f5;
$color-background-datepicker-disabled: #fafafa;
$color-background-attandance: #898ca3;
$color-background-attandance-table: #f9f9f9;
$color-background-notification: #0978BF;
$color-background-card: #f8f8f8;

$color-status-confirmed: #1ec103;
$color-status-progress: #e09400;

$color-table-border: #f4f4f4;
$color-timetable-border:#afafca;
$color-input-hover: #8ae1fc;
$color-popup-border: #eeeeee;
$color-teacher-timetable-border: #dedede;
$color-diagram-stats: #72CD75;
$color-weekday-in-calendar: #777777;
$color-day-in-calendar: #333333;
$color-day-in-calendar-border: #eaeaea;
$color-calendar-switcher-disabled: #a6a6a6;


// popup buttons

$color-warning: #c20000;
$color-confirme: #00ce46;
$color-icon-common: #c5c7Cd;
$color-icon-active: #8ac4ff;

$color-require: #f83232;
$color-wrong-pattern: #ff0000;

// basic fonts
//---------------------------------

$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-regular: 16px;
$font-size-big: 18px;

// animations
//---------------------------------

$default-transition-settings: 0.35s ease all;
$modal-transition-settings: 0.6s cubic-bezier(0.55, 0, 0.1, 1);