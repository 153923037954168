/* ---------------------------------- desktop ---------------------------------- */

.authorization-section {
  position: absolute;
  right: -15px;
  left: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  height: 100vh;
  padding: 0;

  background: linear-gradient(60deg, $color-font-interactive 0%, rgba(56%, 73%, 96%, 1) 100%);
  color:white;

  overflow: hidden;
}

.authorization-section__login-container {
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-top: auto;
  // min-height: 500px;
  // padding-bottom: 3rem;

  // background-color: #fff;
  // border-radius: 7px;
  // box-shadow: 1px 1px 4px #428DF0;

  svg {
    margin-bottom: 2rem;

    & > #background {
      fill: transparent;
    }

    & > .stethoscope {
      fill: #fff;
      filter: drop-shadow(0 0 1px $color-font-main);
    }
  }

  button {
    width: 70%;
    margin: 0 auto;

    text-transform: none;
  }

  & > svg {
    margin-bottom: auto;

    border-radius: 7px;
  }
}

.authorization-section__salutation {
  padding: 0 1rem;
  margin-bottom: 3rem;
  text-align: center;

  span {
    text-align: center;
    color: #fff;
    font-size: 18px;
  }

  h1 {
    margin: 0;
    margin-bottom: 1rem;

    font-size: 24px;
    text-align: center;
    color: #fff;
  }
}

.authorization-section__description-container {
  width: 300px;
  min-height: 300px;

  color: #fff;

  h2 {
    margin: 0;
    margin-bottom: 1rem;
  }

  span {
    display: block;
    margin-bottom: 1rem;
    opacity: 0.7;
  }

  a {
    color: #fff;
    text-decoration: none;

    transition: $default-transition-settings;

    &:hover,
    &:focus {
      color: $color-input-hover;
    }
  }
}

.authorization-section__waves-container {
  width: 100%;

  & > .waves {
    position:relative;
    width: 100%;
    height: 15vh;
    margin-bottom:-5px;
    min-height: 100px;
    max-height: 250px;
  }

  /* Animation */

  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% { 
    transform: translate3d(85px, 0, 0);
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .authorization-section {
    position: relative;
    right: 0;
    top: 0;
  }

  .authorization-section__waves-container {
    width: 100%;

    & > .waves {
      height: 20vh;
      margin-bottom: -12px;
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .authorization-section {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .authorization-section__login-container {
    width: 100%;
    margin-bottom: auto;

    border-radius: 0;
    box-shadow: none;
    background-color: transparent;

    svg {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 2rem;

      & > #background {
        fill: transparent;
      }

      & > .stethoscope {
        fill: #fff;
        filter: drop-shadow(0 0 1px $color-font-main);
      }
    }

    & > svg {
      border-radius: 0;
    }
  }

  .authorization-section__salutation {
    span {
      color: #fff;
      font-size: 16px;
    }
  
    h1 {
      font-size: 20px;
      color: #fff;
    }
  }

  .authorization-section__description-container {
    display: none;
  }

  .authorization-section__waves-container {
    width: 100%;
    margin-top: auto;
  }
}
