/* ---------------------------------- desktop ---------------------------------- */

.lessons-section__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.lessons__filters-container {
  position: relative;
  z-index: 4;

  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-grow: 1;

  & > div {
    flex-grow: 1;
    min-width: 240px;
    max-width: 240px;
  }

  & > .input-container {
    min-width: 200px;
  }
}

.lessons__list-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: auto;

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin: 0;

    transform: translate(-50%, -50%);
  }
}

.lessons__consolidate-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr clamp(70px, 8vw, 200px) clamp(70px, 8vw, 200px) clamp(80px, 15vw, 100px);
  align-items: center;
  padding: 8px;

  text-align: center;

  border-bottom: 1px solid $color-table-border;

  &:nth-child(2n) {
    background-color: $color-background-datepicker-disabled;
  }

  &:not(:first-child):hover {
    background-color: $color-font-interactive-hover;
    transition: $default-transition-settings;
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 5px;

    font-size: 16px;
    color: $color-font-interactive-dark;
    text-transform: capitalize;
    word-break: break-all;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-size: 16px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  span {
    padding: 0 5px;
    
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      overflow: visible;
      text-overflow: inherit;
    }

    & .table__actions-sample {
      justify-content: center;
    }

    & > svg {
      width: 25px;
      height: auto;

      fill: transparent;
    }
  }
}

.lessons__sample-list {
  position: absolute;
  left: -150%;
  top: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  width: fit-content;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.lessons__sample-item {
  display: flex;
  align-items: center;
  width: 170px;
  height: 42px;
  padding: 8px 11px;

  border-bottom: 1px solid $color-table-border;
  background-color: #ffffff;

  &:last-child {
    border-bottom: none;
  }
}

.lessons__sample-button {
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  color: black;
  font-size: 14px;

  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  transition: $default-transition-settings;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    
    fill: $color-font-passive;

    transition: $default-transition-settings;
  }

  span {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:hover,
  &:focus {
    color: $color-font-interactive;

    svg {
      fill: $color-font-interactive;
    }
  }

  &--stroke {
    svg {
      stroke: $color-font-passive;
      fill: transparent;
    }

    &:hover,
    &:focus {
      color: $color-font-interactive;
  
      svg {
        stroke: $color-font-interactive;
        fill: transparent;
      }
    }
  }
}

.lessons__popup-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 450px;
  padding: 2rem;
}

.lessons__pagination {
  margin-top: 1rem;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .lessons-section__header {
    padding: 1rem;
  }

  .lessons__filters-container {
    width: 100%;

    & > .input-container {
      width: 100%;
      max-width: 100%;

      & > label {
        text-align: center;
      }
    }
  }

  .lessons__consolidate-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    min-height: 40px;
    max-width: 100%;

    text-align: center;
    border-bottom: 1px solid #f4f4f4;

    &:first-child {
      display: none;
    }

    & > div,
    & > span {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      min-height: 30px;
      padding-left: 30%;
      padding-right: 10px;

      &::after {
        content: attr(data-name);
  
        position: absolute;
        top: 0;
        left: 10px;
  
        display: flex;
        align-items: center;
        width: 30%;
        height: 100%;
        padding-right: 10px;
        white-space: nowrap;

        font-weight: bold;
        color: $color-font-interactive;
        text-transform: capitalize;
      }
    }
  }

  .lessons__sample-list {
    left: 50%;

    transform: translateX(-50%);
  }

  .lessons__sample-item {
    padding: 1.5rem;
  }
}