/* ---------------------------------- desktop ---------------------------------- */

.further-timetable {
  position: relative;
  top: -62px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-bottom: -62px;
  padding-bottom: 25px;

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;
    margin-top: auto;

    transform: translate(-50%, -50%);
  }
}

.further-timetable__content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.further-timetable__header-button-container {
  display: flex;
  align-items: flex-end;
  margin: 0 5px;
  margin-top: 1rem;
}

.further-timetable__confirme-container {
  display: flex;
  padding: 10px 5px;
  width: 100%;
}


.further-timetable__table-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 100%;
  min-height: 100%;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;

    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.further-timetable__clear-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 5px;

  z-index: 5;
}

.further-timetable__list-container {
  flex-grow: 1;
  min-width: 300px;
  max-width: 550px;

  h2 {
    margin: 0;
    margin-bottom: 1rem;

    text-align: center;
  }
}

.further-timetable__list {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.further-timetable__item {
  width: 100%;
}

.further-timetable__lesson-card {
  position: relative;

  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: 60px;
  padding: 8px;

  border-radius: 8px;
  border: none;
  outline: none;

  cursor: pointer;

  &::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    border-radius: 5px;
    background: inherit;

    opacity: 1;
    z-index: -1;

    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    &::before {
      filter: blur(3px);
    }
  }
}

.further-timetable__lesson-time {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  padding: 5px 15px;

  font-size: 14px;
  font-weight: bold;

  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.7);
}

.further-timetable__card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 8px;

  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);

  span {
    position: relative;

    min-width: 30px;
    max-width: 75px;
    width: 33%;

    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    color: #000;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;

    &:first-child {
      width: auto;
    }

    &:last-child {
      min-width: 72px;
    }

    &:nth-child(2) {
      margin: 0 5px;
    }
  }
}

.further-timetable__popup {
  display: flex;
  flex-direction: column;
  min-height: 620px;
  min-width: 840px;
  padding: 0;

  h4 {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: 2rem;

    font-size: 16px;
    text-transform: uppercase;

    span {
      width: 100%;

      font-size: 14px;
      text-transform: none;
      color: $color-font-additional;
    }
  }
}

.further-timetable__popup-from {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.further-timetable__fieldset-container {
  flex-grow: 1;
}

.further-timetable__popup-fieldset {
  max-width: 100%;

  &:first-child {
    margin-bottom: 2rem;
  }
}

.further-timetable__popup-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.further-timetable__card-exam {
  position: absolute;
  right: 10px;
  top: -13px;

  display: flex;
  padding: 4px 6px;
  

  font-weight: bold;
  text-transform: uppercase;

  border: 2px solid inherit;
  border-radius: 5px;

  span {
    padding: 3px 5px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);

    color: #fff;
  }
}

.further-timetable__input-item {
  flex: 1;
  flex-grow: 1;
  min-width: 250px;
  max-width: 100%;

  &--module {
    flex-grow: 1;
    max-width: 100%;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-color: transparent;
  }

  &--time {
    min-width: 155px;
  
    .react-datepicker {
      & > .react-datepicker__triangle {
        &::before {
          display: none;
        }
      }
    }

    .react-datepicker__time-container {
      width: 155px;

      .react-datepicker__header {
        background-color: $color-background-input;
      }
  
      .react-datepicker__time-box {
        width: 100%;

        ul.react-datepicker__time-list {
          max-height: 100px;
          &::-webkit-scrollbar {
            width: 10px;
            height: 5px;

            cursor: pointer;
          }
        
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
        
          &::-webkit-scrollbar-thumb {
            background: $color-font-interactive;
          }
        
          &::-webkit-scrollbar-thumb:hover {
            background: $color-font-interactive;
          }
          
          li.react-datepicker__time-list-item {
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              transition: $default-transition-settings;
              background-color: $color-font-interactive-hover;
            }
          }

          li.react-datepicker__time-list-item--disabled {
            display: none;
          }

          li.react-datepicker__time-list-item--selected {
            background-color: $color-font-interactive;

            &:hover {
              background-color: $color-font-interactive;
            }
          }
        }
      }
    }
  }
}

.further-timetable__button-container {
  display: flex;
  gap: 1rem;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .further-timetable__table-container {
    justify-content: space-between;
    gap: 10px;
  }

  .further-timetable__list-container {
    margin-right: 0;
  }

  .further-timetable__lesson-time {
    padding: 5px;
    font-size: 13px;
  }

  .further-timetable__popup {
    display: flex;
    flex-direction: column;
    min-height: 95vh;
    min-width: 600px;
  }

  .further-timetable__popup-fieldset {  
    &:nth-child(2) {
      width: 100%;
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .further-timetable{
    top: 0;
    margin-bottom: 0;
  }

  .fulltime-timetable__header-button-container {
    width: 100%;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .further-timetable__content-container {
    padding: 0 15px;
  }

  .further-timetable__list-container {
    width: 100%;

    h2 {
      margin-top: 0;
      text-align: center;
    }
  }

  .further-timetable__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .further-timetable__popup-list {
    width: 100%;
    justify-content: center;
  }

  .further-timetable__input-item {
    width: 100%;
    max-width: 350px;
    margin-right: 0;

    &--time {
      width: 100%;
    }
  }
  
  .further-timetable__popup {
    min-width: auto;
    width: 100%;
    padding-bottom: 0;
  }

  .further-timetable__button-container {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
  }

  .further-timetable__header-button-container {
    width: 100%;
    margin: 1rem 0;
    justify-content: center;
  }

  .further-timetable__confirme-container {
    display: flex;
    justify-content: center;
  }

  .further-timetable__card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 8px;
  
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
  
    span {
      max-width: 60px;
    }
  }

  .further-timetable {  
    .loading-container {
      padding-top: 4rem;
    }
  }
}
