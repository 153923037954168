/* ---------------------------------- desktop ---------------------------------- */

.weekpicker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  max-height: 63px;
  padding: 5px;

  border: 2px solid $color-font-calendar-disabled;
  border-radius: 5px;
}

.weekpicker__content-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.weekpicker__header {
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;

  svg {
    position: absolute;
    left: 3px;
    top: 50%;

    display: block;
    width: 25px;
    height: 25px;
    background-color: transparent;

    transform: translateY(-50%);
    pointer-events: none;

    transition: $default-transition-settings;
  }

  .react-datepicker__input-container {
    margin-right: 10px;
    background-color: transparent;

    input {
      width: 150px;
      height: 30px;
      font-size: 0;
      border: none;
      outline: none;

      cursor: pointer;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, 
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border: none;
  }

  .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 0;
    border-radius: 8px;

    & > .react-datepicker__day {
      margin: 0;
    }

    &:hover {
      background-color: $color-font-interactive;
      cursor: pointer;

      .react-datepicker__day {
        color: #fff;
      }
    }
  }

  .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    border-radius: 0;

    &--selected {
      background-color: $color-font-interactive;
    }

    &--range-start {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &--range-end {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: $color-font-interactive;
    }
  }

  .react-datepicker__day--selected, 
  .react-datepicker__day--in-selecting-range, 
  .react-datepicker__day--in-range, 
  .react-datepicker__month-text--selected, 
  .react-datepicker__month-text--in-selecting-range, 
  .react-datepicker__month-text--in-range, 
  .react-datepicker__quarter-text--selected, 
  .react-datepicker__quarter-text--in-selecting-range, 
  .react-datepicker__quarter-text--in-range, 
  .react-datepicker__year-text--selected, 
  .react-datepicker__year-text--in-selecting-range, 
  .react-datepicker__year-text--in-range {
    background-color: $color-font-interactive;
  }

  .react-datepicker__navigation {
    top: 12px;

    width: 10px;
    height: 10px;

    border: 7px solid transparent;
    transition: $default-transition-settings;

    &--previous {
      left: 5px;
      border-right-color: $color-font-passive;

      &:hover,
      &:focus {
        border-right-color: $color-font-interactive;
      }
    }

    &--next {
      right: 5px;
      border-left-color: $color-font-passive;

      &:hover,
      &:focus {
        border-left-color: $color-font-interactive;
      }
    }
  }

  .react-datepicker__header {
    padding: 0;
    background-color: #fff;
  }

  .react-datepicker__current-month {
    margin-bottom: 10px;

    color: $color-font-interactive;
  }

  .react-datepicker__month-container {
    padding: 10px;

    border: 2px solid $color-font-interactive-hover;
    box-shadow: (0px 0px 12px rgba(14, 81, 170, 0.3));
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    padding: 5px;

    border: 1px solid $color-font-passive;
    outline: none;

    transition: $default-transition-settings;

    &:hover,
    &:focus {
      border-color: $color-font-interactive;
    }
  }

  .react-datepicker__header__dropdown {
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding-left: 10px;
  }

  .react-datepicker__day-name {
    font-weight: bold;
    color: $color-font-interactive;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: black;

    &:hover {
      background-color: $color-font-interactive;
    }
  }

  .react-datepicker__year-select {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $color-font-interactive;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: $color-font-interactive;
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 40px;

    color: $color-font-interactive;
    font-weight: bold;

    transform: translateY(-50%);
    pointer-events: none;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  &:hover + svg {
    fill: $color-font-interactive;
  }
}

.react-datepicker__special-button {
  position: absolute;
  right: 25px;
  top: 37px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;

  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    & > svg {
      transition: $default-transition-settings;
      stroke: $color-font-interactive;
    }
  }
}

.weekpicker__horizontal-datepicker {
  position: relative;

  display: flex;
  align-items: center;
  margin: 0;

  label {
    width: 205px;
    margin-bottom: 0;

    font-size: inherit;
    font-weight: bold;
    text-align: center;

    cursor: pointer;
  }
}

.weekpicker__horizontal-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive;
    }
  }

  svg {
    width: 18px;
    height: auto;

    fill: $color-font-main;
    transition: $default-transition-settings;
  }

  &:disabled {
    svg {
      fill: $color-font-calendar-disabled;
    }

    pointer-events: none;
  }

  &:first-child {
    svg {
      transform: rotate(180deg);
    }
  }
}

.weekpicker__date-picker {
  background-color: transparent;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .weekpicker {
    max-width: 100%;
    margin: 0 auto;
  }

  .weekpicker__header {
    justify-content: center;

    span {
      left: 40%;
    }

    svg {
      left: 25%;
    }
  }

  .react-datepicker-popper {
    position: absolute;

  }
}