/* ---------------------------------- desktop ---------------------------------- */

.attendance-section {
  height: 100%;
  padding-bottom: 1rem;
}

.attendance__content-container {
  .loading-container {
    position: relative;
    top: 40%;
    left: 50%;

    margin-bottom: auto;

    transform: translate(-50%, -50%);
  }
}

.attendance__header {
  position: relative;

  display: grid;
  grid-template-columns: 1fr 285px;
  gap: 1rem;
  margin-bottom: 2rem;
}

.attendance__filters-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  & > .input-container {
    flex-grow: 1;
    max-width: 220px;
    min-width: 150px;
  }
}

.attendance__switcher-container {
  position: absolute;
  top: 0;
  right: 10px;

  display: none;
  justify-content: flex-end;
  width: fit-content;

  button {
    min-width: 45px;
    min-height: 30px;
    padding: 5px;

    font-size: 14px;
  }
}

.attendance__table-pagination-container {
  width: 100%;
}

.attendance__table-pagination-data {
  width: fit-content;

  border: 1px solid $color-table-border;
  border-radius: 3px;
}

.attendance__pagination-button,
.attendance__pagination-switcher {
  width: 30px;
  height: 30px;

  font-weight: bold;

  border: 1px solid $color-table-border;
  border-radius: 3px;
  background-color: #fff;

  cursor: pointer;
  outline: none;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    background-color: $color-font-interactive-hover;
  }

  &:active {
    box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
  }

  &:disabled {
    color: #fff;
    background-color: $color-font-interactive;
  }
}

.attendance__pagination-switcher {
  width: auto;
  padding: 0 15px;

  &:disabled {
    color: #fff;
    background-color: $color-font-passive;

    opacity: 0.6;
  }
}

.attendance__content-container {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;

  .void {
    flex-grow: 1;
  }
}

.attendance__filter-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 60%;
  margin-right: auto;

  .input-container {
    margin-right: 1rem;
    margin-bottom: 1rem;

    &:first-child {
      .attendance__selector-container {
        width: 200px;
      }
    }

    &:nth-child(2) {
      .attendance__selector-container {
        width: 160px;
      }
    }
  }

  .weekpicker {
    margin-left: auto;
  }
}

.attendance__selector-container {
  width: 120px;
}

.attendance-timetable__table-container {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 1rem;

  overflow-x: auto;
  z-index: 0;

  & > .pagination {
    margin-top: 1rem;
  }
}

.attendance-timetable__table {
  max-width: 100%;
  margin-bottom: auto;

  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  tbody, thead {
    border: none;

    & > tr {
      transition: $default-transition-settings;

      &:nth-child(2n) {
        background-color: $color-background-datepicker-disabled;
      }

      &:hover {
        background-color: $color-font-interactive-hover;
      }
    }
  }
}

.attendance-timetable__th,
.attendance-timetable__td {
  width: 9vw;
  max-width: 135px;
  min-width: 50px;
  padding: 0;

  transition: $default-transition-settings;

  span {
    display: block;
    max-width: 90%;

    font-size: 12px;
  }

  &:first-child {
    max-width: clamp(110px, 10vw, 200px);
    padding-left: 10px;

    z-index: 1;

    span {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden; 
      text-overflow: ellipsis;
    }
  }

  &--lesson {
    background-color: $color-font-interactive !important;

    .attendance-timetable__lesson-info-button {
      svg {
        fill: #fff;
      }
    }
  }
}

.attendance-timetable__th {
  background-color: $color-background-card;

  &:first-child {
    border: none;
    border-left: 1px solid transparent;
    background-color: #fff;
  }

  &:not(:first-child) {
    padding: 0;

    cursor: pointer;
  }

  &:nth-child(2n) {
    background-color: $color-font-calendar-disabled;
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 5px;
    color: $color-font-main;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $color-font-interactive;
    }
  }
}

.attendance-timetable__td {
  position: relative;

  &:first-child {
    color: $color-font-interactive;
  }
}

.attendance-timetable__group {
  position: absolute;
  top: 50%;
  left: -1px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100%);
  width: 15px;

  color: #fff;

  background-color: $color-font-interactive;
  border: 1px solid transparent;
  border-left: none;
  border-right: none;

  transform: translateY(-50%);
}

.attendance-timetable__th-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  margin: 0;

  background-color: inherit;
  border: none;
  outline: none;

  transition: $default-transition-settings;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $color-font-interactive;
    color: #fff;
  }

  span {
    &:nth-child(2) {
      font-size: 13px;
      font-weight: bold;
    }

    &:nth-child(3) {
      font-size: 10px;
      font-weight: normal;
    }
  }
}

.attendance-timetable__lesson-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
}

.attendance-timetable__lesson-info {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.attendance-timetable__lesson-info-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
    outline: none;

    transition: $default-transition-settings;

    &:focus {
      fill: $color-font-interactive;
    }
  }

  &:hover {
    svg {
      fill: $color-font-interactive-dark;
    }
  }

  &:focus {
    svg {
      fill: $color-font-interactive;
    }
  }

  &--present {
    svg {
      fill: $color-font-success;
    }
  }

  &--absent {
    svg {
      fill: $color-font-error;
    }
  }

  &--excused {
    svg {
      fill: $color-font-warning;
    }
  }
}

.attendance-timetable__lesson-sample {
  position: absolute;
  top: -46px;
  left: calc(50% - 125px);
  z-index: 1;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 160px;
  padding: 5px;
  border: 1px solid $color-font-passive;

  background-color: whitesmoke;
  box-shadow: (0px 0px 12px rgba(0, 0, 0, 0.42));

  transform: translateX(-50%);

  p {
    width: 90%;
    margin-bottom: 5px;
    margin-right: auto;
    padding: 0 5px;

    font-size: 12px;
    font-weight: bold;

    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;

    &:first-child {
      width: 60%;
      color: $color-font-interactive;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 35px;
    padding: 5px;

    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      outline: none;

      transition: $default-transition-settings;

      &:focus {
        fill: $color-font-interactive;
      }
    }

    &:hover,
    &:focus {
      svg {
        fill: $color-font-interactive;
      }
    }

    &:disabled {
      svg {
        fill: $color-font-success;
      }
    }
  }

  .attendance-timetable__lesson-sample-close {
    width: 20px;
    height: 20px;
  }
}

.attendance-timetable__tooltip {
  outline: none !important;
}

// popup

.attendance-section__popup {
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  .popup__header {
    padding: 1rem;
  }
}

.attendance-section__popup-content {
  flex-grow: 1;
  padding: 2rem;
  padding-top: 1rem;
}

.attendance-section__popup-info {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 90%;
  height: 40px;
  padding-right: 15px;

  p {
    font-size: 18px;
    color: $color-font-interactive;
    font-weight: 600;
  }
}

.attendance-section__info-color {
  display: flex;
  height: 100%;
  width: 5px;
  margin-right: 15px;

  background-color: $color-font-interactive;
}

.attendance-section__popup-table {
  width: 100%;
  margin-bottom: 2rem;
  border-collapse: collapse;

  tbody {
    tr {
      &:nth-child(2n + 2) {
        background-color: $color-background-input;
      }
    }
  }
}

.attendance-section__popup-th {
  padding: 0 1rem 1rem 1rem;
  text-align: left;

  &:last-child {
    text-align: right;
  }
}

.attendance-section__popup-td {
  padding: 0;
  padding-left: 1rem;

  span {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    color: $color-font-popup;
    font-weight: 500;
  }
}

.attendance-section__log-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.attendance-section__popup-fieldset {
  margin: 0;
  margin-bottom: auto;
  padding: 0;
  border: none;
}

.attendance-section__log-button {
  max-width: 200px;

  .button-loading {
    width: 50%;
  }
}

.attendance-section__input-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 5px 0;
}

.attendance__popup-radio {
  position: absolute;

  visibility: hidden;
  margin: 0;
  width: 0;
  height: 0;

  &:checked + .attendance__popup-label--present {
    svg {
      fill: $color-font-success;
    }
  }

  &:checked + .attendance__popup-label--absent {
    svg {
      fill: $color-font-error;
    }
  }

  &:checked + .attendance__popup-label--excused {
    svg {
      fill: $color-font-warning;
    }
  }

  &:disabled + .attendance__popup-label--excused {
    pointer-events: none;
  }
}

.attendance__popup-label {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 1rem;

  &:hover {
    svg {
      fill: $color-font-interactive;
    }
  }

  svg {
    width: 100%;
    height: auto;

    cursor: pointer;
    transition: $default-transition-settings;
  }

  span {
    display: block;
    width: 100%;
    height: 100%;

    font-size: 0;

    background-image: url('../../assets/hatching.png');
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .attendance-timetable__th-container {  
    span {
      &:nth-child(2) {
        font-size: 11px;
        font-weight: bold;
      }
  
      &:nth-child(3) {
        font-size: 10px;
        font-weight: normal;
      }
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .attendance-section {
    padding-bottom: 0;
  }

  .attendance__content-container {
    .loading-container {
      left: 0;
      top: 0;

      transform: translate(0);
    }
  }

  .attendance-timetable__table-container {
    padding-bottom: 0;
  }

  .attendance__table-pagination-data {
    margin: 0 auto;
  }

  .attendance__filter-container {
    width: 90%;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 10px;

    .input-container {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 1rem;

      .attendance__selector-container {
        width: 100%;
        min-width: 100%;
      }

      label {
        text-align: center;
      }
    }
  }

  .attendance__header {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    margin-bottom: 0;

    & > .weekpicker {
      order: -1;
    }
  }

  .attendance__filters-container {
    width: 100%;
    gap: 7px;

    & > .input-container {
      width: 100%;
      max-width: 100%;

      label {
        text-align: center;
      }
    }
  }

  .attendance-timetable__table {
    margin-bottom: 0;

    border-collapse: inherit;
    border-left: none;
    border-bottom: 1px solid $color-font-passive;
    border-right: 1px solid $color-font-passive;
  }

  .attendance-timetable__td {
    &:first-child {
      position: sticky;
      left: 0;
    }
  }

  .attendance-timetable__th {
    border-top: 2px solid $color-font-passive;

    &:first-child {
      border-bottom: 1px solid $color-font-passive;
    }

    &:nth-child(2) {
      border-left: 2px solid $color-font-passive;
    }
  }

  .attendance-section__popup {
    max-height: 100vh;
  }

  .attendance-timetable__lesson-sample {
    top: -50px;
    left: -25px;
  }

  .attendance__content-container {
    .void {
      padding: 2rem 0;
    }
  }

  .attendance-section__popup-info {
    max-width: 85%;
  
    p {
      font-size: 14px;
    }
  }

  .attendance-section__popup-content {
    padding: 1rem 15px;
  }

  .attendance-section__log-button {
    min-width: 200px;
    margin: 0 auto;
  }
}
