/* ---------------------------------- desktop ---------------------------------- */

.class {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.class__actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.class__content-container {
  flex-grow: 1;
  max-width: calc(100vw - 255px);
  padding-bottom: 2rem;
}

.class-section__popup-fieldset {
  width: 100%;
  margin-bottom: auto;

  .class__input-container {
    width: 45%;
  }
}

.class-section__table {
  .table__sample-list {
    top: -400%;
    right: 40px;

    &--teacher {
      top: -150%;
    }
  }
}

.class-section__popup {
  display: flex;
  flex-direction: column;

  min-height: 350px;
}

.class-section__popup-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  button {
    width: 200px;
  }
}

.class-section__edit-group-popup {
  min-height: 635px;
}

.class__input-container {
  margin-right: 25px;
  margin-bottom: 15px;
}

.class-groups__list {
  width: 100%;
}

.class-groups__item {
  width: 100%;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.class-view-section__create-group-button-container {
  margin-left: auto;
}
/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .class__content-container {
    max-width: 100%;
    padding: 0;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .class__actions-container {
    padding: 15px;
  }

  .class-view-section__create-group-button-container {
    margin-left: 0px;
    margin-top: 10px;
  }
}
