/* ---------------------------------- desktop ---------------------------------- */

.student {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
}

.student__content-container {
  flex-grow: 1;
  padding-bottom: 1rem;

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;

    transform: translate(-50%, -50%);
  }
}

.student__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.student__header-top-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;

  .buttons-container {
    display: flex;
  }
}

.student__header-button-block {
  position: absolute;
  top: -5px;
  right: 0;

  display: flex;
  justify-content: flex-end;

  & > span {
    display: none;
  }
}

.student__search-container {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
}

.student__filters-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  flex-grow: 1;
  max-width: 700px;

  & .input-container {
    flex-grow: 1;
    min-width: 220px;
  }

  & > .student__search {
    flex-grow: 3;
    width: 100%;
  }
}

.student__selector-container {
  min-width: 140px;

  .input-select__control {
    border-radius: 6px;
    background-color: $color-background-input;
  }

  .input-select__indicator {
    svg {
      fill: #252733;
    }
  }
}

.student__link-button {
  width: fit-content;
  max-width: 250px;
  margin-right: 1rem;
}

.student__button-container {
  display: flex;
  margin-top: auto;
}

.student__button {
  min-width: 110px;
  margin-right: 1rem;
}

.student__over-popup {
  position: relative;
  height: 100%;
}

.student__form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;

  fieldset {
    &:nth-last-child(2) {
      margin-bottom: 10px;
      border: none;
    }
  }
}

.student__fieldset {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0;
  padding: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;

  border: none;
  border-bottom: 2px solid #000;

  &:first-child {
    padding: 0;
    border: none;
  }

  h2 {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;

    font-size: $font-size-big;
    font-weight: bold;
  }
}

.student__input-list{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  min-width: 600px;
  margin: 0;
  padding: 0;

  list-style: none;

  &--short {
    width: 50%;
  }
}

.student__input-item {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;

  min-width: 175px;
  max-width: 275px;
  margin-bottom: 5px;

  &--short {
    width: 175px;
  }

  &--remark {
    width: 90%;
    max-width: 90%;
    
    textarea {
      height: 85px;
      margin-bottom: 1rem;
    }
  }

  &--checkbox {
    width: 100%;
    max-width: 100%;
  }

  &--module {
    max-width: none;
  }
}

.student__checkbox-label {
  display: flex;
  align-items: center;
  width: fit-content;

  margin: 8px 0;

  & > span {
    color: $color-font-interactive;
  }
}

.student__error-container {
  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  width: fit-content;
  padding: 15px;

  background-color: $color-warning;

  span {
    margin-right: 1rem;
    color: #ffffff;
  }
}

.student__error-close {
  padding: 0;

  font-size: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  transition: $default-transition-settings;

  svg {
    fill: $color-font-passive;
    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: #ffffff;
    }
  }
}

.student__checkbox {
  &:focus + label {
    span {
      color: $color-font-interactive;
    }

    .svg-checkbox__box {
      stroke: $color-font-interactive;
    }
  }

  &:checked + label {
    .svg-checkbox__box {
      fill: $color-font-interactive;
      stroke-dashoffset: 320;
    }
  
    .svg-checkbox__check {
      stroke-dashoffset: 0;
    }
  }
}

.student__checkbox-label {
  &:hover {
    .svg-checkbox__box {
      stroke: $color-font-interactive;
    }
  }
}

.student__checkbox-svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  cursor: pointer;

  .svg-checkbox__box {
    fill: #ffffff;
    stroke: $color-font-passive;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 320;
    stroke-dashoffset: 0;

    transition-delay: 0s, 0s, 0.3s;
    transition-property: none;
    transition-duration: 0.3s, 0.45s, 0.4s;
  }

  .svg-checkbox__check {
    fill: none;
    stroke: #ffffff;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 80;
    stroke-dashoffset: 80;

    transition: stroke-dashoffset 0.2s linear;
  }

  &--disabled {
    .svg-checkbox__box { 
      transition-property: none;
    }
  
    .svg-checkbox__check { 
      transition: stroke-dashoffset none;
    } 
  }
}

.student__upload-files-container {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.student__upload-files-place,
.student__upload-files-place_active,
.student__upload-files-place_error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 200px;

  background-color: transparent;
  border: 2px dashed $color-font-interactive;
  border-radius: 5px;

  cursor: pointer;
}

.student__upload-files-place {
  &:hover {
    border-color: $color-font-interactive-dark;
  }
}

.student__upload-files-place-text {
  color: $color-font-additional;
}

.student__upload-files-place-text_error {
  color: $color-font-error;
}

.student__upload-files-place-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 60px;
    height: auto;
    margin-bottom: 1rem;

    fill: $color-font-interactive;
  }
}

.student__upload-files-place_active {
  background-color: $color-input-hover;
  border: 2px dashed $color-font-interactive;
}

.student__upload-files-place_error {
  border: 2px dashed $color-font-error;
}

.student__upload-files-input {
  display: none;
}

.student__files-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: 200px;
  margin: 30px auto;
  margin-bottom: auto;
  padding-right: 5px;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.student__files-list-item {
  display: flex;
  align-items: center;
  width: 350px;
  margin-bottom: 10px;
  padding: 15px;

  background-color: $color-font-interactive-hover;

  span {
    width: 80%;

    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
  }

  .icon-document {
    width: 25px;
    margin-right: 1rem;

    fill: $color-font-interactive;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: auto;
    padding: 0;

    border: none;
    background-color: transparent;
    outline: none;

    cursor: pointer;

    &:hover,
    &:focus {
      svg {
        fill: $color-font-interactive;
      }
    }

    svg {
      width: 18px;
      height: 18px;

      fill: $color-font-passive;
      transition: $default-transition-settings;
    }
  }
}

.student__files-list-item:last-child {
  margin-bottom: 0px;
}

.student__files-list-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.student__files-list-close-btn {
  cursor: pointer;
}

.student__upload-files-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.student__upload-result-button {
  svg {
    margin-right: 1rem;

    fill: #fff;

    transform: rotate(180deg);
  }
}

.student__upload-files-cancel-btn {
  margin-left: 1rem;
}

.student__upload-modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 500px;
  min-height: 650px;
}

.student__result-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 530px;

  .title-successful {
    color: $color-font-success;
    margin-bottom: 10px;
  }

  .title-error {
    color: $color-font-error;
    margin-bottom: 10px;
  }

  .result-item {
    margin-bottom: 5px;
  }

  .row-error-block {
    margin-bottom: 5px;
    padding-left: 5px;

    div {
      &:first-child {
        color: $color-font-interactive;
        font-weight: 500;
      }
    }
  }
}

.student__result-block-text {
  padding: 20px 30px;
  max-width: 500px;
}

.student__result-block-error-msg {
  padding: 5px 0px 5px 10px;
}

.student-section__table-container {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  & > .pagination-container {
    margin-top: 1rem;
  }
}

.student-section__table {
  margin-bottom: auto;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .student__header {
    padding: 0;
    margin-bottom: 0;
  }

  .student__header-top-block {
    padding: 10px;
  }

  .student__search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    & > .input-container {
      width: 100%;
      margin: 0;
    }
  }

  .student__filters-container {
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;

    & .input-container {
      width: 100%;
      min-width: 100px;
      max-width: 100%;

      label {
        text-align: center;
      }
    }
  }
  
  .student__content-container {
    padding: 0;
  }

  .student__link-button {
    margin-right: 10px;
  }

  .student__over-popup {
    padding: 1rem 15px;
  }

  .student__fieldset {
    padding-top: 10px;
  
    h2 {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      
      font-size: $font-size-regular;
    }
  }

  .student__input-list{
    justify-content: center;
    width: 100%;
    min-width: inherit;
    margin: 0 auto;
  
    &--short {
      width: 100%;
    }
  }

  .student__input-item {
    min-width: inherit;
    width: 100%;
    max-width: 100%;
    margin: 0 8px;
  
    &--short {
      width: 100%;
    }

    &--remark {
      max-width: 100%;
    }
  }

  .student__checkbox-container {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .student__checkbox-label {
    margin: 0 auto;

    font-size: $font-size-small;
  }

  .student__button-container {
    justify-content: center;
  }

  .student__upload-files-container {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 0px;
    margin-top: 15px;
    height: 100%;
    padding-bottom: 10px;
  }

  .student__upload-files-place,
  .student__upload-files-place_active,
  .student__upload-files-place_error {
    width: 80%;
  }

  .student__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;

    .buttons-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin: 0;
    }

    .buttons-container > * {
      min-width: 260px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  .student__upload-modal {
    min-width: 100%;
    min-height: 0px;
  }

  .student__files-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50px;
    margin: 20px 0px 20px 0px;
    padding-right: 0px;
  }

  .student__files-list-item {
    width: 100%;
    max-width: 350px;
  }

  .student__result-block {
    min-height: 480px;
  }

  .student__header-button-block {
    position: relative;

    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 10px;

    & > span {
      display: block;
      margin-right: 10px;
    }
  }

  .student__search {
    margin-top: 10px;
  }
}
