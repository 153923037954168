/* ---------------------------------- desktop ---------------------------------- */

.holidays {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 25px;
}

.holidays__content-container {
  position: relative;
  z-index: 0;

  height: auto;
}

.holidays__header {
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;

  z-index: 2;
}

.holidays__selector-container {
  z-index: 5;

  .input-select__control {
    background-color: $color-background-input;
  }

  .input-select__indicator {
    svg {
      fill: #000000;
    }
  }
}

.holidays__month-select {
  width: 220px;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month-container {
    box-shadow: 1px 1px 4px 2px rgba(34, 60, 80, 0.2);
  }

  .react-datepicker__header {
    padding: 8px;

    color: $color-font-interactive;
  }

  .react-datepicker__month-text {
    padding: 5px 0;

    transition: $default-transition-settings;
  }

  .react-datepicker__month {
    transition: $default-transition-settings;

    &--selected {
      font-weight: bold;
      background-color: $color-font-interactive;
    }
  }

  .react-datepicker__navigation {
    top: 9px;

    width: 10px;
    height: 10px;

    border: 7px solid transparent;
    transition: $default-transition-settings;

    &--previous {
      left: 5px;
      border-right-color: $color-font-passive;

      &:hover,
      &:focus {
        border-right-color: $color-font-interactive;
      }
    }

    &--next {
      right: 5px;
      border-left-color: $color-font-passive;

      &:hover,
      &:focus {
        border-left-color: $color-font-interactive;
      }
    }
  }
}

.holidays__date-picker {
  width: 100%;
  height: 42px;
  padding: 10px;

  border: 1px solid $color-font-calendar-disabled;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  outline: none;
}

.holidays__calendar-switch-container {
  display: flex;
  height: 38px;
  margin-right: auto;

  button {
    text-transform: capitalize;
  }
}

.holidays__popup {
  display: flex;
  flex-direction: column;
  min-height: 550px;
}

.holidays__popup-from {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.holidays__popup-button {
  min-width: 155px;
}

.holidays__content-container {
  .rbc-calendar {
    height: 750px;
  }
}

.holidays__calendar-wrapper {
  .rbc-toolbar-label {
    font-size: 22px;
    font-weight: bold;
  }

  .rbc-month-view {
    border-top: none;
  }

  .rbc-month-header {
    box-shadow: 0 0 0 1px #fff;
  }

  .rbc-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    padding-right: 10px;
  }

  .rbc-header + .rbc-header {
    border-left: none;
  }

  .rbc-date-cell {
    padding-top: 10px;
    padding-right: 10px;
  }

  .rbc-event {
    margin-bottom: 5px;
    padding: 2px 15px;

    border-radius: 5px;
    background-color: $color-status-confirmed;

    z-index: 1;
  }

  .rbc-row-segment {
    background-color: transparent;
  }

  .rbc-date-cell{
    a {
      pointer-events: none;
    }
  }

  .rbc-time-view {
    width: 80%;
    margin: 0 auto;
    flex-grow: 0;
    border: none;
  }

  .rbc-label {
    padding: 0;
  }

  .rbc-allday-cell {
    width: 80%;
    margin: 10px auto;

    background-color: transparent;
  }

  .rbc-time-content {
    display: none;
  }

  .rbc-time-header-content {
    border: none;
  }

  .rbc-btn-group {
    button {
      border-radius: 0;
      cursor: pointer;
    }
  }
}

.holidays__year-date {
  display: block;
  margin-bottom: 1rem;

  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.holidays__year-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 700px;
  max-height: 1550px;
}

.holidays__year-calendar {
  margin-right: 25px;
  margin-bottom: 25px;

  .rbc-calendar {
    width: calc(215px + 15 * ((100vw - 1024px) / (1920 - 1024)));
    height: 295px;
  }

  .rbc-event {
    padding: 2px;
    font-size: 0;

    border-radius: 20px;
    background-color: $color-status-confirmed;

    z-index: 1;
  }

  .rbc-row-segment {
    a {
      pointer-events: none;
    }
  }
  
  .rbc-date-cell {
    a {
      pointer-events: none;
    }
  }
}

.holidays__year-heading {
  display: flex;
  align-items: center;
  height: 35px;
  padding-left: 5px;

  font-weight: bold;

  border: 1px solid $color-background-input;
  border-bottom: none;
}

.holidays__popup-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  li {
    width: 45%;

    &:first-child {
      width: 100%;
    }
  }
}

.holidays__input-item {
  min-width: 175px;
  width: 275px;
  min-height: 90px;
  margin-right: 25px;
  margin-bottom: 5px;
}

.holidays__button-container {
  display: flex;
  margin-top: auto;
}

.holidays__button {
  min-width: 110px;
  margin-right: 1rem;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .holidays__popup-list {  
    li {
      width: 100%;
    }
  }

  .holidays__year-list {
    justify-content: space-between;
  }

  .holidays__year-calendar {
    margin-right: 10px;
    margin-bottom: 25px;
  
    .rbc-calendar {
      width: 205px;
      height: 295px;
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .holidays {
    padding-bottom: 0;
  }

  .holidays__content-container {
    flex-grow: 1;
    height: 100%;
    max-height: 750px;
  }

  .holidays__month-select {
    display: none;
  }

  .holidays__header {
    align-items: flex-start;
    margin: 0;
  }

  .holidays__selector-container {
    margin-bottom: 15px;
  }

  .holidays__calendar-switch-container {
    width: 100%;
    margin-bottom: 15px;

    button {
      width: 50%;
      height: 42px;
    }
  }

  .holidays__popup-button {
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .holidays__calendar-wrapper {
    width: 100vw;
    height: auto;

    .rbc-btn-group {
      width: 100%;
      text-align: center;
    }

    .rbc-header {
      height: 30px;
    }

    .rbc-row-segment {
      display: flex;
      align-items: center;

    }

    .rbc-event {
      height: 16px;
      margin: 0;
      padding: 3px 10px;

      font-size: 8px;
  
      border-radius: 20px;
      background-color: $color-status-confirmed;
  
      z-index: 1;
    }

    .rbc-toolbar-label {
      margin: 10px 0;
    }
  }

  .holidays__year-list {
    width: 100%;
  }

  .holidays__year-calendar {
    width: 100%;
    margin: 0;
  
    .rbc-calendar {
      width: 100%;
      height: 205px;
    }
  
    .rbc-event {
      padding: 2px;
      font-size: 0;
  
      border-radius: 20px;
      background-color: $color-status-confirmed;
  
      z-index: 1;
    }
  
    .rbc-row-segment {
      a {
        pointer-events: none;
      }
  
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .holidays__button-container {
    justify-content: center;
  }

  // .holidays__content-container {
  //   .rbc-calendar {
  //     height: 100%;
  //     min-height: 550px;
  //   }
  // }
}