/* ---------------------------------- desktop ---------------------------------- */

.void {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 1rem;
  padding-bottom: 5rem;

  height: 100%;

  svg {
    width: 20%;
    min-width: 100px;
    max-width: 200px;
    height: auto;
    margin-bottom: 1rem;
  }

  span {
    max-width: 650px;

    font-size: 26px;
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .void {
    justify-content: flex-start;
    padding: 150px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .void { 
    justify-content: center;
    padding: 0;

    span {
      font-size: 22px;
    }
  }
}
