/* ---------------------------------- desktop ---------------------------------- */

.invoice-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding-bottom: 1rem;
}

.invoice__template-container,
.invoice__product-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.invoice__content-container {
  position: relative;

  flex-grow: 1;
  margin-bottom: auto;

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;

    transform: translate(-50%, -50%);
  }
}

.invoice__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.invoice__filters-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 450px;

  & > label {
    width: 100%;

    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    color: $color-font-interactive;
  }
}

.invoice__input-search-container {
  position: relative;

  width: 100%;

  & > svg {
    position: absolute;
    top: 50%;
    left: 12px;

    display: block;
    width: 18px;
    height: 18px;

    transform: translateY(-50%);
    stroke: #c5c7Cd;
    transition: $default-transition-settings;
  }

  & > input {
    width: 100%;
    height: 42px;
    padding-left: 45px;
  
    font-size: $font-size-normal;
  
    border: 2px solid $color-font-passive;
    border-radius: 5px;
    outline: none;
  
    transition: $default-transition-settings;

    &::-webkit-search-cancel-button{
      cursor: pointer;
    }
  
    &:disabled {
      background-color: $color-table-border;
      border-color: $color-table-border;
  
      pointer-events: none;
  
      &::placeholder {
        color: $color-icon-common;
      }
    }
  
    &:hover {
      border-color: $color-input-hover;
    }
  
    &:hover + svg {
      stroke: $color-input-hover;
    }
  
    &:focus {
      border-color: $color-font-interactive;
    }
  
    &:focus + svg {
      stroke: $color-font-interactive;
    }
  
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    }
  
    &::placeholder {
      color: $color-font-passive;
      text-transform: capitalize;
    }
  
    &:focus::-webkit-input-placeholder {
      color: transparent;
      transition: 0.15s all ease-in-out;
    }
  
    &::-webkit-search-cancel-button{
      position:relative;
      right: 10px;
    }
  }
}


.invoice__management-container {
  display: flex;
  justify-content: flex-end;
}

.invoice__product-header,
.invoice__template-header {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

.invoice__invoice-data-item,
.invoice__installment-item,
.invoice__template-item,
.invoice__product-item {
  display: grid;
  grid-template-columns: 1fr 1fr clamp(80px, 10vw, 200px);
  align-items: center;
  padding: 10px;

  text-align: center;

  border-bottom: 1px solid $color-table-border;

  &:nth-child(2n) {
    background-color: $color-background-card;
  }

  &:not(:first-child):hover {
    background-color: $color-font-interactive-hover;
    transition: $default-transition-settings;
  }

  h3 {
    padding: 0 5px;

    font-size: 16px;
    color: $color-font-interactive;
    text-transform: capitalize;
    word-break: break-all;

    &::first-letter {
      text-transform: uppercase;
    }

    &:last-child {
      opacity: 0;
      pointer-events: none;
    }

    span {
      font-size: 16px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  a,
  span {
    color: $color-font-main;
    font-size: 14px;

    & > svg {
      width: 25px;
      height: auto;
    }


    & > sup {
      font-size: 9px;
    }
  }

  a {
    width: 100%;
    padding: 0 5px;

    color: $color-font-interactive;
    transition: $default-transition-settings;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus-visible {
      color: $color-font-interactive-dark;
      text-decoration: underline;
    }
  }
}

.invoice__invoice-item {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &:nth-child(2n) {
    background-color: $color-background-card;
  }
}

.invoice__invoice-data-item {
  width: 100%;
  grid-template-columns: clamp(80px, 10vw, 150px) 1fr 1fr 1fr 1fr 1fr clamp(80px, 10vw, 150px);

  & > h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    &:last-child {
      opacity: 1;
      pointer-events: inherit;
    }
  }

  &:hover {
    background-color: $color-font-interactive-hover;
    transition: $default-transition-settings;
  }

  &--open {
    background-color: $color-font-interactive-hover;
  }
}

.invoice__installment-void,
.invoice__installment-list {
  width: 100%;
  max-height: 0;

  opacity: 0;
  pointer-events: none;
  transition: 0.35s linear all;
  overflow: hidden;

  &--open {
    max-height: 220px;
    padding: 8px;

    background-color: $color-font-interactive-hover;
    opacity: 1;
    pointer-events: all;
  }
}

.invoice__installment-void {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  font-weight: bold;
  color: #fff;  

  background-color: $color-font-warning;

  &--open {
    padding: 10px;
    max-height: 45px;
  }
}

.invoice__installment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  margin-left: auto;
  padding: 0 5px;

  border: none;
  background-color: transparent;
  outline: none;

  cursor: pointer;
  transition: $default-transition-settings;

  & > svg {
    width: 100%;
    height: auto;
    fill: $color-font-interactive;

    transition: $default-transition-settings;
  }

  &--open {
    & > svg {
      fill: transparent;
      stroke: $color-font-interactive;
    }
  }
}

.invoice__installment-item {
  padding: 8px;
  grid-template-columns: clamp(80px, 10vw, 150px) 1fr 1fr;

  & > h4 {
    margin: 0;

    font-size: 14px;
    font-weight: bold;
    color: $color-font-interactive;
    text-transform: capitalize;
  }

  & > span {
    font-size: 12px;
  }

  &:nth-child(n) {
    background-color: #fff;
  }

  &:nth-child(2n + 1) {
    background-color: $color-background-card;
  }

  &:first-child:hover {
    background-color: $color-font-interactive-hover;
    transition: $default-transition-settings;
  }
}

.invoice__invoice-item-span {
  & > svg {
    fill: $color-font-interactive;
  }

  &--success {
    & > svg {
      fill: $color-font-success;
    }
  }

  &--error {
    & > svg {
      fill: $color-warning;
    }
  }
}

.invoice__popup-wrapper {
  min-height: 530px;
}

.invoice__template-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 550px;
  padding: 1.5rem;
  overflow: hidden;

  & > h4 {
    margin: 0;
    margin-bottom: 5px;
    
    color: $color-font-interactive;
    text-transform: capitalize;
  }
}

.invoice__template-form-button {
  margin: 0 auto;
  margin-top: auto;
  margin-left: 0;
}

.invoice__form-error {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;

  text-align: center;
  color: $color-font-warning;
  font-weight: bold;

  border-radius: 15px;
  border: 2px solid $color-status-progress;
}

.invoice__type-select-container {
  width: 50%;
  margin-bottom: 1.5rem;

  & > label {
    color: $color-font-interactive;
  }
}

.invoice__product-form-list,
.invoice__additional-form-list,
.invoice__template-form-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.invoice__additional-form-list {
  max-height: 0;
  opacity: 0;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  transition: $default-transition-settings;
  pointer-events: none;

  &--open {
    max-height: 200px;
    opacity: 1;
    pointer-events: all;
  }
}

.invoice__product-form-button {
  margin-top: auto;
  width: fit-content;
  max-width: 200px;
}

.invoice__product-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem;
}

.invoice__product-form-item,
.invoice__template-form-item {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  .input-select__control,
  .react-datepicker-wrapper,
  & > .input__selector-container,
  & > input {
    flex-grow: 1;
    width: auto;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-container__input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > label {
    position: relative;

    display: flex;
    align-items: center;
    min-width: 180px;
    max-width: 180px;
    padding-left: 10px;

    font-size: 18px;
    color: $color-font-interactive;
    text-transform: capitalize;

    border: 2px solid $color-font-calendar-disabled;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;

    & > sup {
      margin-left: 1px;

      font-size: 12px;
      font-weight: bold;
    }
  }

  & > .input-container__validation {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 0;

    width: fit-content;
    border-radius: 5px;

    transform: translateY(-50%);
  }
}

.invoice__template-form-item {
  flex-wrap: nowrap;
  &:last-child {
    width: 100%;
  }
}

.invoice__form-product-container {
  max-height: 160px;
  margin-bottom: 1rem;
  padding: 1rem;

  border: 2px solid $color-table-border;
  border-radius: 5px;
  background-color: $color-background-card;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.invoice__form-product-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
}

.invoice__form-product-item {
  display: flex;

  & > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    

    border: 1px solid $color-font-passive;
    border-right: none;
    background-color: #fff;

    & > span {
      flex-grow: 1;
      max-width: 110px;
      padding: 5px;

      text-align: center;

      &:first-child {
        max-width: 100%;
        flex-grow: 2;
        border-right: 1px solid $color-font-passive;
      }
    }
  }

  & > input {
    position: relative;
    padding-left: 15px;

    text-align: center;
    border: 1px solid $color-font-passive;

    -moz-appearance:textfield;

    // &::-webkit-outer-spin-button,
    // &::-webkit-inner-spin-button {
    //   -webkit-appearance: none;
    //   margin: 0;
    // }
  }
}

.invoice__product-total-price {
  margin-bottom: 1rem;
  font-weight: bold;
}

.invoice__form-product-quantity {
  width: 100px;
  margin-right: 1rem;
  padding: 5px;
}

.invoice__form-product-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  font-size: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    & > svg {
      fill: $color-font-interactive;
      transition: $default-transition-settings;
    }
  }

  & > svg {
    width: 20px;
    height: auto;
  }
}

.invoice__sample-list {
  position: absolute;
  left: -25px;
  top: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  width: fit-content;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.invoice__sample-item {
  display: flex;
  align-items: center;
  width: 170px;
  height: 42px;
  padding: 8px 11px;

  border-bottom: 1px solid $color-table-border;
  background-color: #ffffff;

  &:last-child {
    border-bottom: none;
  }
}

.invoice__sample-button {
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  color: black;
  font-size: 14px;

  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  transition: $default-transition-settings;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    
    fill: $color-font-passive;

    transition: $default-transition-settings;
  }

  span {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:hover,
  &:focus {
    color: $color-font-interactive;

    svg {
      fill: $color-font-interactive;
    }
  }

  &--stroke {
    svg {
      stroke: $color-font-passive;
      fill: transparent;
    }

    &:hover,
    &:focus {
      color: $color-font-interactive;
  
      svg {
        stroke: $color-font-interactive;
        fill: transparent;
      }
    }
  }
}

.invoice__template-item {
  grid-template-columns: 1fr 1fr clamp(100px, 10vw, 200px) 1fr clamp(80px, 10vw, 200px);
}

.invoice__template-item-list {
  display: flex;
  flex-direction: column;
  gap: 5px;

  & > article {
    word-break: break-word;
  }
}

.invoice__pagination {
  margin-top: 1rem;
}

.invoice__checkbox-container {
  display: flex;
  justify-content: center;
  width: fit-content;
  margin-bottom: 1rem;

  &--disable {
    
  }
}

.invoice__checkbox-svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  cursor: pointer;

  .svg-checkbox__box {
    fill: #ffffff;
    stroke: #000;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 320;
    stroke-dashoffset: 0;

    transition-delay: 0s, 0s, 0.3s;
    transition-property: none;
    transition-duration: 0.3s, 0.45s, 0.4s;
  }

  .svg-checkbox__check {
    fill: none;
    stroke: #ffffff;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 80;
    stroke-dashoffset: 80;

    transition: stroke-dashoffset 0.2s linear;
  }

  &--disabled {
    .svg-checkbox__box {
      transition-property: none;
    }
  
    .svg-checkbox__check { 
      transition: stroke-dashoffset none;
    } 
  }
}

.invoice__checkbox {
  &:focus-visible + label {
    span {
      color: $color-font-interactive;
    }

    .svg-checkbox__box {
      stroke: $color-font-interactive;
    }
  }

  &:checked + label {
    .svg-checkbox__box {
      fill: $color-font-interactive;
      stroke-dashoffset: 320;
    }
  
    .svg-checkbox__check {
      stroke-dashoffset: 0;
    }
  }

  &:disabled + label {
    .svg-checkbox__box {
      opacity: 0.5;
    }
  }
}

.invoice__checkbox-label {
  display: flex;
  align-items: center;

  font-weight: bold;
  color: $color-font-interactive;

  cursor: pointer;

  &:hover {
    .svg-checkbox__box {
      stroke: $color-font-interactive;
    }
  }

  &--disabled {
    pointer-events: none;
    color: $color-font-warning;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .invoice-section {
    padding-bottom: 0;
  }

  .invoice__header {
    flex-wrap: wrap;
  }

  .invoice__management-container {
    width: 100%;
    order: -1;

    & > a {
      flex-grow: 1;
    }
  }

  .invoice__filters-container {
    width: 100%;
    max-width: 100%;
    padding: 0 1rem;

    & > label {
      text-align: center;
    }
  }

  .invoice__template-header,
  .invoice__product-header {
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 16px;
  }

  .invoice__invoice-data-item,
  .invoice__invoice-item,
  .invoice__product-item,
  .invoice__template-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    min-height: 40px;
    max-width: 100%;

    text-align: center;
    border-bottom: 1px solid #f4f4f4;

    &:first-child {
      display: none;
    }

    & > span {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      min-height: 30px;
      padding-left: 30%;
      padding-right: 10px;

      &::after {
        content: attr(data-name);
  
        position: absolute;
        top: 0;
        left: 10px;
  
        display: flex;
        align-items: center;
        width: 30%;
        height: 100%;
        padding-right: 10px;
        white-space: nowrap;

        font-weight: bold;
        color: $color-font-interactive;
        text-transform: capitalize;
      }
    }

    & > .invoice__template-item-list {
      align-items: flex-end;
    }
  }

  .invoice__invoice-data-item {
    &:first-child {
      display: flex;
    }
  }

  .invoice__invoice-list {
    & > .invoice__invoice-data-item {
      display: none;
    }
  }

  .invoice__product-form-item,
  .invoice__template-form-item {
    & > label {
      display: inline-block;
      width: 100%;
      max-width: 100%;
      margin-bottom: 5px;

      font-size: 14px;
      text-align: center;

      border: none;
    }

    &:last-child {
      width: 100%;
    }
  }

  .invoice__sample-list {
    top: -60px;
    left: 50%;

    transform: translate(-50%);
    z-index: 1;
  }
}