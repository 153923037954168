html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  min-width: $viewport--sm;
  max-width: 100%;
  margin: 0 auto;

  font-family: 'Manrope', 'Arial', sans-serif;
  font-size: $font-size-regular;
  color: $color-font-main;

  background-color: #ffffff;

  &::-webkit-scrollbar {
    width: 0.4vw;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.4vw;
    background-color: $color-background-notification;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(197, 217, 226, 0.9);
  }
}

h3 {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

p {
  margin: 0;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;

  clip: rect(0 0 0 0);
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;

  list-style: none;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.break-word {
  overflow-wrap: anywhere;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  body {
    padding: 0;
  }
}
