/* ---------------------------------- desktop ---------------------------------- */

.lessons-section {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
}

.lessons-section__buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.lessons-section__content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > .loading-container {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    transform: translate(0, 0);
  }
}

.lessons-section__management-container {
  display: flex;
}

.lessons-section__actions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  button {
    min-width: 180px;
  }
}

.lessons-section__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 8%;

  span {
    font-size: 24px;
    font-weight: 500;
  }
}

.lessons-section__book-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.lessons-section__book-display {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  span {
    display: block;
    margin-bottom: 10px;
  }
}

.lessons-section__horizontal-calendar {
  padding: 1rem;

  box-shadow: 1px 1px 4px 2px rgba(34, 60, 80, 0.2);
}

.lessons-section__switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.lessons-section__horizontal-list {
  display: flex;
  width: fit-content;
}

.lessons-section__horizontal-item {
  display: flex;
  flex-direction: column;
  margin-right: 35px;

  cursor: pointer;

  &:hover {
    .lessons-section__horizontal-number {
      background-color: $color-background-input;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &--disabled {
    cursor: default;
    pointer-events: none;

    .lessons-section__horizontal-number {
      color: $color-font-passive;
      background-color: $color-background-datepicker-disabled;
    }

    .lessons-section__horizontal-day {
      color: $color-font-calendar-disabled;
    }
  }

  &--active {
    pointer-events: none;

    .lessons-section__horizontal-number {
      color: #ffffff;
      background-color: $color-font-interactive;
    }
  }
}

.lessons-section__horizontal-day {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  color: $color-font-aside;
  transition: $default-transition-settings;
}

.lessons-section__horizontal-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  border-radius: 50%;
  background-color: $color-background-datepicker;
  transition: $default-transition-settings;
}

.lessons-section__horizontal-datepicker {
  position: relative;

  display: flex;
  align-items: center;
  margin: 0 15px;

  &:hover {
    svg {
      fill: $color-font-interactive;
    }
  }

  label {
    width: 100%;
    min-width: fit-content;
    margin-right: 1rem;

    font-weight: bold;

    cursor: pointer;
  }

  svg {
    position: absolute;
    right: 0;
    top: 50%;

    display: block;
    width: 20px;
    height: 20px;

    transform: translateY(-50%);
    pointer-events: none;

    transition: $default-transition-settings;
  }

  .react-datepicker__input-container {
    input {
      width: 20px;
      height: 20px;
      font-size: 0;
      border: none;
      outline: none;

      cursor: pointer;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, 
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-color: transparent;
  }

  .react-datepicker__day-name {
    color: $color-font-interactive;
    font-weight: bold;
  }

  .react-datepicker__day {
    transition: $default-transition-settings;

    &--today {
      color: $color-font-interactive;
      font-weight: bold;
    }

    &--selected {
      color: #ffffff;
      background-color: $color-font-interactive;
      font-weight: bold;
    }
  }

  .react-datepicker__navigation {
    transition: $default-transition-settings;

    &--previous {
      &:hover {
        border-right-color: $color-font-interactive
      }
    }

    &--next {
      &:hover {
        border-left-color: $color-font-interactive
      }
    }
  }
}

.lessons-section__horizontal-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;
  transition: $default-transition-settings;

  &:hover {
    svg {
      fill: $color-font-interactive;
    }
  }

  svg {
    fill: $color-font-main;
    transition: $default-transition-settings;
  }

  &:disabled {
    svg {
      fill: $color-font-calendar-disabled;
    }

    pointer-events: none;
  }

  &:first-child {
    svg {
      transform: rotate(180deg);
    }
  }
}

.lessons-section__table-container {
  --table-td-width: 90px;

  width: 100%;

  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.lessons-section__table {
  min-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.lessons-section__th {
  position: relative;

  padding: 5px 10px;
  padding-bottom: 15px;

  text-align: center;

  border: 1px solid $color-background-disabled;
  border-top: none;

  span {
    display: block;
    min-width: var(--table-td-width);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--time {
    position: sticky;
    left: 0;

    border: none;
    background-color: #ffffff;

    z-index: 1;
  }
}

.lessons-section__td {
  width: var(--table-td-width);
  padding: 5px;

  text-align: center;

  border: 1px solid $color-background-disabled;

  &--time {
    position: sticky;
    left: 0;

    font-weight: bold;

    border: none;
    background-color: #ffffff;
  }
}

.lessons-section__book-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;

  font-size: 0;

  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  transition: $default-transition-settings;

  &:hover,
  &:focus {
    background-color: $color-background-datepicker;
    svg {
      fill: #0f5abd;
    }
  }

  &:active {
    svg {
      fill: #0b3f84;
    }
  }

  svg {
    width: 24px;
    height: 24px;

    fill: $color-font-interactive;
    stroke: transparent;
    transition: $default-transition-settings;
  }

  &--checked {
    background-color: #ECF4FD;

    &:hover,
    &:focus {
      svg {
        fill: #5599f1;
      }
    }
  }
  
  &--reserved {
    background-color: $color-font-interactive;
    pointer-events: none;

    &:hover,
    &:focus {
      background-color: #5599f1;

      svg {
        fill: #ffffff;
      }
    }

    svg {
      fill: #ffffff;
    }
  }

  &--disabled {
    background-color: $color-background-datepicker;
    pointer-events: none;

    svg {
      fill: $color-background-datepicker;
    }
  }
}

.lessons-section__book-view {
  h2 {
    margin-top: 30px;
    margin-bottom: 1rem;

    font-weight: normal;
    font-size: 16px;
  }

  .lessons-section__popup-table-container {
    padding: 0;
  }
}

.lessons-section__popup-table-container {
  padding: 15px;
  padding-top: 0;
}

.lessons-section__popup-table {
  width: 100%;
  margin-bottom: 25px;
}

.lessons-section__popup-button-container {
  display: flex;
  flex-wrap: wrap;

  button {
    &:first-child {
      margin-right: 25px;
    }
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {

}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .lessons-section {
    padding-bottom: 0;
  }

  .lessons-section__buttons-container {
    margin-bottom: 1rem;

    & > button {
      margin-left: 1rem;

      font-size: 12px;
    }
  }

  .lessons-section__management-container {
    width: 100%;
    margin-bottom: 1rem;

    a {
      width: 50%;
      font-size: 14px;
    }
  }

  .lessons-section__actions-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 0;
  
    .button {
      width: 260px;
      font-size: 12px;

      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  .lessons-section__book-display {
    margin: 0 auto;
  }

  .lessons-section__horizontal-calendar {
    margin: 0 auto;
  }

  .lessons-section__horizontal-item {
    margin-right: 15px;
  }
  
  .lessons-section__horizontal-number {
    width: 25px;
    height: 25px;
  }

  .lessons-section__actions-sample {
    position: initial;
  }

  .lessons-section__sample-list {
    position: absolute;
    top: -100%;
    left: 50%;
  
    width: 280px;
    margin: 0;
    padding: 0;
  
    list-style: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .lessons-section__sample-item {
    width: 100%;
  }

  .lessons-section__loading { 
    span {
      font-size: 18px;
    }
  }

  .lessons-section__horizontal-datepicker {
    .react-datepicker-popper {
      transform: translate3d(-30px, 27px, 0px) !important;
    }
  }

  .lessons-section__popup {
    display: flex;
    flex-direction: column;

    padding-bottom: 25px;
  }

  .lessons-section__popup-table-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    h2 {
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 2rem;
    }

    tbody {
      width: 100%;
    }

    .table__tr {
      width: 100%;
    }
  }

  .lessons-section__popup-table {
    margin-bottom: 0;
    padding: 0;
    border-spacing: 0;
  }

  .lessons-section__popup-button-container {
    justify-content: center;
    margin-top: 3rem;
  }

  .lessons-section__book-view {
    h2 {
      text-align: center;
    }
  }
}
