/* ---------------------------------- desktop ---------------------------------- */

.global-popup {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.4);
}

.global-popup__content-container {
  width: 500px;
  padding: 2rem;

  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 4px rgb(178, 186, 197);
}