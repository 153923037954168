/* ---------------------------------- desktop ---------------------------------- */

.notifications-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 1rem;

  & > .pagination {
    margin-top: 1rem;
  }
}

.notifications-section__header {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;

  .search-users__container {
    margin-right: auto;
  }
}

.notifications-section__header-link-container {
  display: flex;
  min-width: fit-content;
  margin-left: auto;
}

.notifications-section__info-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .pagination-container {
    margin-top: 1rem;
  }

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;

    transform: translate(-50%, -50%);
  }
}

.notifications-section__info-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: auto;
  border-bottom: 2px solid $color-background-card;

  overflow: hidden;
}

.notifications-section__header-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;

  border-bottom: 2px solid $color-background-card;

  & > h3 {
    position: relative;

    padding: 10px;

    text-align: center;
    font-size: 16px;
    color: $color-font-interactive;
  }
}

.notifications-section__info-item {
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;

  transition: $default-transition-settings;

  &:nth-child(2n + 1) {
    background-color: $color-background-datepicker-disabled;
  }

  &:hover > h4 {
    background-color:aliceblue;
  }

  & > h4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 10px;

    font-size: 15px;
    font-weight: normal;
    word-wrap: break-word;
    word-break: break-all;

    transition: $default-transition-settings;

    & > span {
      text-align: center;
    }
  }
}

.notifications-section__details-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  font-size: 13px;
  letter-spacing: 0.05rem;
}

.notifications-section__details-item {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  & > article {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    padding: 6px 0;

    text-align: center;

    transition: $default-transition-settings;

    &:hover {
      background-color:aliceblue;
    }
  }

  & > div {
    padding: 1rem;
  }
}

.notifications-section__details-date {
  padding: 0 5px;
}

.notifications-section__description {
  font-weight: bold;
}

.notifications-section__details-email {
  display: inline-block;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  color: $color-font-interactive;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border-bottom: 1px solid transparent;

  transition: $default-transition-settings;

  &:hover,
  &:focus {
    color: $color-font-interactive-dark;
    border-color: $color-font-interactive;
  }
}

.notifications-section__details-status {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notifications-section__details-status,
.notifications-section__cron-status {
  font-weight: bold;
  color: $color-font-warning;
  text-transform: lowercase;

  &--error {
    color: $color-font-error;
  }

  &--done {
    color: $color-font-success;
  }
}

.notifications-section__crob-list {
  margin-bottom: auto;

  border-bottom: none;
}

.notifications-section__cron-header-item,
.notifications-section__cron-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr clamp(60px, 10vw, 100px);

  text-align: center;

  & > h3,
  & > span {
    padding: 10px;
  }

  & > h3 {
    color: $color-font-interactive;
    font-size: 16px;

    &:last-child {
      font-size: 0;
    }
  }

  & > span {
    font-size: 15px;
  }
}

.notifications-section__cron-item {
  transition: $default-transition-settings;

  &:nth-child(2n) {
    background-color: $color-background-datepicker-disabled;
  }

  &:hover {
    background-color:aliceblue;
  }
}

.notifications-section__cron-item-link {
  display: flex;
  align-items: center;
  justify-content: center;

  color: $color-font-main;

  outline: none;

  transition: $default-transition-settings;

  &:hover,
  &:focus {
    color: $color-font-interactive-dark;

    & > .notifications-section__cron-notes {
      color: $color-font-interactive-dark;
    }
  }
}

.notifications-section__cron-read {
  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: auto;
    margin: 0 auto;

    stroke: $color-status-confirmed;
  }

  &--off {
    & > svg {
      stroke: $color-font-warning;
    }
  }
}

.notifications-section__cron-date {
  font-weight: bold;
}

.notifications-section__cron-notes {
  color: $color-font-warning;
  transition: $default-transition-settings;

  &--error {
    color: $color-font-error;
  }

  &--done {
    color: $color-font-success;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .notifications-section__header {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 0;
    gap: 0;

    & > a {
      width: 50%;

      font-size: 14px;
    }
  }

  .notifications-section__header-link-container {
    order: -1;
    width: 100%;

    & > a {
      flex-grow: 1;
    }
  }

  .notifications-section__header-item {
    display: none;
  }

  .notifications-section__search {
    width: 100%;

    input {
      border: none;
      border-bottom: 2px solid $color-font-passive;
    }
  }

  .notifications-section__info-item {
    display: flex;
    flex-direction: column;

    border-left: none;
    border-right: none;

    & > h4 {
      border: none;
    }
  }

  .notifications-section__header-link-container {
    width: 100%;

    & > .button {
      width: 50%;
    }
  }

  .notifications-section__details-item {
    font-size: 12px;
  }

  .notifications-section__details-email {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .notifications-section__details-status {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .notifications-section__crob-list {
    border: none;
  }

  .notifications-section__cron-header-item,
  .notifications-section__cron-item {
    & > h3,
    & > span {
      display: flex;
      align-items: center;
      justify-content: center ;
      padding: 3px;
    }

    & > h3 {
      font-size: 14px;
    }

    & > span {
      font-size: 13px;
    }
  }

  .notifications-section__cron-item-link {
    padding: 3px;

    font-size: 13px;
  }
}