/* ---------------------------------- desktop ---------------------------------- */

.page {
  --aside-max-width: clamp(200px, 10.5vw, 22px);
  --aside-tablet-width: 70px;

  position: relative;
  
  display: flex;
  min-height: 100vh;
}

.page__overflow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0;
  z-index: -1;

  transition: $default-transition-settings;

  &--active {
    background-color: rgba(0, 0, 0, 0.42);

    opacity: 1;
    z-index: 6;
  }
}

.page__main-container {
  max-width: calc(100% - var(--aside-max-width));
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
}

.page__content {
  flex-grow: 1;
  padding: 0 25px;
}

.page-main__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  & > .loading-container {
    left: 50%;
  }
}


/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .page {
    --aside-max-width: 75px;
  }

  .page__main-container {
    max-width: 100%;
    padding: 0;
  }

  .page__content {
    flex-grow: 1;
    padding: 0 15px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .page {
    height: 100vh;
    max-height: 100vh;
  }

  .page__main-container {
    padding: 0;
  }

  .page__content {
    padding: 0;
  }

  .page-main__loader { 
    & > .loading-container {
      top: 50%;
      left: 50%;
    }
  }
}