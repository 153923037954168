/* ---------------------------------- desktop ---------------------------------- */

.semester-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.semester {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.semester__header {
  display: flex;
  height: 58px;

  & + .semester {
    position: relative;
    top: -58px;

    flex-grow: 1;
    margin-bottom: -58px;
  }
}

.semester__actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 58px;

  margin-bottom: 2rem;
}

.semester__content-container {
  flex-grow: 1;
  max-width: calc(100vw - 255px);
  padding-bottom: 2rem;
}

.semester__over-popup {
  height: auto;
}

.semester__form {
  min-width: 650px;
  margin: 0;
  padding: 0;
}

.semester__fieldset {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;

  border: none;
}

.semester__input-container {
  width: 300px;

  &--short {
    width: 200px;
  }
}

.semester__button-container {
  display: flex;

  button {
    margin-right: 25px;
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .semester__content-container {
    max-width: 100%;
    padding: 0;
    padding-bottom: 25px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .semester__header {
    margin-bottom: 1rem;

    a {
      width: 50%;
    }

    & + .semester {
      position: relative;
      top: 0;
  
      margin-bottom: 0;
    }
  }

  .semester__content-container {
    padding: 0;
  }

  .semester__over-popup {
    height: 100%;
  }

  .semester__actions-container {
    padding: 0 15px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }


  .semester__fieldset {
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    flex-wrap: wrap;

    .input-container--datepicker {
      margin-bottom: 0;
    }
  }

  .semester__input-container {
    width: 100%;
    margin-right: 0;
  }

  .semester__button-container {
    margin: 0 auto;
  }

  .semester__form {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: inherit;
    margin: 0;
    padding: 20px;
  }

  .semester__button-container {
    margin-top: auto;
  }
}
