/* ---------------------------------- desktop ---------------------------------- */

.dashboard__main-component {
  display: flex;
}

.dashboard__statistics-wrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.dashboard__diagrams {
  width: 100%;
  margin-right: 50px;
}

.dashboard__worked-hours-info {
  display: flex;
  width: 100%;
  background: $color-background-salary;
}

.dashboard__worked-hours, .dashboard__planned-hours {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0 10px 0;
}

.dashboard__worked-hours {
  border-right: 1px solid $color-timetable-border;
}

.dashboard__worked-hours-text, .dashboard__planned-hours-text {
  font-size: 12px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.dashboard__worked-hours-text_active {
  text-decoration: underline;
}

.dashboard__worked-hours-text-divider {
  margin: 0px 5px;
}

.dashboard__worked-hours-stats, .dashboard__planned-hours-stats {
  font-size: 14px;
  font-weight: 600;
}

.dashboard__worked-hours-out-of {
  color: $color-timetable-border;
}

.dashboard__lessons-stats {
  display: flex;
}

.dashboard__pie-diagram {
  position: relative;
  width: 50%;
  height: 150px;
}

.dashboard__pie-diagram-hours {
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: $color-diagram-stats;
}


.dashboard__all-lessons {
  min-width: 350px;
}

.dashboard__all-lessons-title {
  font-size: 14px;
  text-align: center;
}

.dashboard__all-lessons-list-container {
  height: 320px;
  padding: 4px;
  background: $color-background-salary;
}

.dashboard__all-lessons-list {
  background: #fff;
}

.dashboard__all-lessons-list-empty {
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__all-lessons-item {
  border-bottom: 1px solid $color-background-salary;
  display: flex;
  padding: 11px;
}

.dashboard__all-lessons-name,
.dashboard__all-lessons-class,
.dashboard__all-lessons-hours {
  margin-right: 10px;
  font-size: 12px;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.dashboard__all-lessons-name {
  font-weight: 600;
}

.dashboard__all-lessons-hours {
  margin-right: 0;
  text-overflow: ellipsis;
  overflow: hidden;

}

.dashboard__absences {
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calendar-month-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.calendar-header {
  margin-bottom: 30px;
}

.month-popup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.month-nav {

}


.month-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 35px;
  font-size: 12px;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 10px;
  color: $color-weekday-in-calendar;

  a {
    &:visited {
      color: $color-weekday-in-calendar;
    }
  }
}

.absences-by-month {
  text-align: center;
  border-radius: 4px;
  width: 100%;
  font-size: 10px;
  line-height: 1;
  padding: 3px 0 3px 0;
  background: $color-day-in-calendar-border;
}

.month-item-empty {
  .absences-by-month {
    background: transparent;
  }
}

.month-item-active {
  .absences-by-month {
    background: $color-day-in-calendar;
    color: #fff;
  }

  .month-name {
    color: $color-day-in-calendar;
    font-weight: 500;
  }
}

.month-item-active-empty {
  .absences-by-month {
    background: transparent;
    color: $color-day-in-calendar;
  }
}

.calendar-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calendar {
  max-width: 350px;
  width: 100%;
}

.emptySlot {
  visibility: hidden;
  display: inline-block;
  border: 3px solid transparent;
  width: calc(100% / 7 - 14px);
  height: 0;
  padding-bottom: calc(100% / 7 - 20px);
  margin: 7px;
}

.calendar__days-of-week {
  display: flex;
}

.week-day {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(100% / 7 - 14px);
  margin: 7px;
  color: $color-weekday-in-calendar;
  font-size: 10px;
}

.days__container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 350px;
}

.day {
  position: relative;
  color: $color-day-in-calendar;
  font-size: 10px;
  border: 3px solid $color-day-in-calendar-border;
  border-radius: 50%;
  width: calc(100% / 7 - 14px);
  height: 0;
  padding-bottom: calc(100% / 7 - 20px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 7px;
  cursor: pointer;
  div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

}

.current-day {
  border: 3px solid #000;
}

.calendar__switcher-button {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: $color-calendar-switcher-disabled;
  &--active {
    color: #000;
  }
}

.month {
  margin-right: 18px;
}

.selected-day {
  border: 3px solid $color-background-table-confirm-btn;
}

.calendar__absences-header {
  width: 100%;
  margin-bottom: 65px;
}

.calendar__absences-header-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 18px;
}

.calendar__total-absences {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}

.calendar__total-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard__hours-switch {
  width: 100%;
}

.dashboard__hours-switch-container {
  display: flex;
}

.dashboard__hours-switch-item {
  text-transform: capitalize;
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dashboard__hours-switch-item_active {
  background-color:  $color-background-salary;
}

.dashboard__horizontal-calendar {
  display: flex;
  flex-direction: column;
}

.dashboard__switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.dashboard__horizontal-list {
  display: flex;
  width: fit-content;
  margin-bottom: 25px;
}

.dashboard__horizontal-item {
  display: flex;
  flex-direction: column;
  margin-right: 35px;

  cursor: pointer;

  &:hover {
    .dashboard__horizontal-number {
      background-color: $color-background-input;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &--disabled {
    cursor: default;
    pointer-events: none;

    .dashboard__horizontal-number {
      color: $color-font-passive;
      background-color: $color-background-datepicker-disabled;
    }

    .dashboard__horizontal-day {
      color: $color-font-calendar-disabled;
    }
  }

  &--active {
    pointer-events: none;

    .dashboard__horizontal-number {
      color: #ffffff;
      background-color: $color-font-interactive;
    }
  }
}

.dashboard__horizontal-day {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  color: $color-font-aside;
  transition: $default-transition-settings;
}

.dashboard__horizontal-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  border-radius: 50%;
  background-color: $color-background-datepicker;
  transition: $default-transition-settings;
}

.dashboard__horizontal-datepicker {
  position: relative;

  display: flex;
  align-items: center;
  margin: 0 15px;

  &:hover {
    svg {
      fill: $color-font-interactive;
    }
  }

  label {
    margin-right: 1rem;

    font-weight: bold;

    cursor: pointer;
  }

  svg {
    position: absolute;
    right: 0;
    top: 50%;

    display: block;
    width: 20px;
    height: 20px;

    transform: translateY(-50%);
    pointer-events: none;

    transition: $default-transition-settings;
  }

  .react-datepicker__input-container {
    input {
      width: 20px;
      height: 20px;
      font-size: 0;
      border: none;
      outline: none;

      cursor: pointer;
    }
  }
}

.dashboard__horizontal-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;
  transition: $default-transition-settings;

  &:hover {
    svg {
      fill: $color-font-interactive;
    }
  }

  svg {
    fill: $color-font-main;
    transition: $default-transition-settings;
  }

  &:disabled {
    svg {
      fill: $color-font-calendar-disabled;
    }

    pointer-events: none;
  }

  &:first-child {
    svg {
      transform: rotate(180deg);
    }
  }
}

.dashboard__statistics-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;
}

.dashboard__statistics-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 200px;
  padding: 14px;
  margin-right: 15px;
}

.dashboard__statistics-data {
  font-size: 15px;
  color: $color-background-card;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .dashboard__main-component {
    display: flex;
    flex-direction: column;
  }

  .dashboard__diagrams {
    width: 100%;
    margin-right: 0px;
  }

  .dashboard__lessons-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dashboard__all-lessons {
    width: 100%;
  }

  .dashboard__all-lessons-title {
    font-size: 14px;
    text-align: center;
  }

  .dashboard__absences {
    width: 100%;
    margin-top: 10px;
  }

  .calendar__total-wrap {
    justify-content: space-evenly;
  }

  .dashboard__worked-hours-text, .dashboard__planned-hours-text {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
}
