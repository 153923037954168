/* ---------------------------------- desktop ---------------------------------- */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;

  min-width: 685px;
  max-height: 680px;

  background-color: #ffffff;
  z-index: 6;

  transform: translate(-50%, -50%);
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.popup__header {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  background-color: #fff;
  border-bottom: 1px solid $color-popup-border;

  h3 {
    margin: 0;
    padding: 0;

    font-size: 20px;
    color: $color-font-popup;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.popup__close-button, .popup__back-button {
  padding: 0;

  font-size: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  transition: $default-transition-settings;

  svg {
    fill: $color-font-passive;
    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive;
    }
  }
}

.popup__back-button {
  margin-right: 15px;

  svg {
    fill: $color-font-interactive;
    transition: $default-transition-settings;
  }
}

.popup__form {
  padding: 15px 25px;
  padding-bottom: 42px;
  margin: 0;
}

.popup__fieldset {
  display: flex;
  flex-wrap: wrap;
  max-width: 425px;
  padding: 0;
  margin: 0;
  margin-bottom: auto;

  border: none;

  &--subject {
    max-width: 550px;
  }
}

.popup__warning {
  padding: 10px 25px;

  color: #fff;
  font-weight: bold;

  background-color: $color-require;
}

.popup__input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 20px;

  label {
    margin-bottom: 4px;

    font-size: 12px;
    text-transform: capitalize;
  }

  &--capacity {
    max-width: 125px;
    margin-left: 25px;
  }

  &--code {
    margin-left: 25px;
  }

  &--color {
    position: relative;

    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;

    button {
      display: flex;
    }
  }
}

.popup__input {
  width: 100%;
  height: 42px;
  padding: 0 1em;

  border: 2px solid $color-font-passive;
  outline: none;

  transition: $default-transition-settings;

  &:hover {
    border-color: $color-input-hover;
  }

  &:focus {
    border-color: $color-font-interactive;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.popup__textarea {
  width: 100%;
  height: 126px;
  padding: 0.5em 1em;

  border: 2px solid $color-font-passive;
  outline: none;
  resize: none;

  transition: $default-transition-settings;

  &:hover {
    border-color: $color-input-hover;
  }

  &:focus {
    border-color: $color-font-interactive;
  }
}

.popup__button {
  min-width: 110px;
  height: 42px;
}

.popup__color-block {
  position: relative;

  display: flex;
  width: 42px;
  height: 42px;
  margin-right: 24px;

  font-size: 0;

  border: 1px solid $color-font-passive;
  border-radius: 50%;
}

.popup__color-picker {
  position: absolute;
  top: 0;
  left: 52%;

  height: 160px;

  border-radius: 10px;
  overflow: hidden;
}

.popup__color-button {
  padding: 0.5rem;

  font-size: 12px;
}

/* CSSTransition classes  */

.popup-animation-enter {
  opacity: 0;
  transition: all 0.3s ease;
}

.popup-animation-enter-active {
  opacity: 1;
  transition: all 0.3s ease;
}

.popup-animation-exit-active {
  opacity: 0;

  transition: all 0.3s ease;
}

.popup-animation-exit-done {
  opacity: 0;
}

.popup-local-animation-enter {
  position: absolute;

  opacity: 0;
  z-index: -1;
}

.popup-local-animation-enter-active {
  position: relative;
  opacity: 1;
  transition: all 0.3s ease;
  z-index: -1;
}

.popup-local-animation-exit {
  position: absolute;

  opacity: 0;
}

.popup-local-animation-exit-active {
  position: absolute;

  opacity: 0;
  transition: opacity 200ms;
}

.popup-local-animation-exit-done {
  position: absolute;
  opacity: 0;
}

.popup__students-check-list {
  &:last-child {
    margin-bottom: 20px;
  }
}

.popup__students-check-item {
  position: relative;
}

.popup__students-check-label {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #F4F4F4;
}

.popup__students-check-fullName {
  display: flex;
}

.popup__students-check-firstName {
  margin-right: 5px;
}

.popup__custom-checkmark {
  position: relative;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #BDBDBD;
  margin-right: 20px;
}

.test {
  display: none;
  position: absolute;
  left: 8px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.test-active {
  display: block;
}

.popup__students-check-checkbox, .popup__students-check-selectAll {
  display: none;
}

.popup__students-check-checkbox:checked + .popup__custom-checkmark .test {
  display: block;
}

.popup__students-check-selectAll:checked + .popup__custom-checkmark .test {
  display: block;
}

.popup__students-check-checkbox:checked + .popup__custom-checkmark {
  background-color: #000;
  border: 1px solid #000;
}

.popup__students-check-selectAll:checked + .popup__custom-checkmark {
  background-color: #000;
  border: 1px solid #000;
}

.popup__custom-checkmark-active {
  background-color: #E8E8E8;
  border: 1px solid #E8E8E8;
}

.popup__custom-firstName-active, .popup__custom-lastName-active {
  color: #E8E8E8;
}

.popup__students-check-groupName {
  margin-left: auto;
}

.popup__students-check-textName {
  font-weight: bold;
}

.popup__students-check-plug {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.popup-wrapper__external-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 500px;
  max-height: 90vh;

  border: 2px solid rgb(101, 104, 107);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 4px rgb(178, 186, 197);

  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.popup-wrapper__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  background-color: #fff;
  border-bottom: 1px solid $color-popup-border;

  & > h3 {
    color: $color-font-interactive;
    text-transform: capitalize;
  }
}

.popup-wrapper__contant-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
}

.popup-wrapper-animation-enter {
  opacity: 0;
  transition: all 0.3s ease;
}

.popup-wrapper-animation-enter-active {
  opacity: 1;
  transition: all 0.3s ease;
}

.popup-wrapper-animation-exit-active {
  opacity: 0;

  transition: all 0.3s ease;
}

.popup-wrapper-animation-exit-done {
  opacity: 0;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .popup {
    min-width: 485px;
  }

  .popup__color-picker {
    left: 42%;
  }

  .popup__input-container {
    &--code {
      width: 100%;
      margin-left: 0;
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .popup {
    position: fixed;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 320px;
    max-width: 485px;
    max-height: 100%;

    overflow: auto;
    transform: translate(-50%, 0);
  }

  .popup__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-bottom: 25px;
  }

  .popup__header {
    padding: 15px;

    z-index: 2;

    h3 {
      width: 75%;
      font-size: 16px;
    }
  }

  .popup__input-container {
    width: 100%;

    &--capacity {
      max-width: inherit;
      margin-left: 0;
    }

    &--code {
      margin-left: 0;
    }

    &--color {
      margin-bottom: auto;
    }
  }

  .popup__color-picker {
    left: 0;
  }

  .popup-wrapper__external-container {
    width: 100%;
    max-width: 500px;
    min-width: auto;
  }
}

