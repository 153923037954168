/* ---------------------------------- desktop ---------------------------------- */

.upload {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.upload__files-container {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.upload__files-place {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  min-height: 200px;

  background-color: transparent;
  border: 2px dashed $color-font-interactive;
  border-radius: 5px;

  cursor: pointer;

  &:hover {
    border-color: $color-font-interactive-dark;
  }

  &--active {
    background-color: $color-input-hover;
    border: 2px dashed $color-font-interactive;

    &:hover {
      border-color: $color-font-interactive;
    }
  }

  &--error {
    cursor: auto;
    border: 2px dashed $color-font-error;

    &:hover {
      border-color: $color-font-error;
    }
  }
}

.upload__files-place-text {
  color: $color-font-additional;
}

.upload__files-place-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;

  svg {
    width: 60px;
    height: auto;
    margin-bottom: 1rem;

    fill: $color-font-interactive;
  }
}

.upload__files-input {
  display: none;
}

.upload__files-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 30px auto;
  margin-bottom: auto;
  padding-right: 5px;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.upload__file-item {
  display: flex;
  align-items: center;
  max-width: 350px;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px;

  background-color: $color-font-interactive-hover;

  span {
    width: 80%;

    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
  }

  .icon-document {
    width: 25px;
    margin-right: 1rem;

    fill: $color-font-interactive;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: auto;
    padding: 0;

    border: none;
    background-color: transparent;
    outline: none;

    cursor: pointer;

    &:hover,
    &:focus {
      svg {
        fill: $color-font-interactive;
      }
    }

    svg {
      width: 18px;
      height: 18px;

      fill: $color-font-passive;
      transition: $default-transition-settings;
    }
  }
}

.upload__files-list-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.upload__files-list-close-btn {
  cursor: pointer;
}

.upload__files-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.upload__result-button {
  svg {
    margin-right: 1rem;

    fill: #fff;

    transform: rotate(180deg);
  }
}

.upload__files-cancel-btn {
  margin-left: 1rem;
}

.upload__modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 500px;
  min-height: 650px;
}

.upload__result-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 530px;

  .title-successful {
    color: $color-font-success;
    margin-bottom: 10px;
  }

  .title-error {
    color: $color-font-error;
    margin-bottom: 10px;
  }

  .result-item {
    margin-bottom: 5px;
  }

  .row-error-block {
    margin-bottom: 5px;
    padding-left: 5px;

    div {
      &:first-child {
        color: $color-font-interactive;
        font-weight: 500;
      }
    }
  }
}

.upload__result-block-text {
  padding: 20px 30px;
  max-width: 500px;
}

.upload__result-block-error-msg {
  padding: 5px 0px 5px 10px;
}

.upload__table-container {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  & > .pagination-container {
    margin-top: 1rem;
  }
}

.upload__error-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.upload__error-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;

  font-size: 14px;

  & > span {
    width: 100%;
    word-wrap: break-word;
  }

  & > h4 {
    width: 100%;
    margin: 0;
    margin-bottom: 5px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .upload {
    width: 100%;
  }

  .upload__files-container {
    flex-wrap: wrap;
    padding: 1rem;
  }

  .upload__files-place {
    width: 100%;
    max-width: 400px;
  }
}
