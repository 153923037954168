/* ---------------------------------- desktop ----------------- */

.alert__main-container {
  position: fixed;
  top: 10vh;
  right: 0;
  z-index: 20;

  display: flex;
  justify-content: space-between;
  min-width: 300px;
  max-width: 500px;
}

.alert__message-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px;

  background-color: $color-status-confirmed;
  z-index: 20;

  & > span {
    display: inline-block;
    margin-right: 10px;

    color: #fff;
    font-weight: bold;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &--error {
    background-color: $color-font-warning;
  }
}

.alert__close-button {
  padding: 0;

  font-size: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  transition: $default-transition-settings;

  svg {
    fill: #fff;
    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive;
    }
  }
}

/*________ CSSTransition classes ________*/

.alert-animation-enter {
  transform: translateX(500px);
}

.alert-animation-enter-active {
  transform: translateX(0);
  transition: 0.7s cubic-bezier(.14,.24,.61,1.58) all;
}

.alert-animation-exit-active {
  transform: translateX(500px);
  transition: 0.7s cubic-bezier(0.2, -0.6, 0.2, 0) all;
}