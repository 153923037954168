/* ---------------------------------- desktop ---------------------------------- */

.pagination-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination__list {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination-switcher {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  font-weight: bold;
  color: $color-font-warning-main;

  border: 2px solid $color-font-passive;
  border-radius: 5px;
  background-color: transparent;

  outline: none;
  cursor: pointer;
  transition: $default-transition-settings;

  & > svg {
    width: 100%;
    height: auto;
  }

  &:hover,
  &:focus {
    color: $color-font-interactive;
    border-color: $color-background-notification;
  }

  &:disabled {
    color: #fff;
    background-color: rgba(189, 189, 189, 0.4);

    cursor: auto;
    pointer-events: none;
  }

  &--dots {
    align-items: flex-end;
    margin-bottom: -10px;

    color: $color-font-passive;

    border-color: transparent;

    cursor: auto;
    pointer-events: none;

    &:disabled {
      color: $color-font-passive;
      background-color: transparent;
    }

    & > svg {
      width: 100%;
      height: auto;
    }
  }

  &--active {
    background-color: $color-font-interactive;

    &:disabled {
      background-color: $color-font-interactive;
    }
  }

  &--edge {
    &:disabled {
      border-color: $color-font-passive;
      background-color: rgba(189, 189, 189, 0.4);

      color: $color-font-aside;

      &:focus {
        border-color: $color-font-passive;
        background-color: rgba(189, 189, 189, 0.4);
      }
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .pagination-container {
    justify-content: center;
    gap: 7px;
  }
  
  .pagination__list {
    gap: 3px;
  }

  .pagination-switcher { 
    width: 28px;
    height: 28px;
    padding: 3px;
  }
}
