/* ---------------------------------- desktop ---------------------------------- */

.module-section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.module__header {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.module__content-container {
  flex-grow: 1;
}

.module__filters-container {
  width: 100%;
  max-width: 300px;
  margin-right: auto;
}

.module__form {
  padding: 1.5rem;
}

.module__fieldset {
  margin: 0;
  margin-bottom: 5rem;
  padding: 0;

  border: none;
}

.module__input-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 650px;
  max-width: 650px;
}

.module__input-item {
  width: 45%;
  flex-grow: 1;

  &:nth-child(3),
  &:nth-child(4) {
    width: 100%;
  }
}

.module__list-item {
  display: grid;
  grid-template-columns: clamp(120px, 12vw, 200px) 1fr clamp(60px, 12vw, 90px);
  width: 100%;
  padding: 10px;

  text-align: center;

  border-bottom: 1px solid $color-table-border;

  transition: $default-transition-settings;

  &:nth-child(2n) {
    background-color: $color-background-card;
  }
  
  &:hover:not(:first-child) {
    background-color: $color-font-interactive-hover;
  }

  h4 {
    margin: 0;

    font-size: 17px;
    color: $color-font-interactive;
    word-break: break-all;

    &::first-letter {
      text-transform: capitalize;
    }

    &:last-child {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.module__subjects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;

  article {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;

    background-color: #fff;
    box-shadow: 2px 2px 2px 1px rgba(34, 60, 80, 0.2);
  }
}

.module__option-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.module__module-name {
  font-weight: bold;
}

.module__subject-decoration {
  display: block;
  width: 15px;
  height: 15px;

  border-radius: 50%;
  border: 1px solid $color-popup-border;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .module__header {
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0;
    padding: 1rem;
  }

  .module__filters-container {
    width: 100%;
    max-width: 100%;

    label {
      text-align: center;
    }
  }

  .module__list-item {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 1rem;

    &:first-child {
      display: none;
    }
  }

  .module__option-container {
    width: 100%;

    .table__actions-sample {
      position: relative;
    }

    .table__sample-list {
      top: -165%;
    }
  }

  .module__subjects-container {
    width: 100%;
  }
}