/* ---------------------------------- desktop ---------------------------------- */

.warning-popup {
  position: fixed;
  top: 50%;
  left: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 510px;
  padding: 40px;

  background-color: #ffffff;
  z-index: 10;

  transform: translate(-50%, -50%);

  h3 {
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    text-transform: capitalize;
    font-size: 34px;
    font-weight: 600;
    color: $color-font-warning-header;
  }

  p {
    max-width: 320px;
    margin: 0;
    margin-bottom: 32px;
    font-size: 20px;
    text-align: center;
    color: $color-font-warning-main;
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;

    transform: translate(-50%, -50%);
  }
}

.warning-popup__image-container, .warning-popup__image-wrap {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.warning-popup__image-container {
  width: 350px;
  height: 350px;

  & > loading-container {
    padding: 0;
  }
}

.warning-popup__button-container {
  display: flex;

  button {
    margin: 0 6px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .warning-popup {
    position: fixed;

    width: 100vw;
    padding: 30px;

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 18px;
    }
  }
}
