/* ---------------------------------- desktop ---------------------------------- */

.report {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.report__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.report__header-switch-container {
  display: flex;

  & > .button {
    &:nth-child(2n) {
      border: 2px solid $color-font-calendar-disabled;
      border-top: none;
      border-bottom: none;
    }

    &:last-child {
      border-right: none;
    }

    &--disabled:nth-child(2n) {
      background-color: $color-font-interactive;
    }
  }
}

.report__filters-container {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-right: auto;

  z-index: 2;
}

.report__class-content-container {
  flex-grow: 1;
}

.report__input-container {
  min-width: 250px;
}

.report__average-grades-list,
.report__details-grades-list,
.report__class-grades-list,
.report__attendance-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 1rem;
}

.report__average-grade-item,
.report__details-grade-item,
.report__student-proof-item ,
.report__class-proof-item,
.report__class-grade-item,
.report__attendance-item {
  display: grid;
  grid-template-columns: 10fr 1fr 1fr;

  &:first-child {
    color: $color-font-interactive;
  }

  &:nth-child(2n) {
    background-color: $color-background-card;
  }

  span,
  a,
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 10px 1rem;

    text-align: center;
    white-space: pre-wrap;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  a {
    display: block;
    min-width: auto;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: $color-font-interactive;

    transition: $default-transition-settings;

    &:first-of-type {
      text-align: center;
    }

    &:hover,
    &:focus {
      color: $color-font-interactive-dark;
      text-decoration: underline;
    }
  }

  h4,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 10px;

    text-transform: capitalize;
    text-align: center;
    word-break: break-word;
    hyphens: auto;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    color: $color-font-main;
  }

  .report__class-hidden-description {
    opacity: 0;
    pointer-events: none;
  }
}

.report__link-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.report__details-grade-item {
  grid-template-columns: 1fr 1fr;
}

.report__details-average-value {
  padding-left: 10px;

  font-size: 20px;
  font-weight: bold;
}

.report__average-hidden-description,
.report__attendance-hidden-description {
  opacity: 0;
  pointer-events: none;
}


.report__average-grade-item {
  grid-template-columns: 1fr 1fr clamp(70px, 10vw, 200px);
}

/* ----------- student proofs table ----------- */

.report__student-proof-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 1rem;
}

.report__student-proof-item {
  display: grid;
  grid-template-columns: 1fr 1.5fr clamp(90px, 10vw, 200px) 1fr clamp(90px, 10vw, 200px);
  border-bottom: 1px solid #bdbdbd;

  h4,
  a {
    &:last-child {
      min-width: 100px;
    }
  }
}

.report__class-download-container {
  display: flex;
  justify-content: flex-end;
}

.report__class-proof-item {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr clamp(90px, 10vw, 150px) 1fr clamp(80px, 10vw, 100px);
}

.report__student-name {
  text-transform: capitalize;
}

.report__details-content-container,
.report__average-content-container {
  height: 100%;

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;

    transform: translate(-50%, -50%);
  }

  & > h3 {
    color: $color-font-interactive;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

/* ----------- attendance table ----------- */

.report__attendance-group-heading {
  padding: 10px;
  padding-top: 0;
  color: $color-font-interactive;
}

.report__attendance-statistic-list {
  margin-bottom: 1rem;

  border: 1px solid $color-font-passive;
}

.report__attendance-statistic-item {
  display: grid;
  grid-template-columns: minmax(6em, 10%) 1fr 1fr 1fr 1fr minmax(6em, 10%);

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 10px;

    color: $color-font-interactive;
    text-transform: capitalize;
    text-align: center;
    word-break: break-all;
    hyphens: auto;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: $color-background-card;
    border-bottom: 1px solid $color-font-passive;

    &:not(:last-child) {
      border-right: 1px solid $color-font-passive;
    }

    &:last-child {
      pointer-events: none;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &:not(:last-child) {
      border-right: 1px solid $color-font-passive;
    }
  }

  .download-button__download-container {
    margin: 0 auto;
  }

  &:nth-child(3) {
    border-top: 1px solid $color-font-passive;
  }

  &--short {
    grid-template-columns: 1fr 1fr 1fr 1fr minmax(6em, 10%);
  }
}

.report__attendance-item {
  grid-template-columns: 1fr clamp(80px, 20vw, 400px) clamp(80px, 20vw, 400px);

  span,
  a,
  h4 {
    min-width: 35px;
  }
}


.report__attendance-value {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
}

.report__attendance-value-last {
  position: relative;
  left: -1px;
  height: calc(200% + 1px);

  background-color: #fff;
  border-left: 1px solid $color-font-passive;
  z-index: 1;
}

.report__attendance-download-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------- class grades table ----------- */

.report__class-grade-item {
  grid-template-columns: 1fr 1fr 1fr minmax(50px, 150px);
}


/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .report-section {
    padding: 10px;
  }

  .report__header {
    margin-bottom: 1rem;
  }

  .report__filters-container {
    width: 100%;
    row-gap: 10px;
    grid-column-gap: 10px;
  }

  .report__header-switch-container {
    width: 100%;
    justify-content: center;

    & > a {
      flex-grow: 1;
      width: auto;
      min-width: auto;
    }
  }

  .report__input-container {
    width: 80%;
    margin: 0 auto;
  }

  .report__student-proof-list {
    border: none;
    margin-bottom: 0;
  }

  .report__class-grades-list {
    margin: 0;
  }

  .report__average-grade-item,
  .report__details-grade-item {
    span,
    a,
    h4 {
      font-size: 14px;
      min-width: 80px;
    }
  }

  .report__attendance-item,
  .report__attendance-statistic-item,
  .report__class-proof-item,
  .report__class-grade-item,
  .report__student-proof-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    border: none;

    &:first-child {
      display: none;
    }

    &:nth-child(2) {
      border-top: 1px solid #bdbdbd;
    }
  
    span,
    h4,
    .report__link-container,
    .report__attendance-value {
      position: relative;

      display: flex;
      justify-content: flex-end;
      text-align: right;
      width: 100%;
      max-width: 100%;
      min-height: 42px;
      margin: 0;
      padding: 10px 1rem;
      padding-left: 40%;

      & {
        border: none;
        border-bottom: 1px solid $color-font-passive;
        border-left: 1px solid $color-font-passive;
        border-right: 1px solid $color-font-passive;
      }

      &:not(:last-child) {
        border-right: 1px solid $color-font-passive;
      }

      &:first-child {
        text-align: right;
      }

      &:nth-child(2) {
        text-align: right;
      }

      &::before {
        content: attr(data-name);
  
        position: absolute;
        top: 0;
        left: 15px;
  
        display: flex;
        align-items: center;
        width: 45%;
        height: 100%;
        padding-right: 10px;
        white-space: nowrap;

        font-weight: bold;
        color: $color-font-main;
      }
    }

    a {
      &:first-of-type {
        text-align: right;
      }
    }

    .download-button__download-container {
      margin: 0;
    }
  }

  .report__link-container {
    a {
      padding: 0;
    }
  }

  .report__attendance-download-container {
    display: flex;
    justify-content: flex-end;
  }

  .report__attendance-statistic-list,
  .report__attendance-list {
    position: relative;
    width: 100%;

    border: none;
  }

  .report__attendance-statistic-list {
    &--long {
      padding-bottom: 65px;
    }
  }

  .report__attendance-statistic-item {
    &:nth-child(3) {
      border-top: none;
      background-color: $color-background-salary;
    }

    .report__attendance-value-last {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 65px;
    
      background-color: #fff;
      border-left: 1px solid $color-font-passive;
      z-index: 1;
    }
  }

  .report__attendance-list {
    margin-bottom: 0;
  }
}
