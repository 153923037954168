.edit-popup__students-fullName {
  display: flex;
}

.edit-popup__add-student-button-container {
  padding-left: 25px;
  margin-top: 15px;
}

.edit-popup__students-list-forEditing {
  margin-bottom: 30px;
}

.edit-popup__students-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #F4F4F4;
}

.edit-popup__students-firstName {
  margin-right: 5px;
}

.edit-popup__cross-button {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

