/* ---------------------------------- desktop ---------------------------------- */

.apprentice-timetable {
  position: relative;
  top: -62px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-bottom: -62px;
}

.apprentice-timetable__create-group-btn {
  margin: 0 auto;
}

.apprentice-timetable__header {
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 350px);
  gap: 10px;

  .input-container {
    flex-grow: 1;
    max-width: 340px;
    min-width: 150px;
  }
}

.apprentice-timetable__header-button-container {
  display: flex;
  align-items: flex-end;
  margin-left: 5px;
  margin-top: 1rem;
}

.apprentice-timetable__header-button {
  display: none;
}

.apprentice-timetable__table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.apprentice-timetable__table {
  position: relative;
  --cellWidth: calc(480px + (800 - 250) * ((100vw - 1024px) / (1920 - 1024)));

  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 15px;

  z-index: 0;
}

.apprentice-timetable__th {
  &:first-child {
    min-width: 110px;
    width: 110px;

    font-weight: bold;

    background-color: #fff;
  }
}

.apprentice-timetable__th-heading {
  display: flex;
  flex-wrap: wrap;

  h4 {
    width: 100%;
    margin: 0;
    margin-bottom: 12px;

    font-weight: bold;
    font-size: 22px;
    color: $color-font-interactive;
  }

  span {
    width: 50%;
  }
}

.apprentice-timetable__td {
  position: relative;

  min-width: 460px;
  width: var(--cellWidth);
  max-width: var(--cellWidth);

  border-radius: 5px;
  height: 45px;

  z-index: 0;

  &:first-child {
    min-width: 105px;
    width: 110px;

    font-weight: bold;
    color: $color-font-interactive-dark;

    background-color: #fff;
    border-radius: 0;
  }

  &--selected {
    .apprentice-timetable__card-component {
      box-shadow: 0 0 25px 1px $color-font-interactive;
    }
    
  }
}

.apprentice-timetable__lesson-cell {
  display: flex;
  width: 100%;
  height: 100%;
}

.apprentice-timetable__card-container {
  position: relative;

  width: 50%;
  margin: 0 1rem;
}

.apprentice-timetable__button {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 1rem;

  color: transparent;
  font-weight: bold;

  border: none;
  border-radius: 5px;
  background-color: rgba(137, 140, 163, 0.15);
  cursor: pointer;

  outline: none;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    color: #fff;
    background-color: rgba(22, 114, 236, 0.6);
  }

  &:active {
    background-color: rgba(22, 114, 236, 0.7);
  }
}

.apprentice-timetable__card-component {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 10px;

  border: none;
  border-radius: 5px;
  cursor: pointer;

  outline: none;
  transition: $default-transition-settings;

  border: none;
  border-radius: 5px;
  cursor: pointer;

  outline: none;
  transition: $default-transition-settings;

  &::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    border-radius: 5px;
    background: inherit;

    opacity: 1;

    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    &::before {
      filter: blur(4px);
    }
  }
}

.apprentice-timetable__week-container {
  width: 50%;
  text-align: center;
}

.apprentice-timetable__date {
  display: none;
  justify-content: flex-start;
  padding: 5px 0;
  margin-bottom: 10px;
  width: 100%;

  border-bottom: 1px solid rgba(21%, 22%, 25%, 0.3);

  span {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

.apprentice-timetable__lesson-card-container {
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 100%;

  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-left: auto;
    width: 45px;
    height: 45px;

    font-weight: bold;
    color: $color-font-interactive;

    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .apprentice-timetable__lesson-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 15px;
    margin-top: auto;

    p {
      width: 100%;

      text-align: left;

      span {
        font-weight: bold;
        color: #fff;
        text-shadow: 1px 1px 2px #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
      }
    }

    &--small p {
      width: calc(80px + (200 - 80) * ((100vw - 1450px) / (1920 - 1450)));
      max-width: 75%;
      min-width: 80px;
    }

    &--empty p {
      width: 100%;
      max-width: 100%;
      justify-content: center;
    }
  }

  &--small h4 {
    position: absolute;
    right: 15px;
    top: 15px;
    top: 50%;

    transform: translateY(-50%);
  }

  &--small .apprentice-timetable__lesson-content-container {
    height: 100%;
    justify-content: center;
    padding-top: 0;
  }
}

.apprentice-timetable__description {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;

  border-radius: 5px;
  background-color: rgba(21%, 22%, 25%, 0.3);

  &:nth-child(2) {
    text-transform: capitalize;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.apprentice-timetable__lesson-header {
  display: flex;
  margin-bottom: 12px;

  h4 {
    display: flex;
    width: 100%;
    justify-content: space-between;

    margin: 0;
    padding: 0;

    span {
      width: 42%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--simple {
    h4 {
      span {
        width: 100%;
      }
    }
  }
}

.apprentice-timetable__popup {
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  min-height: 660px;
  overflow: hidden;
}

.apprentice-timetable__secondary-fieldset-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  fieldset {
    &:first-child {
      width: 100%;
    }
  }
}

.apprentice-timetable__popup-list {
  display: flex;
  flex-wrap: wrap;

  .timetable__input-item {
    max-width: 172px;

    &--type {
      max-width: 250px;
    }

    &--time {
      min-width: 160px;
      max-width: 160px;
    }
  }
}

.apprentice-timetable__no-group-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;

  span {
    margin-bottom: 2rem;
  }
}

.apprentice-timetable__select-groups {
  display: none;
}


/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .timetable-section__management-button {
    min-width: 60px;

    &--status {
      display: none;
    }
  }

  .apprentice-timetable__popup {
    width: 80%;
    min-width: 485px;
    min-height: 660px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .apprentice-timetable {
    top: 0;
  }

  .apprentice-timetable__selector-container {
    width: 100%;
  }

  .apprentice-timetable__header {
    justify-content: center;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding: 0 1rem;
  
    .input-container {
      max-width: 100%;
      width: 100%;
      text-align: center;
    }
  }

  .apprentice-timetable__header-button {
    display: block;
    margin-top: 1rem;
  }

  .apprentice-timetable__table-container {
    padding: 10px;
  }

  .apprentice-timetable__table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      min-width: 295px;
      margin: 0 auto;
    }
  }

  .apprentice-timetable__tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .apprentice-timetable__td {
    display: none;
    height: 130px;
    max-height: 225px;
    padding: 0;

    &--content {
      display: flex;
      max-width: 100%;
      min-width: auto;
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .apprentice-timetable__lesson-card {
    height: 100%;
    max-height: 100%;
    position: relative;
    font-size: 16px;

    &--half::after {
      content: "";

      position: absolute;
      left: 50%;
      top: 50%;

      display: block;
      width: 1px;
      height: 80%;

      background-color: $color-background-table-day-header;
      transform: translateY(-50%);
      opacity: 0.5;

      z-index: 1;
    }
  }

  .apprentice-timetable__lesson-card-container {
    padding: 8px;
  }

  .apprentice-timetable__date {
    display: flex;
    font-weight: bold;
  }

  .apprentice-timetable__button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .apprentice-timetable__popup {
    min-width: auto;
    max-width: 485px;
    width: 100%;
    padding-bottom: 0;
  }

  .apprentice-timetable__no-group-container {
    padding: 0 10px;
    padding-top: 5rem;
    span {
      text-align: center;
    }
  }

  .apprentice-timetable__form-header {
    span {
      display: none;
    }
  }

  .apprentice-timetable__secondary-fieldset-container {
    display: flex;
    flex-wrap: wrap;
  
    fieldset {
      &:first-child {
        width: 100%;
      }
  
      width: 100%;
    }
  }

  .apprentice-timetable__popup-list {
    display: flex;
    flex-wrap: wrap;
  
    .timetable__input-item {
      max-width: 100%;
  
      &--type {
        max-width: 100%;
      }
    }
  }

  .apprentice-timetable__lesson-cell {
    max-height: 130px;
  }

  .apprentice-timetable__card-container {
    min-width: 130px;
    height: 100%;
    margin: 0;

    &:first-child {
      margin-right: 1rem;
    }
  }

  .apprentice-timetable__card-component {
    min-height: 100%;
    max-height: 130px;
    position: relative;
  }

  .apprentice-timetable__lesson-card-container {
    padding: 7px;
  
    h4 {
      width: 100%;
      height: 25px;
      border-radius: 5px;
      padding: 5px;
      margin: 0 auto;
    }

    &--small h4 {
      position: relative;
      top: 0;
      left: 0;
      right: 0;

      transform: translateY(0);
    }
  
    .apprentice-timetable__lesson-content-container {
      &--small p {
        width: 100%;
        max-width: 100%;
        min-width: 80px;
      }
    }

    &--small .apprentice-timetable__lesson-content-container {
      justify-content: flex-end;
    }
  }

  .apprentice-timetable__description {
    &--remove {
      display: none;
    }
  }
}