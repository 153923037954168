/* ---------------------------------- desktop ---------------------------------- */

.salary-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 1rem;
}

.salary__header {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  gap: 1rem;
  margin-bottom: 1rem;
}

.salary__filters-container {
  position: relative;
  z-index: 5;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  flex-grow: 1;
  gap: 1rem;
  margin-right: auto;

  & > div {
    flex-grow: 1;
    min-width: 240px;
    max-width: 240px;
  }

  & > .input-container {
    min-width: 200px;
  }
}

.salary__additional-filters-container {
  position: relative;
  z-index: 4;

  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  & > div {
    flex-grow: 1;
    min-width: 240px;
    max-width: 240px;
  }

  & > .input-container {
    min-width: 200px;
  }
}

.salary__management-container {
  display: flex;
  margin-left: auto;

  & > .button {
    &:nth-child(2n) {
      border: 2px solid $color-font-calendar-disabled;
      border-top: none;
      border-bottom: none;
    }

    &--disabled:nth-child(2n) {
      background-color: $color-font-interactive;
    }
  }
}

.salary__global-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;

    transform: translate(-50%, -50%);
  }
}

.salary__consolidate-item,
.salary__total-item,
.salary__hours-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr clamp(70px, 8vw, 200px) clamp(70px, 8vw, 200px) clamp(100px, 15vw, 200px);
  align-items: center;
  padding: 8px;

  text-align: center;

  border-bottom: 1px solid $color-table-border;

  &:nth-child(2n) {
    background-color: $color-background-datepicker-disabled;
  }

  &:not(:first-child):hover {
    background-color: $color-font-interactive-hover;
    transition: $default-transition-settings;
  }

  h3 {
    gap: 5px;
    padding: 0 5px;

    font-size: 16px;
    color: $color-font-interactive-dark;
    text-transform: capitalize;
    word-break: break-all;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-size: 16px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  span {
    position: relative;

    font-size: 14px;

    word-break: break-all;
    white-space: nowrap; 
    text-overflow: ellipsis;

    &:not(:last-child) {
      overflow: hidden;
    }

    & > svg {
      width: 25px;
      height: auto;

      fill: transparent;
    }

    & > article {
      position: absolute;
      right: 0;
      top: 50%;

      padding: 5px;

      font-size: 10px;
      font-weight: bold;
      color: $color-font-interactive-dark;
      background-color: #E1EEFA;

      transform: translateY(-50%);
    }

    .table__sample-list {
      top: -185%;
      right: 115px;
    }
  }
}

.salary__consolidate-item {
  & > h3 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.salary__consolidate-item-link {
  max-width: 120px;

  color: $color-font-interactive;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  border-bottom: 1px solid $color-font-interactive;

  transition: $default-transition-settings;

  &:hover,
  &:focus-visible {
    color: darken($color-font-interactive, 40%);
  }
}

.salary__total-item {
  align-items: stretch;
  grid-template-columns: 1fr 1.3fr 1.3fr clamp(50px, 8vw, 200px) clamp(50px, 8vw, 200px);
  padding: 0;

  font-size: 14px;

  border-left: 2px solid  $color-table-border;

  &:first-child {
    border-top: 2px solid $color-table-border;
  }

  & > div {
    padding: 10px;
    text-transform: capitalize;
    border-right: 2px solid $color-table-border;

    &:first-child,
    &:nth-last-child(2),
    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:nth-last-child(2) {
      font-weight: bold;
    }
  }
}

.salary__total-item-salary {
  color: $color-font-success;

  &--low {
    color: $color-font-error;
  }
}

.salary__total-item-plan {
  color: $color-font-interactive-dark;
}

.salary__total-head-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3px;

  & > h3 {
    width: 100%;
  }

  & > div {
    width: 100%;
  }
}

.salary__total-types-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  grid-template-rows: 1fr 1fr;
  gap: 3px;

  & > span {
    text-align: center;
    padding: 0 2px;
  }

  & sup {
    font-size: 8px;
  }

  &--head {
    grid-template-rows: 1fr;

    font-weight: bold;
    color: $color-font-interactive;
  }
}

.salary__consolidate-item-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.salary__pagination {
  margin-top: 1rem;
}

.salary__teacher-salary-info {
  position: relative;
  z-index: 3;

  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.salary__salary-tab {
  position: relative;
  flex-grow: 1;
  max-width: 420px;

  transition: all 0.25s ease-in-out;

  &--lessons {
    max-width: 350px;
  }

  &--rise {
    & > .salary__salary-tab-label {
      --bg-color: #CBE1CD;
      --bg-color-darker: #B1D2B4;
    }
  }

  &--loss {
    z-index: 2;
  
    & > .salary__salary-tab-label {
      --bg-color: #FCD49C;
      --bg-color-darker: #F9A939;
    }
  }
}

.salary__salary-tab-label {
  --bg-color: #E1EEFA;
  --bg-color-darker: #A8CDF0;

  position: relative;

  display: flex;
  flex-wrap: wrap;
  padding: 1em 15px;

  font-weight: bold;
  font-size: 24px;

  border-radius: 15px;
  border-left: 15px solid var(--bg-color-darker);
  background: var(--bg-color);
  box-shadow: 5px 0 10px -2px rgba(34, 60, 80, 0.2);

  cursor: pointer;
  transition: all 0.25s ease-in-out;

  & > span {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;

    font-size: 16px;
  }

  & > sup {
    font-size: 14px;
  }

  &:focus-visible,
  &:hover {
    color: #000;
    border-left: 15px solid var(--bg-color);
    background: var(--bg-color-darker);
  }

  & > .salary__salary-tab-decoration {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;

    color: #fff;
    font-weight: bold;
    font-family: 'Arial';

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.4);

    transform: rotate(0);
    transition: all .3s linear;

    & > svg {
      width: 25px;

      stroke: #fff;
    }
  }
}

.salary__salary-additional-label {
  position: absolute;
  top: 50%;
  right: 25px;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  transform: translateY(-50%);

  &::after {
    content: "";

    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    display: block;
    height: 1px;

    background-color: rgba(0%, 0%, 0%, 0.3);
    transform: translateY(-50%);
  }

  & > span {
    font-size: 12px;

    & > sup {
      font-size: 9px;
    }
  }
}

.salary__salary-tab-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;

  display: flex;
  padding: 0 1em;
  gap: 10px;

  color: $color-font-main;
  border-radius: 0 0 15px 15px;
  border-left: 15px solid darken($color-background-card, 10%);
  background: $color-background-card;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  opacity: 0;

  transition: all 0.25s ease-in-out;
  pointer-events: none;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > article {
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 8px;

      font-size: 14px;

      & > span {
        position: relative;

        padding-left: 1em;

        &::after {
          content: "";
  
          position: absolute;
          left: 3px;
          top: 50%;
  
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $color-font-main;
  
          transform: translateY(-50%);
        }

        & > b {
          color: $color-font-interactive;
        }
      }
    }

    span {
      & > b {
        color: $color-font-interactive-dark;
      }
    }
  }
}

.salary__salary-tab-input:focus-visible {
  & + .salary__salary-tab-label {
    color: #000;
    border-left: 15px solid var(--bg-color);
    background: var(--bg-color-darker);
  }
}

.salary__salary-tab-input:checked {
  & + .salary__salary-tab-label {
    color: #000;
    border-radius: 15px 15px 0 0;
    border-left: 15px solid var(--bg-color);
    background: var(--bg-color-darker);

    & > .salary__salary-tab-decoration  {
      transform: rotate(90deg);
    }
  }

  & ~ .salary__salary-tab-content {
    top: 100%;

    opacity: 1;
    padding: 1em;
    pointer-events: all;
  }
}

.salary__teacher-salary-card {
  position: relative;

  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 1rem;
  padding-right: 130px;

  border: 2px solid rgba(34, 60, 80, 0.2);
  border-radius: 10px;
  box-shadow: 2px 2px 3px 1px rgba(34, 60, 80, 0.2);

  overflow: hidden;

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    z-index: 1;

    width: 60px;
    min-width: 55px;
    height: auto;
    padding: 5px;

    fill: #fff;

    transform: translateY(-50%);
  }

  & > h4 {
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;
    gap: 5px;
    margin: 0;
    margin-bottom: 1rem;

    color: $color-font-interactive;

    & > svg {
      height: 22px;
      width: auto;

      fill: $color-font-interactive;
    }

    &::before {
      content: "";
  
      position: absolute;
      left: -10%;
      bottom: -7px;
  
      display: block;
      width: 200%;
      height: 2px;
  
      background-color: $color-font-interactive;
    }
  }

  &::after {
    content: "";

    position: absolute;
    right: 0;
    top: 50%;

    display: block;
    width: 100px;
    height: 105%;

    background-color: $color-font-interactive;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);

    transform: translateY(-50%);
    z-index: 0;
  }
}

.salary__consolidate-container {
  position: relative;
  margin-bottom: auto;

  & > .salary__download-container {
    right: 0;
    top: -10px;
  }
}

.salary__total-container {
  position: relative;
  margin-bottom: auto;

  & > .salary__download-container {
    right: 0;
    top: -45px;
  } 
}

.salary__download-container {
  position: absolute;
  right: 5px;
  top: -43px;
}

.salary__salary-card-content {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
}

.salary__salary-rates-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  padding: 5px;

  & > span {
    color: $color-font-interactive-dark;
  }
}

.salary__salary-card-info {
  color: $color-font-main;
  font-weight: 500;

  & > sup {
    font-weight: bold;
    font-size: 9px;
  }

  & > b {
    color: $color-font-main;
  }

  &--loss {
    color: $color-font-error;
  }

  &--rise {
    color: $color-font-success;
  }
}

.salary__salary-card-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: 1rem;
  padding: 5px;

  font-size: 14px;

  &:last-child {
    margin: 0;
  }
}

.salary__link-button {
  color: $color-font-interactive;
  border-bottom: 1px solid transparent;

  transition: $default-transition-settings;

  &:focus,
  &:hover {
    color: $color-font-interactive-dark;
    border-color: $color-font-interactive-dark;
  }
}

.salary__hours-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: calc(-1.5rem - 42px);

  & > .void {
    padding: 0;
  }

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;

    transform: translate(-50%, -50%);
  }

  &--teacher {
    margin-top: 0;
  }
}

.salary__hours-item {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr clamp(80px, 10vw, 200px);

  h3 {
    &:last-child {
      opacity: 0;
      pointer-events: none;
    }

    span {
      font-size: 16px;
    }
  }

  .table__actions-sample {
    justify-content: center;
  }
}

.salary__popup {
  max-width: 700px;
}

.salary-popup__fieldset {
  margin: 0;
  padding: 0;
  border: none;

  & .input-container__calendar-svg {
    top: 70%;
    right: 2%;
  }
}

.salary-popup__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 100%;
  margin-bottom: 1rem;
}

.salary-popup__item {
  flex-grow: 1;

  &:first-child,
  &:last-child {
    width: 100%;
  }

  & > .input-container__textarea {
    height: 85px;
  }
}

.salary__hours-header {
  position: relative;
  z-index: 5;

  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin-bottom: 1rem;
  margin-left: auto;
}

.salary__hours-list {
  margin-bottom: auto;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .salary__teacher-salary-info {
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: 100%;
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .salary-section {
    padding-bottom: 0;
  }
  
  .salary__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    padding: 0;
  }

  .salary__filters-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 1rem;
    padding-bottom: 0;

    & > div {
      max-width: 100%;
    }

    .input-container {
      width: 100%;
    }

    label {
      text-align: center;
    }
  }

  .salary__management-container {
    order: -1;
    width: 100%;
  }

  .salary__header-link {
    flex-grow: 1;
    min-width: 80px;
  }

  .salary__teacher-salary-card {
    width: 100%;
  }

  .salary__consolidate-container {
    padding: 0;
  }

  .salary__teacher-salary-info {
    padding: 0 1rem;
  }

  .salary__download-container {
    position: relative;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
  }

  .salary__consolidate-item,
  .salary__total-item,
  .salary__hours-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    min-height: 40px;
    max-width: 100%;

    text-align: center;
    border-bottom: 1px solid #f4f4f4;

    &:first-child {
      display: none;
    }

    & > div,
    & > span {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      min-height: 30px;
      padding-left: 30%;
      padding-right: 10px;

      & > article {
        position: relative;
        margin-left: 5px;

        transform: translateY(0);
      }

      &::after {
        content: attr(data-name);
  
        position: absolute;
        top: 0;
        left: 10px;
  
        display: flex;
        align-items: center;
        width: 30%;
        height: 100%;
        padding-right: 10px;
        white-space: nowrap;

        font-weight: bold;
        color: $color-font-interactive;
        text-transform: capitalize;
      }
    }
  }

  .salary__total-item {
    & > div {
      padding: 10px;
      text-transform: capitalize;
      border-right: 2px solid $color-table-border;
  
      &:first-child,
      &:nth-last-child(2),
      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  .salary__total-container {  
    & > .salary__download-container {
      right: 0;
      top: 0;
    } 
  }

  .salary__hours-header {
    margin: 0;
  }

  .salary__additional-filters-container {
    padding: 0 16px;

    & > div {
      width: 100%;
      max-width: 100%;
    }
  }

  .salary__salary-tab {
    width: 100%;
    max-width: 100%;

    &--loss {
      z-index: 5;
    }

    &--rise {
      z-index: 6;
    }
  }

  .salary__salary-additional-label {
    & > span {
      font-size: 10px;
    }
  }

  .salary__hours-container {
    margin-top: 0;
  }
}
