.button-loading {
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.button-loading div {
  position: absolute;
  top: -6px;

  width: 13px;
  height: 13px;

  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.button-loading div:nth-child(1) {
  left: 8px;
  animation: button-loading1 0.6s infinite;
}

.button-loading div:nth-child(2) {
  left: 8px;
  animation: button-loading2 0.6s infinite;
}

.button-loading div:nth-child(3) {
  left: 32px;
  animation: button-loading2 0.6s infinite;
}

.button-loading div:nth-child(4) {
  left: 56px;
  animation: button-loading3 0.6s infinite;
}

@keyframes button-loading1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes button-loading3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes button-loading2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}