/* ---------------------------------- desktop ---------------------------------- */

.teachers {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2rem;
}

.teacher__profile-container {
  height: 100%;
}

.teachers__content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .loading {
    padding-top: 5rem;
  }
}

.teachers__actions-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & > .search-users__container  {
    margin-bottom: 1rem;
  }
}

.teachers__actions-bottom-container {
  position: absolute;
  right: 0;
  top: 0;

  & > span {
    display: none;
  }
}

.teachers__checkbox-label {
  margin-right: 1rem;
}

.teachers__link-button {
  min-width: 185px;
  margin-bottom: 1rem;
}

.teachers__search-container {
  position: relative;

  width: 100%;
  max-width: 500px;
  margin-right: 15px;

  svg {
    position: absolute;
    top: 50%;
    left: 12px;

    display: block;
    width: 18px;
    height: 18px;

    transform: translateY(-50%);
    stroke: $color-icon-common;
    transition: $default-transition-settings;
  }
}


.teachers__search-input {
  width: 100%;
  height: 42px;
  padding-left: 45px;
  
  font-size: $font-size-normal;

  border: 2px solid $color-font-passive;
  outline: none;

  transition: $default-transition-settings;

  &:disabled {
    background-color: $color-table-border;
    border-color: $color-table-border;

    pointer-events: none;

    &::placeholder {
      color: $color-icon-common;
    }
  }

  &:hover {
    border-color: $color-input-hover;
  }
  
  &:hover + svg {
    stroke: $color-input-hover;
  }

  &:focus {
    border-color: $color-font-interactive;
  }

  &:focus + svg {
    stroke: $color-font-interactive;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  &::placeholder {
    color: $color-font-passive;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.15s all ease-in-out;
  }

  &::-webkit-search-cancel-button{
    position:relative;
    right: 10px;
  }
}

.teachers__search-fieldset {
  display: flex;
  align-items: stretch;
  padding: 0;
  margin: 0;

  border: none;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    min-width: 90px;

    text-transform: capitalize;

    &:disabled {
      background-color: $color-table-border;
      color: $color-icon-common;

      pointer-events: none;
    }
  }
}

.teachers__search-form {
  width: 80%;
}

.teacher__form {
  padding-bottom: 2rem;
}

.teacher__fieldset {
  padding: 0;
  margin-bottom: 32px;

  border: none;
}

.teacher__input-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 600px;
}

.teacher__input-item {
  min-width: 165px;
  width: 275px;
  margin-right: 25px;
  margin-bottom: 1rem;

  &:first-child {
    width: 185px;
    margin-right: 120px;
  }

  &:nth-child(4) {
    width: 100%;
  }

  &:nth-child(7),
  &:nth-child(9),
  &:nth-child(10) {
    width: 185px;
  }
}

.teacher__button-container {
  display: flex;
}

.teacher__button {
  margin-right: 25px;
}

.teachers-section__table-container {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > .pagination-container {
    margin-top: 1rem;
  }
}

.teachers-section__table {
  margin-bottom: auto;

  th, 
  td {
    width: 8%;

    &:first-child {
      max-width: 70px;
    }

    &:nth-child(2) {
      max-width: 100px;
    }
  }
}

.teacher__error-container, 
.teacher__success-container {
  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 250px;
  padding: 15px;

  span {
    margin-right: clamp(1rem, auto, auto);
    color: #ffffff;
  }
}

.teacher__success-container {
  background-color: $color-confirme;
}

.teacher__error-container {
  background-color: $color-warning;
}

.teacher__error-close {
  padding: 0;

  font-size: 0;

  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  transition: $default-transition-settings;

  svg {
    fill: $color-font-passive;
    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: #ffffff;
    }
  }
}

.teacher__loading {
  margin-top: 100px;
}

.teachers__selector-container {
  width: 250px;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .teachers {
    padding-bottom: 0;
  }

  .teachers__content-container {
    padding: 1rem 0;
  }

  .teachers__actions-top-container {
    padding: 10px;
  }

  .teachers__checkbox,
  .teachers__checkbox-label,
  .teachers__search-form {
    display: none;
  }

  .teachers__actions-container {
    justify-content: center;
    margin-bottom: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
  }

  .teachers__link-button {
    min-width: 185px;
    margin: 0 auto;
  }

  .teachers-section__table {
    th,
    td {
      width: auto;

      &:first-child {
        max-width: 100%;
      }
  
      &:nth-child(2) {
        max-width: 100%;
      }
    }
  }

  .teacher__fieldset {
    margin: 0;
    padding: 15px;
  }

  .teacher__input-list {
    justify-content: center;
    width: 100%;
    max-width: inherit;
    margin: 0;
  }

  .teacher__input-item {
    width: 100%;
    max-width: 275px;
    margin: 0 10px;
    margin-bottom: 1rem;

    &:first-child {
      width: 100%;
      margin: 0 10px;
      margin-bottom: 1rem;
    }
   
    &:nth-child(7),
    &:nth-child(9),
    &:nth-child(10) {
      width: 100%;
    }
  }

  .teacher__button-container {
    justify-content: center;
  }

  .teacher__button {
    margin: 0 12px;
  }

  .teachers__actions-bottom-container {
    position: relative;

    display: flex;
    align-items: center;
    margin: 0 auto;

    & > span {
      display: block;
      margin-right: 10px;
    }
  }
}
