/* ---------------------------------- desktop ---------------------------------- */

.error-section {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    margin-right: 10rem;
  }
}

.error-section__message-container {
  & > h1 {
    margin-bottom: 40px;

    font-size: 60px;
    line-height: 46px;
  }

  & > p {
    margin-bottom: 1rem;
  }
}


#Polygon-1 , #Polygon-2 , #Polygon-3 , #Polygon-4 , #Polygon-4, #Polygon-5 {
  animation: float 1s infinite ease-in-out alternate;
}
#Polygon-2 {
  animation-delay: .2s; 
}
#Polygon-3 {
  animation-delay: .4s; 
}
#Polygon-4 {
  animation-delay: .6s; 
}
#Polygon-5 {
  animation-delay: .8s; 
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .error-section {
    padding: 1rem;
    flex-wrap: wrap;

    & > svg {
      width: 82%;
      height: auto;
      max-height: 500px;
      margin: 0;
      margin-bottom: 1rem;
    }
  }

  .error-section__message-container {
    text-align: center;
  }
}