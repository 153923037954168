/* ---------------------------------- desktop ---------------------------------- */

.proof-section {
  padding-bottom: 1rem;

  & > .loading-container {
    left: 50%;
  }

  &--wide {
    & > .loading-container {
      left: 55%;
    }
  }
}

.proof__create-container {
  display: flex;
}

.proof__card-container {
  flex-grow: 1;
  max-width: 40%;
  min-width: 360px;
  margin-right: auto;
  padding: 1.5rem;

  background-color: $color-background-card;
  border-radius: 5px;

  & > h3 {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.proof__templates-container {
  flex-grow: 1;
  max-width: 42%;

  & > h3 {
    margin: 0;
    margin-bottom: 1rem;

    text-align: end;
  }

  & > .void {
    padding-top: 2rem;
  }
}

.proof__templates-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.proof__templates-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 45%;
  min-width: 220px;
  margin-left: 1rem;
  margin-bottom: 1rem;

  & > button {
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
  }
}

.proof__competence-template {
  width: 100%;
  max-width: 300px;
  border: 2px dashed transparent;

  cursor: pointer;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    box-shadow: 4px 4px 3px 1px rgba(19, 123, 202, 0.4);
  }

  &--active {
    border: 2px dashed $color-status-confirmed;
    box-shadow: none;
    opacity: 0.5;

    pointer-events: none;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  &--selected {
    position: relative;

    border: none;
    box-shadow: 0 0 5px 1px rgba(19, 123, 202, 0.4);

    &::before {
      content: attr(data-action);

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #fff;
      font-weight: bold;
      font-size: 20px;

      background-color: $color-font-error;
      border-radius: 5px;

      opacity: 0;
      transition: $default-transition-settings;
    }

    &:hover,
    &:focus {
      & h4,
      & span {
        opacity: 0;
        transition: $default-transition-settings;
      }

      & p {
        opacity: 0;
      }

      box-shadow: 0 0 5px 1px rgba(221, 35, 11, 0.6);

      &::before {
        opacity: 0.7;
      }
    }
  }
}

.proof__input-container {
  width: 100%;
  margin-bottom: 1rem;
}

.proof__card-template {
  margin-bottom: 2rem;

  & > h4 {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.proof__card-template-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;

  width: 100%;
  max-width: 400px;

  border: 2px dashed $color-status-confirmed;
  background-color: #fff;

  &--empty {
    background-color: #fff;
    border-color: $color-font-warning;
  }

  &--drag {
    background-color: $color-font-interactive-hover;
  }
}

.proof__card-template-empty {
  padding: 15px;

  color: #fff;
  font-size: 14px;
  font-weight: bold;

  background-color: $color-background-attandance;
  border-radius: 5px;
}

.proof__small-card {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 12px;

  background-color: $color-background-card;
  border-radius: 5px;
  box-shadow: 4px 4px 3px 1px rgba(34, 60, 80, 0.2);

  &::after {
    content: "";

    position: absolute;
    left: 0;
    top: 47%;

    display: block;
    width: 100%;
    height: 55%;

    background-color: #ebebeb;

    transform: translateY(-50%);
  }
}

.proof__small-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  & > span {
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
  
    border-radius: 50%;
    border: 1px solid $color-timetable-border;
  }

  & > h4 {
    max-width: 85%;
    margin: 0;
    margin-right: auto;

    font-size: 18px;
    color: $color-font-interactive;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.proof__status-container {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;

  z-index: 1;
}

.proof__status-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 

  & > span {
    margin-right: 5px;

    color: #000;
    font-weight: bold;
    text-transform: capitalize;
  }

  &--approve {
    color: $color-status-confirmed;
  }

  &--not-filled {
    color: $color-warning;
  }

  &--filled {
    color: $color-status-progress;
  }
}

.proof__card-button {
  min-height: auto;
  padding: 8px;

  font-size: 14px;

  margin-top: 1rem;
  margin-left: auto;
}

.proof__action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  &:nth-child(2) {
    margin-left: 5px;
  }

  svg {
    height: 100%;

    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive;
    }
  }
}

.proof__cards-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.proof__card-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  margin-bottom: 2rem;
  
  border-radius: 5px;
  background-color: $color-background-card;
  box-shadow: 4px 4px 3px 1px rgba(34, 60, 80, 0.2);

  &:last-child {
    margin-bottom: 0;
  }

  & > h3 {
    margin-bottom: 1rem;
  }
}

.proof__card-info-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
  padding: 10px;

  border: 2px solid #fff;
  background-color: #fff;

  transition: $default-transition-settings;

  &:last-child {
    margin-bottom: 0;
  }

  & > p {
    width: 100%;
    margin: 0;
    margin-right: auto;
    margin-bottom: 5px;

    color: $color-font-interactive;
  }

  &--error {
    border-color: red;
  }
}

.proof__card-grades-list {
  display: flex;
}

.proof__grades-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
}

.proof__grades-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 270px;
  margin-right: 10px;
  margin-bottom: 10px;
  
  background-color: #fff;

  &--disabled {
    pointer-events: none;
  }
}

.proof__grades-info-description {
  display: block;
  margin-bottom: clamp(10px, 1.5vw, 1rem);

  font-weight: bold;

  span {
    color: $color-font-calendar-disabled;
  }
}

.proof__card-grades-label {
  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-right: 5px;

    font-weight: bold;
    white-space: pre-wrap;
    word-wrap: break-word;
    letter-spacing: 0;

    border: 2px solid $color-font-passive;
    border-radius: 50%;

    transition: $default-transition-settings;
    cursor: pointer;

    &:hover {
      border-color: $color-input-hover;
    }
  }
}

.proof__card-grades-input {
  position: absolute;

  height: 0;
  width: 0;

  opacity: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    & ~ span {
      border-color: $color-input-hover;
    }
  }

  &:checked ~ span {
    border-color: $color-font-interactive;

    &:hover,
    &:focus {
      border-color: $color-font-interactive;
    }
  }
}

.proof__card-notes-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  min-width: 220px;
  margin: 10px 0;

  & > label {
    display: none;
    width: 100%;
    padding-left: 2px;
    margin-bottom: 5px;

    font-size: 12px;
    text-align: right;
  }
}

.proof__card-notes-input {
  width: 100%;
  min-height: 78px;
  padding: 8px;

  font-size: 12px;

  border: 2px solid $color-font-passive;
  border-radius: 5px;

  outline: none;
  resize: none;

  transition: $default-transition-settings;

  &::placeholder {
    color: $color-font-passive;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.15s all ease-in-out;
  }

  &:hover {
    border-color: $color-input-hover;
  }

  &:focus {
    border-color: $color-font-interactive;
  }

  &:disabled {
    border-color: $color-font-passive;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.proof__main-header {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 1rem;
  padding: 1rem;

  background-color: $color-background-salary;

  & > span {
    display: block;
    width: 45px;
    height: 45px;
    margin-right: 1rem;

    border-radius: 50%;
    border: 2px solid $color-font-passive;
  }

  & > h2 {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;

    font-size: 22px;
    color: $color-font-interactive-dark;
  }
}

.proof__submit-buttons-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  button {
    &:nth-child(2) {
      margin-left: 2rem;
    }
  }
}

.proof__submit-button {
  width: 300px;

  &:disabled {
    color: $color-font-passive;

    background-color: transparent;
    border: 2px dashed $color-font-passive;
  }
}

.proof__header-description-container {
  display: flex;
}

.proof__header-description {
  margin-right: 2rem;
  margin-bottom: 15px;

  & > p {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    & > span {
      margin-left: 5px;

      font-weight: bold;
      color: $color-font-interactive;
    }
  }

  &--last {
    margin-right: 280px;
  }

  &--textarea {
    display: flex;
    width: 100%;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 0;

    & > p {
      width: 100%;
      height: 100%;
      padding: 5px 10px;

      background-color: #fff;
    }
  }
}

.proof__header-buttons-container {
  position: absolute;
  top: 1rem;
  right: 1rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  max-width: 330px;

  button {
    min-width: 150px;
    width: auto;
    min-height: 30px;
    height: 30px;
    margin-left: auto;
  }

  & > .download-button__download-container {
    margin-left: 8px;
  }
}

.proof__link-info-container {
  margin-left: 1rem;

  & > .proof__link-view-button {
    display: flex;
    align-items: center;
    min-width: auto;
    min-height: auto;
    padding: 5px 10px;
    margin: 0;

    font-weight: bold;

    border: 1px solid $color-font-interactive;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    outline: none;
    cursor: pointer;

    transition: $default-transition-settings;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #fff;
      background-color: $color-font-interactive;

      & > svg {
        fill: #fff;
      }
    }
  
    & > svg {
      width: auto;
      height: 12px;
      margin-left: 8px;

      transition: $default-transition-settings;
    }

    &--active {
      color: #fff;
      background-color: $color-font-interactive;

      & > svg {
        fill: #fff;
      }
    }
  }
}

.proof__notification-info-caontainer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-end;
  margin-left: auto;
  width: 100%;
  margin-bottom: 5px;

  & > .proof__notification-view-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    min-height: auto;
    height: 100%;
    margin: 0;
    padding: 5px;

    font-weight: bold;

    border: none;
    background-color: #fff;
    outline: none;
    cursor: pointer;

    transition: $default-transition-settings;

    &:hover,
    &:focus {
      & > svg {
        stroke: $color-font-interactive;
      }
    }
  
    & > svg {
      width: auto;
      height: 24px;

      fill: #fff;
      stroke: #000;

      transition: $default-transition-settings;
    }

    &--active {
      &:hover,
      &:focus {
        & > svg {
          stroke: #fff;
          fill: $color-font-interactive;
        }
    }

      & > svg {
        stroke: #fff;
        fill: $color-font-interactive;
      }
    }
  }
}

.proof__notification-info-list,
.proof__link-info-list {
  position: absolute;
  top: 90px;
  left: -50px;

  display: flex;
  flex-wrap: wrap;
  width: 380px;
  padding: 1rem;

  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.proof__notification-info-item,
.proof__link-info-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & > label {
    width: 100%;
    margin-bottom: 5px;

    font-size: 12px;
    font-weight: bold;
  }

  & > input {
    flex-grow: 1;
    margin: 0;
    padding: 0 5px;
    padding-right: 10px;

    border: 2px solid $color-font-passive;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    outline: none;

    &:hover,
    &:focus {
      border-color: $color-font-interactive;
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    min-height: auto;
    margin: 0;
    padding: 0;
    width: 35px;

    font-size: 0;

    border: 2px solid $color-font-passive;
    border-left: none;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
      & > svg {
        stroke: $color-font-interactive;
        fill: $color-background-card;
      }
    }

    & > svg {
      width: 25px;
      height: auto;

      transition: $default-transition-settings;
    }
  }
}

.proof__notification-info-item {
  gap: 5px;
}

.proof__info-date {
  width: 100%;
  margin-bottom: 2px;

  font-size: 16px;
  color: $color-font-interactive;
}

.proof__info-description {
  font-size: 15px;
}

.proof__info-details-container {
  width: 100%;
  padding: 0 10px;

  & > article {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-size: 13px;
  }

  p {
    width: 100%;
    margin: 1px 0;

    span {
      font-weight: bold;
      color: $color-font-interactive;
    }
  }
}

.proof__info-details-status,
.proof__info-details-description {
  display: inline-block;
  max-width: 150px;
  margin-left: 3px;
  padding: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.proof__info-details-status {
  font-weight: bold;
  color: $color-font-warning;

  &--error {
    color: $color-font-error;
  }

  &--done {
    color: $color-font-success;
  }
}

.proof__note-container {
  & > label {
    display: block;
    margin-bottom: 5px;

    font-size: 16px;
    font-weight: bold;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  & > textarea {
    min-height: 125px;
    padding: 10px;
  }
}

.proof__header-status {
  display: inline-block;
  padding: 5px;

  font-weight: bold;
  text-align: right;

  background-color: #fff;
}

.proof__popup-svg {
  display: block;
  width:100px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 2rem;

  stroke: $color-font-warning;
}

.proof__popup-description {
  display: block;
  width: 80%;
  margin: 0 auto;

  font-size: 20px;
  text-align: center;
}

.proof__popup-buttons-contaier {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;

  & > button {
    margin-left: 1rem;
    margin-bottom: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.proof__card-note-alert {
  display: block;
  margin-top: 1rem;
  padding: 1rem;

  color: #fff;
  font-weight: bold;

  background-color: $color-font-warning;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .semester__content-container {
    max-width: 100%;
    padding: 0;
    padding-bottom: 25px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .proof-section {
    &--wide {
      & > .loading-container {
        top: 50%;
        left: 50%;
      }
    }
  }

  .proof__main-header {
    align-items: center;
    justify-content: center;

    & > span {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      margin-bottom: 10px;

      order: -1;
    }
  
    & > h2 {
      width: auto;
      margin-bottom: 10px;
    }
  }

  .proof__card-info-container {
    border-radius: 5px;

    & > p {
      max-width: 100%;
      width: 100%;
      margin: 0;
      margin-bottom: 1rem;

      font-size: 13px;
      text-align: center;
    }
  }

  .proof__header-description {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;

    text-align: center;

    &--textarea {
      margin-bottom: 0;
    }
  }

  .proof__grades-container {
    width: 100%;
  }

  .proof__grades-info-container {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .proof__card-grades-label {
    & > span {
      width: 35px;
      height: 35px;
    }
  }

  .proof__card-grades-item {
    &--disabled {
      pointer-events: none;
    }
  }

  .proof__grades-info-description {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;

    font-size: 14px;
    text-align: center;
  }

  .proof__card-item {
    box-shadow: none;
    & > h3 {
      text-align: center;
    }
  }

  .proof__cards-list {
    padding: 0 16px;
  }

  .proof__card-grades-list {
    margin-right: 10px;
  }

  .proof__card-notes-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    & > label {
      text-align: center;
    }
  }

  .proof__card-notes-input {
    margin-right: 0;
  }

  .proof__notification-info-caontainer {
    justify-content: center;
  }

  .proof__header-buttons-container {
    position: relative;
    top: 0;
    left: 0;

    justify-content: center;
    width: 100%;
    margin-bottom: 10px;

    & > button {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    & > .download-button__download-container {
      margin: 0;
    }
  }

  .proof__submit-buttons-container {
    width: 100%;
    padding: 0 1rem;

    button {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;

      &:nth-child(2) {
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
  }

  .proof__note-container {
    padding: 0 16px;

    text-align: center;
  }

  .proof__header-status {
    display: inline-flex;
    justify-content: center;
  }

  .proof__notification-info-list,
  .proof__link-info-list {
    left: 50%;

    width: 90vw;
    min-width: 300px;
    max-width: 350px;

    transform: translateX(-50%);
  }
}
