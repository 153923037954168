/* ---------------------------------- desktop ---------------------------------- */

.class-view-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
}

.class-view-section__switch-container {
  margin-right: 1rem;
}

.class-view-section__selector {
  width: 20%;
  min-width: 175px;
  margin-right: 25px;
}

.class-view-section__buttons-container {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 30px;

  .class__donwload-container {
    bottom: 0;
  }
}

.class-view-section__content-container {
  flex-grow: 1;
}

.class-view-section__management-container {
  display: flex;
}

.class-view-section__actions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  button {
    min-width: 180px;
  }
}

.class-view-section__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 10%;

  span {
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.class-group__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.class-group__button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-bottom: 1rem;
  }
}

.class-group__button-container-text {
  text-align: center;
  font-size: 18px;
}
.class-groups__item-group-header {
  display: flex;
  align-items: center;
}

.class-groups__item-edit-remove-container {
  display: flex;
  align-items: flex-end;
  margin-left: 15px;
}

.class-groups__item-edit-link {
  color: #1672EC;
  cursor: pointer;
  margin-right: 25px;
}

.class-groups__item-remove-link {
  color: #EC1616;
  cursor: pointer;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .class-view-section__management-container {
    // margin-bottom: 1rem;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .class-view-section {
    padding-bottom: 0;
  }

  .class-view-section__buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > button {
      margin-left: 1rem;
      font-size: 12px;
    }
  }

  .class-view-section__switch-container {
    margin: 0 auto;
    width: 100%;
  }

  .class-view-section__management-container {
    a {
      width: 50%;
    }
  }

  .class-view-section__actions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-left: 1rem;

    button {
      min-width: 120px;
      font-size: 12px;
    }
  }

  .class-view-section__actions-sample {
    position: initial;
  }

  .class-view-section__sample-list {
    position: absolute;
    top: -100%;
    left: 50%;

    width: 280px;
    margin: 0;
    padding: 0;

    list-style: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .class-view-section__sample-item {
    width: 100%;
  }

  .class-view-section__loading {
    span {
      font-size: 18px;
    }
  }

  .class-view-section__selector {
    margin: 0 auto;
    margin-bottom: 10px;
    padding-top: 1rem;

    text-align: center;

    span {
      font-size: 18px;
    }
  }

  .class-groups__item-group-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
}
