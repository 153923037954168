/* ---------------------------------- desktop ---------------------------------- */

.fulltime-timetable {
  position: relative;
  top: -62px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-bottom: -62px;
}

.fulltime-timetable__content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;
    margin-top: auto;

    transform: translate(-50%, -50%);
  }

  .void {
    padding: 1rem;
  }
}

.fulltime-timetable__header {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: calc(100% - 350px);

  .input-container {
    flex-grow: 1;
    max-width: 340px;
  }

  z-index: 5;
}

.fulltime-timetable__header-button-container {
  display: flex;
  align-items: flex-end;
  margin-left: 5px;
  margin-top: 1rem;
}

.fulltime-timetable__header-button {
  display: none;
}

.fulltime-timetable__selector-container {
  min-width: 160px;
  width: 100%;
}

.fulltime-timetable__table-container {
  overflow-x: auto;
}

.fulltime-timetable__table {
  --cellWidth: calc(145px + (250 - 110) * ((100vw - 1024px) / (1920 - 1024)));
  // --cellWidth: 200px;

  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 20px;
}

.fulltime-timetable__th {
  &:first-child {
    min-width: calc(var(--cellWidth) / 2);
    width: calc(var(--cellWidth) / 2);

    font-weight: bold;

    background-color: #fff;
  }
}

.fulltime-timetable__td {
  position: relative;

  min-width: 180px;
  width: var(--cellWidth);
  max-width: var(--cellWidth);

  background-color: rgba(137, 140, 163, 0.05);
  border-radius: 5px;
  height: 140px;

  z-index: 0;

  &:first-child {
    min-width: 100px;
    width: calc(var(--cellWidth) / 2);

    font-weight: bold;

    background-color: #fff;
    border-radius: 0;
  }

  .fulltime-timetable__button {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
    padding: 0;

    border: none;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;

    outline: none;
    transition: $default-transition-settings;

    &:hover,
    &:focus {
      background-color: rgba(138, 225, 252, 0.3);
    }

    &:active {
      background-color: rgba(138, 225, 252, 0.6);
    }
  }
}

.fulltime-timetable__lesson-card {
  position: relative;

  width: 100%;
  height: 100%;

  padding: 8px;

  font-size: 14px;

  border: none;
  border-radius: 5px;
  cursor: pointer;

  outline: none;
  transition: $default-transition-settings;

  span {
    text-align: left;
  }

  &::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    border-radius: 5px;
    background: inherit;

    opacity: 1;

    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    &::before {
      filter: blur(5px);
    }
  }

  &--half::after {
    content: "";

    position: absolute;
    left: 50%;
    top: 50%;

    display: block;
    width: 1px;
    height: 80%;

    background-color: $color-font-warning-main;
    opacity: 0.4;

    z-index: 1;
    transform: translateY(-50%);
  }
}

.fulltime-timetable__date {
  display: none;
  justify-content: flex-start;
  padding: 5px 0;
  margin-bottom: 10px;

  border-bottom: 1px solid rgba(21%, 22%, 25%, 0.3);

  span {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

.fulltime-timetable__lesson-card-container {
  position: relative;

  display: flex;
  justify-content: space-between;
  padding: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.fulltime-timetable__group-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  max-width: 50%;
  padding: 5px;

  h4 {
    width: fit-content;
    margin: 0 auto;
    padding: 3px 10px;
    margin-bottom: 6px;

    font-weight: bold;
    font-size: 16px;
    color: #fff;
    text-shadow: 1px 1px 2px #000;

    background-color: rgba(54, 56, 64, 0.3);
    border-radius: 5px;
  }

  &--full {
    max-width: 100%;
  }
}

.fulltime-timetable__group-lesson {
  margin-bottom: 5px;

  font-weight: bold;
  color: $color-font-interactive-dark;
}


.fulltime-timetable__group-room {
  width: 80%;
  margin: 0 auto;
}

.fulltime-timetable__group-teacher {
  padding: 5px;
}

.fulltime-timetable__confirme-container {
  display: flex;
  padding: 10px 5px;
  width: 100%;
}

.fulltime-timetable__button-container {
  display: none;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .timetable-section__management-button {
    min-width: 60px;

    &--status {
      display: none;
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .fulltime-timetable{
    top: 0;
  }

  .fulltime-timetable__header {
    justify-content: center;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding: 0 1rem;
  
    .input-container {
      max-width: 100%;
      width: 100%;
      text-align: center;
    }
  }

  .fulltime-timetable__header-button {
    display: block;
    margin-top: 1rem;
  }

  .fulltime-timetable__table-container {
    padding: 10px;
  }

  .fulltime-timetable__table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      min-width: 295px;
      margin: 0 auto;
    }
  }

  .fulltime-timetable__tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .fulltime-timetable__td {
    display: none;
    height: auto;

    &--content {
      display: flex;
      max-width: 100%;
      min-width: auto;
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .fulltime-timetable__lesson-card {
    font-size: 16px;

    &--half::after {
      content: "";
  
      display: block;
      width: 1px;
      height: 85%;
  
      background-color: $color-background-table-day-header;
      opacity: 0.5;
  
      z-index: 1;
    }
  }

  .fulltime-timetable__lesson-card-container {
    flex-wrap: wrap;
    padding: 8px;
  }

  .fulltime-timetable__date {
    display: flex;
    width: 100%;

    font-weight: bold;
  }

  .fulltime-timetable__button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .fulltime-timetable__confirme-container {
    display: flex;
    justify-content: center;
  }
}