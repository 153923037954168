/* ---------------------------------- desktop ---------------------------------- */

.teacher-timetable {
  padding-bottom: 1rem;

  .void {
    display: none;
  }
}

.teacher-timetable__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.teacher-timetable__selector-container {
  width: 250px;
  margin-bottom: 1rem;

  .input-select__single-value {
    padding-left: 2rem;
  }
}

.teacher-timetable__input-container {
  margin-right: auto;
}

.teacher-timetable__link-button {
  margin-bottom: 1rem;
}

.teacher-timetable__warning {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 25px;
  margin-bottom: 1rem;

  color: $color-font-warning;

  svg {
    min-width: 25px;
    height: auto;
    margin-right: 10px;
  }
}

.teacher-timetable__table-container {
  width: 100%;
  padding-bottom: 20px;

  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;

    cursor: pointer;
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.teacher-timetable__table {
  position: relative;
  --table-td-width: 160px;

  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  z-index: 0;

  td {
    position: relative;

    min-width:  var(--table-td-width);
    width: var(--table-td-width);
    height: clamp(115px, 12vh, 300px);

    border-bottom: 2px solid $color-teacher-timetable-border;
    z-index: 1;

    &:first-child {
      text-align: center;
      font-weight: bold;
      
      border: 2px solid $color-teacher-timetable-border;
      background-color: #fff;
    }

    &:nth-child(2n + 1),
    &:last-child {
      border-right: 2px solid $color-teacher-timetable-border;
    }
  }

  th {
    height: 35px;

    text-align: center;
    
    border: 2px solid $color-teacher-timetable-border;
    color: $color-font-interactive;

    &:first-child {
      width: 110px;
      border: none;
    }
  }
}

.teacher-timetable___tr {
  position: relative;
  z-index: 1;

  font-size: 18px;

  &:nth-child(2n + 2) {
    background-color: #f1f1f1;

    td {
      pointer-events: none;
    }
  }
}

.teacher-timetable___td {
  pointer-events: none;

  div {
    pointer-events: initial;
  }
}

.teacher-timetable__lesson-card {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: auto;
  padding: 8px;

  border: 2px solid black;
  border-radius: 5px;

  z-index: 2;
}

.teacher-timetable__lesson-card-container {
  position: relative;

  padding: 10px 8px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);

  border-radius: 8px;

  z-index: 2;
}

.teacher-timetable__lesson-sample {
  & > .table__sample-list {
    top: 10px;
    right: 35px;

    .table__sample-item {
      width: 220px;
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    padding: 0;

    border: none;
    outline: none;
    background-color: transparent;

    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      transition: $default-transition-settings;
    }

    &:hover {
      svg {
        fill: $color-font-interactive;
      }
    }
  }
}

.teacher-timetable__lesson-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  h4 {
    display: block;
    max-width: 90%;

    margin: 0;
    padding: 0;

    color: $color-font-interactive;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.teacher-timetable__lesson-content-container {
  display: flex;
  flex-direction: column;

  span {
    display: block;
    max-width: 90%;

    color: #000;
    font-size: 14px;

    white-space: nowrap;
    text-overflow: ellipsis;
  
    overflow: hidden;
  }
}

.teacher-timetable__lesson-description {
  display: flex;
  align-items: center;
  gap: 2px;
  width: auto;
  max-width: 90%;
  margin: 0;
  margin-bottom: 3px;

  color: $color-font-disabled;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;

  & > span {
    display: block;

    color: $color-font-disabled;
    font-size: 12px;
  }

  &--mobile {
    display: none;
  }
}

.teacher-timetable__lesson-status {
  position: absolute;
  bottom: 3px;
  right: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;

    fill: transparent;
  }
}

.teacher-timetable__lesson-additioanl {
  position: absolute;
  top: -10px;
  left: -20px;

  display: block;
  width: fit-content;
  padding: 2px 5px;

  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #fff;

  background-color: $color-font-warning;
  border-radius: 4px;
  border: 2px solid #000;
}

.teacher-timetable__popup {
  display: flex;
  flex-direction: column;
  width: 510px;
  min-width: auto;
  min-height: 450px;
  padding: 45px;

  overflow-x: hidden;
  z-index: 7;
}

.teacher-timetable__popup-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  & > svg {
    margin-bottom: 10px;
  }

  h3 {
    margin: 0;
    margin-bottom: 15px;

    font-size: 34px;
  }

  p {
    max-width: 350px;
    margin: 0;
    margin-bottom: auto;

    text-align: center;
    font-size: 20px;
  }

  .teacher-timetable__error-span {
    display: block;
    margin: auto 0;
    padding: 8px;

    color: #fff;
    background-color: $color-font-error;
  }
}

.teacher-timetable__popup-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  .react-select__menu-list {
    height: 500px;
  }

  fieldset {
    margin: 0;
    margin-bottom: auto;
    margin-bottom: auto;
    padding: 0;

    border: none;

    li {
      margin-bottom: 15px;
    }
  }
  
  label {
    font-size: 12px;
  }
}

.teacher-timetable__buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  button {
    &:first-child {
      margin-right: 15px;
    }
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .teacher-timetable {
    padding-bottom: 0;

    .void {
      display: flex;
    }
  }

  .teacher-timetable__header {
    justify-content: center;
    padding: 15px;
    margin-bottom: 0;
  }

  .teacher-timetable__input-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .teacher-timetable__selector-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .teacher-timetable__warning {
    width: 100%;
    margin-right: 0;
    justify-content: center;

    text-align: center;

    svg {
      display: none;
    }
  }
  
  .teacher-timetable__table  {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 15px;
    }

    td {
      position: relative;
  
      min-width:  var(--table-td-width);
      width: 100%;
      max-width: 400px;
      height: 145px;
      margin: 0 auto;
      margin-bottom: 1rem;
  
      border: none;
      z-index: initial;
  
      &:first-child {
        display: none;
      }
  
      &:nth-child(2n + 1),
      &:last-child {
        border: none;
      }
    }
  
    th {
      height: 35px;
  
      text-align: center;
      
      border: none;
  
      &:first-child {
        width: 110px;
        border: none;
      }
    }
  }

  .teacher-timetable___tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &:nth-child(2n + 2) {
      background-color: #fff;
    }

    td {
      &:first-child {
        display: block;
        height: fit-content;

        background-color: #fff;
      }
    }
  }

  .teacher-timetable__lesson-card {
    position: relative;
    left: 0 !important;

    max-width: 100%;
    min-width: 100%;
  }

  .teacher-timetable___td {
    display: none;

    &--card {
      display: block;
    }
  }

  .teacher-timetable__popup {
    width: 100%;
    height: 100vh;
    padding: 35px 15px;

    overflow: auto;

    h3 {
      margin-bottom: 2rem;
      font-size: 30px;
    }
  }

  .teacher-timetable__lesson-header {
    margin-bottom: 5px;
  }

  .teacher-timetable__lesson-description {
    font-size: 14px;

    & > span {
      margin-left: 5px;
  
      font-size: 14px;
    }

    &--mobile {
      display: flex;
    }
  }

  .teacher-timetable__lesson-sample {
    & > .table__sample-list {
      top: 65px;
      right: 35px;
    }
  }

  .teacher-timetable__table-container {
    padding-bottom: 0;
  }

  .teacher-timetable__link-button {
    margin-bottom: 0;
  }

  .teacher-timetable__lesson-additioanl {
    top: -15px;
    left: 50%;

    transform: translateX(-50%);
  }
}
