/* ---------------------------------- desktop ---------------------------------- */

.grades-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.grades__header {
  position: relative;

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 2rem;

  z-index: 2;
}

.grades__header-switch-container {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}

.grades__header-switch-button {
  width: 50%;
  padding: 0 10px;
  margin-bottom: 1rem;
}

.grades__header-link {
  width: 100%;
}

.grades__content-container {
  flex-grow: 1;
  padding-bottom: 2rem;

  & > .loading-container {
    position: relative;
    top: 50%;
    left: 50%;

    margin-bottom: auto;

    transform: translate(-50%, -50%);
  }
}

.grades__filters-container {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  flex-wrap: wrap;
  margin-right: auto;

  .input-container {
    flex-grow: 1;
    min-width: 220px;
    max-width: 280px;
  }
}

.grades__header-buttons-container {
  display: flex;
  flex-wrap: wrap;
}

.grades__form-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;

  border: none;
}

.grades__input-container {
  width: 213px;

  & > label {
    font-size: 12px;
  }
}

.grades__teacher-container {
  width: 100%;
}

.grades__date-container {
  display: flex;
  flex-direction: column;

  & > label {
    font-size: 12px;
  }
}

.grades__input-container:not(:first-child) {
  margin-left: 20px;
}

.grades__buttons-container {
  margin-top: 20px;
}

/* ------------------------ grade-table ------------------------ */

.grades__error-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $color-warning;
  color: #FFFFFF;
  padding: 3px 7px 3px 7px;
  margin-left: 13px;
}

.grades__error-close {
  padding: 0;
  font-size: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  transition: $default-transition-settings;

  svg {
    fill: $color-font-passive;
    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: #ffffff;
    }
  }
}

.grades__grade-card {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  height: 100%;

  border-radius: 5px;
  border: 2px solid rgba(175, 175, 202, 0.3);
  // background-color: $color-background-card;

  overflow: hidden;
  box-shadow: 2px 2px 5px 0px rgba(34, 60, 80, 0.2);
}

.grades__grade-decoration {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 5px;

  border-radius: 50%;
  border: 1px solid $color-popup-border;
}

.grades__grade-card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.grades__exam-info {
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-bottom: auto;
  padding: 1rem;

  background-color: #fff;
}

.grades__exam-legend {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: auto;
}

.grades__exam-data {
  width: 100%;
  margin-bottom: 5px;

  font-size: 18px;
  font-weight: bold;
  color: $color-font-interactive-dark;
}

.grades__legend-info-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.grades__text-info {
  margin-right: 1rem;

  span {
    color: $color-font-interactive-dark;
    font-weight: 500;
  }
}

.grades__actions-container {
  position: absolute;
  right: 1rem;

  display: flex;

  .button {
    min-height: 30px;
    height: 30px;
    width: 100px;

    &:first-child {
      margin-right: 1rem;
    }
  }
}

.grades__action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  &:nth-child(2) {
    margin-left: 5px;
  }

  svg {
    height: 100%;

    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive;
    }
  }
}

.grades__grade-form {
  background-color: $color-background-card;

  h4 {
    margin: 0;
    margin-bottom: 1rem;
    padding: 1rem;
    padding-bottom: 0;
  }
}

.grades__grade-list {
  margin-bottom: 1rem;
}

.grades__grade-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 35px;
  margin-bottom: 8px;
  padding: 0 1rem;
  padding-right: 0;

  background-color: #fff;

  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    max-width: 40%;

    font-size: 14px;
    font-weight: 500;
    color: $color-font-interactive;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* ------------------------ grade-picker ------------------------ */

.grade-picker {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;

  border: none;

  overflow: hidden;
}

.grade-picker__button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 100%;

  font-size: 0;

  border: none;
  outline: none;
  background-color: $color-font-calendar-disabled;

  cursor: pointer;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    background-color: $color-background-notification;

    svg {
      fill: #fff;
    }
  }

  &:disabled {
    pointer-events: none;
    background-color: $color-font-passive;
  }

  svg {
    width: auto;
    height: 13px;

    transition: $default-transition-settings;
  }

  &--down {
    svg {
      transform: rotate(90deg);
    }
  }

  &--up {
    svg {
      transform: rotate(270deg);
    }
  }

  &--present,
  &--absent {
    padding: 0;

    background-color: #fff;
    border: 1px solid $color-font-success;

    svg {
      width: 70%;
      height: auto;
    }

    &:hover,
    &:focus {
      background-color: #fff;
      border-color: $color-font-interactive;

      svg {
        fill: $color-font-interactive;
      }
    }
  }

  &--present {
    svg {
      fill: $color-font-success;
    }
  }

  &--absent {
    border-color: $color-font-error;

    svg {
      fill: $color-font-error;
    }
  }
}

.grade-picker__input {
  width: 60px;
  height: 100%;
  flex: 1;
  pointer-events: none;
  cursor: none;
  user-select: none;
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  border: 2px solid transparent;
  border-top: none;
  border-bottom: none;
  outline: none;
  background-color: transparent;

  user-select: none;

  transition: 0.35s ease background-color;

  &:hover,
  &:focus {
    border-color: $color-background-notification;
    background-color: $color-font-interactive-hover;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: transparent;

    background-color: $color-font-calendar-disabled;
    pointer-events: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &--bad {
    color: $color-warning;
  }

  &--average {
    color: $color-font-warning;
  }

  &--average {
    color: $color-font-interactive;
  }

  &--well {
    color: $color-status-confirmed;
  }
}

.grades__card-buttons-container {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  padding-top: 0;

  button {
    width: 140px;
    height: 30px;
    min-height: 32px;
  }

  .button-loading {
    width: 80%;
  }
}

.grades__proof-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(100% / (4 + 1) + 0.1%, 400px, 100%), 1fr));
  gap: 1.5rem;
}

.grades__proof-item {
  grid-auto-rows: 1fr;

  &--one {
    max-width: 750px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .grades__content-container {
    flex-grow: 1;
    padding-bottom: 1rem;

    & > .void {
      padding-top: 2rem;
    }

    & > .loading-container {
      position: relative;
      top: 50%;

      padding-top: 1rem;
    }
  }

  .grades__header {
    padding: 1rem;
  }

  .grades__filters-container {
    width: 100%;
    justify-content: center;
    padding: 0;

    .input-container {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;

      text-align: center;

      &:last-child {
        width: 100%;
      }
    }
  }

  .grades__table-container {
    padding: 0 1rem;
  }

  .grades__grade-card {
    flex-direction: column;

    border: 2px solid $color-background-notification;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .grades__grade-card-content {
    width: 100%;
  }

  .grades__actions-container {
    position: absolute;
    top: 1rem;
    right: 1rem;

    flex-direction: row;

    .button {
      min-width: auto;
      width: 100px;
      min-height: 20px;
      height: 20px;
      margin: 0;

      font-size: 14px;

      &:first-child {
        margin: 0;
        margin-bottom: 8px;
      }
    }
  }

  .grades__grade-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    padding: 10px;

    background-color: #fff;
  }

  .grades__grade-item {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;

    border: none;

    &::after {
      display: none;
    }

    span {
      max-width: 100%;
      width: 100%;
      margin-bottom: 5px;

      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .grades__grade-decoration {
    position: absolute;
    top: -14px;
    left: -14px;

    width: 30px;
    height: 30px;
  }

  .grades__grade-form {
    background-color: #fff;

    & > h4 {
      margin: 0;
      padding: 10px;

      text-align: center;

      background-color: $color-font-interactive-hover;
    }
  }

  .grade-picker__input {
    flex-grow: 1;
    width: 25%;

    font-size: 15px;

    background-color: $color-popup-border;
  }

  .grade-picker__button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 35px;
    height: 100%;

    svg {
      height: 12px;
    }
  }

  .grades__header-buttons-container {
    display: flex;
    justify-content: center;
    width: 100%;

    & > a {
      &:last-child {
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
  }

  .grades__header-link {
    width: 80%;
    min-width: 200px;
    max-width: 360px;
    margin: 0 auto;

    text-align: center;
  }

  .grades__input-container:not(:first-child) {
    margin-left: 0px;
  }

  .grades__date-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .grades__date-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 10px;
    width: 100%;
  }

  .grades__input-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;

    & > label {
      width: 100%;

      text-align: center;
    }

    & .react-datepicker-wrapper {
      width: 100%;
    }
  }

  .grades__form-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-end;

    border: none;
  }

  .grades-container {
    height: 100%;
  }

  .grades__form-wrapper {
    height: 100%;
  }

  .grades__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1rem 2rem;
  }

  .grades__buttons-container {
    margin: 0px 0px 10px 0px;
    display: flex;
    justify-content: center;
  }

  .grades__error-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: $color-warning;
    color: #FFFFFF;
    padding: 3px 7px 3px 7px;
    margin-left: 0px;
  }

  .grades__card-buttons-container {
    width: 100%;

    .button {
      min-width: 260px;
      width: 50%;
      margin: 0 auto;
    }
  }

  .grades__proof-item {
    width: 100%;
    margin: 0;
  }

  .grade-picker {
    width: 100%;
    max-width: 350px;
    height: 30px;
  }
}