/* ---------------------------------- desktop ---------------------------------- */

.gratitude-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  background: rgb(2,0,36);
  background: linear-gradient(346deg, rgba(2,0,36,1) 0%, rgba(9,9,121,0.8155637254901961) 41%, rgba(0,212,255,1) 100%);
  background-image: url('../../assets/img/PngItem_5131515.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gratitude__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 3rem;

  background-color: #fff;
  border-radius: 5px;

  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

  svg {
    width: 80px;
    height: auto;
    margin-bottom: 1rem;
  }
}

.gratitude__message {
  position: relative;

  display: inline-block;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  font-size: 30px;
  font-weight: bold;

  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 50%;

    display: block;
    width: 30%;
    height: 8px;

    border-radius: 4px;
    background-color: $color-font-interactive;
  
    transform: translateX(-50%);
  }
}

.gratitude__gratitude-description {
  display: inline-block;
  margin-bottom: 3rem;

  font-size: 20px;
  text-align: center;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .gratitude-section {
    justify-content: center;
    min-height: 100vh;
  
    background-image: url('../../assets/img/blue-background-images-for-websites.jpg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .gratitude__container {
    width: 100%;
    padding: 2rem 1rem;
    margin-top: 20vh;

    box-shadow: none;

    svg {
      width: 80px;
      height: auto;
      margin-bottom: 1rem;
    }
  }

  .gratitude__message {
    font-size: 24px;
  }

  .gratitude__gratitude-description {
    font-size: 16px;
  }
}
