/* ---------------------------------- desktop ---------------------------------- */

.competence {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  max-height: 100%;
  height: 100%;
}

.competence__header {
  position: relative;
  z-index: 3;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 1rem;
  gap: 1rem;
}

.competence__header-link {
  height: 42px;
  margin-right: auto;
}

.competence__header-switch-container {
  display: flex;
}

.competence__selector-container {
  min-width: 300px;
  max-width: 700px;
  height: auto;

  .input-select__multi-value {
    color: #fff;
    background-color: $color-font-success;

    & > .input-select__multi-value__label {
      color: #fff;
    }
  }
}

.competence__card-component {
  display: flex;
}

.competence__card-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.competence__card-container {
  display: flex;
  flex-direction: column;
  width: 45vw;
  min-width: 350px;
  max-width: 850px;
  max-height: 88vh;
  margin: 0;
  margin-right: 3rem;
  padding: 1.5rem;

  border-radius: 5px;
  border: none;
  background-color: $color-background-card;

  h4 {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.competence__card-name {
  width: 75%;
  margin-bottom: 1rem;
  padding: 5px 10px;

  font-size: 18px;
  font-weight: bold;
  color: $color-font-interactive;

  border: none;
  border-bottom: 2px solid $color-font-passive;
  background-color: transparent;

  outline: none;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    border-color: $color-input-hover;
  }

  &::placeholder {
    color: $color-font-passive;
  }

  &:valid:not(:placeholder-shown) {
    border-color: $color-font-interactive;
  }

  &:valid:not(:placeholder-shown):hover,
  &:valid:not(:placeholder-shown):focus {
    border-color: $color-input-hover;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.15s all ease-in-out;
  }

  &--error {
    border-color: $color-font-error;

    &::placeholder {
      color: $color-font-error;
      opacity: 0.6;
    }
  }
}

.competence__tags-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.competence__tags-item {
  position: relative;

  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 1rem;
  margin-bottom: 5px;

  border: 1px solid $color-font-passive;
  border-radius: 5px;
  background-color: #fff;

  &--error {
    border-color: $color-font-error;
  }
}

.competence__card-tag {
  min-width: 6ch;
  max-width: 350px;
  padding: 5px 10px;

  font-size: 12px;
  text-align: center;
  font-weight: 500;
  color: $color-font-interactive-dark;

  border: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: $color-font-interactive-hover;
  outline: none;

  transition: $default-transition-settings;

  &:hover,
  &:focus {
    color: $color-font-main;
    border-color: $color-font-interactive;

    background-color: #fff;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.15s all ease-in-out;
  }
}

.competence__submit-button {
  width: 60%;
  margin-top: 1rem;
}

.competence__features-list {
  margin-bottom: auto;

  overflow: auto;

  transition: $default-transition-settings;

  &--dragged {
    background-color: $color-font-interactive-hover;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.competence__feature-item {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 2rem;

  border-radius: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";

    position: absolute;
    left: 10px;
    top: 50%;

    display: block;
    width: 10px;
    height: 10px;

    border-radius: 50%;
    background-color: $color-background-notification;

    transform: translateY(-50%);
  }

  &--error {
    box-shadow: 0px 0px 1px 1px $color-font-error inset;

    &::before {
      background-color: $color-font-error;
    }
  }
}

.competence__card-feature {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;

  font-size: 14px;

  border: 2px solid grey;
  border: none;
  border-radius: 5px;
  outline: none;
  resize: none;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.competence__tag-button,
.competence__feature-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 5px;
  width: 15px;
  height: 15px;

  font-size: 0;

  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: auto;
    height: 100%;
    fill: $color-font-aside;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive;

      transition: $default-transition-settings;
    }
  }
}

.competence__feature-button {
  width: 20px;
  height: 20px;
}

.competence__empty-container {
  display: flex;
  margin-bottom: auto;
}

.competence__card-enter {
  width: 40%;
  margin: 0;

  border: none;

  h3 {
    margin-bottom: 1rem
  }
}

.competence__enter-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  & > textarea {
    width: 100%;
    min-height: 100px;
    padding: 1rem;
    margin-bottom: 1rem;

    border: 2px solid $color-font-passive;
    background-color: transparent;
  
    outline: none;
    resize: none;
    transition: $default-transition-settings;
  
    &:hover,
    &:focus {
      border-color: $color-input-hover;
    }
  
    &::placeholder {
      color: $color-font-passive;
    }
  
    &:valid:not(:placeholder-shown) {
      border-color: $color-font-interactive;
    }
  
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
  
    &:focus::-webkit-input-placeholder {
      color: transparent;
      transition: 0.15s all ease-in-out;
    }
  }

  & > input {
    margin-bottom: 1rem;
  }

  & > button {
    margin-left: auto;
  }
}

.competence__cards-list {
  display:grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(100%/(4 + 1) + 0.1%, 500px, 50%), 1fr));
  gap: 1.5rem;
}

.competence__cards-item {
  & > .competence__template {
    height: 100%;
  }
}

/* ----------- competence-card styles ----------- */

.competence__card {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;

  border-radius: 5px;
  background-color: $color-background-card;
  box-shadow: 4px 4px 3px 1px rgba(34, 60, 80, 0.2);
}

.competence__card-header {
  width: 100%;

  & > h4 {
    width: fit-content;
    max-width: 75%;
    margin: 0;
    margin-bottom: 8px;
    padding-right: 5px;
    padding-bottom: 2px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    border-bottom: 2px solid $color-font-interactive-dark;
  }

  &--simple {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--simple > h4 {
    display: block;
    width: 75%;
    margin-bottom: 0;

    text-align: right;

    border: none;
  }
}

.competence__card-actions-container {
  position: absolute;
  top: 15px;
  right: 15px;

  display: flex;
}

.competence__action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  &:nth-child(2) {
    margin-left: 5px;
  }

  svg {
    height: 100%;

    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive;
    }
  }
}

.competence__card-tags-list,
.competence__card-features-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.competence__card-features-list {
  border-radius: 5px;
  background-color: rgba(36%, 35%, 35%, 0.1);
}

.competence__empty-tag-list {
  margin-bottom: clamp(5px, 20%, 1rem);

  font-size: 14px;
  color: $color-warning;
}

.competence__card-tag-item {
  & > button {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 3px 5px;
  
    font-size: 12px;
    color: #fff;
    font-weight: bold;
  
    border: none;
    border-radius: 5px;
    background-color: $color-font-interactive;

    transition: $default-transition-settings;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $color-input-hover;
    }
  }

  &--active {
    & > button {
      background-color: $color-font-success;
      pointer-events: none;
    }
  }
}

.competence__card-features-list {
  margin-bottom: 0;
}

.competence__card-feature-item {
  display: flex;
  width: 100%;
  padding: 7px;

  font-size: 14px;
  color: $color-font-main;

  border-radius: 5px;
  z-index: 1;

  & > span {
    z-index: 1;
  }
}

.competence__card-feature-ordinal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;
  margin-right: 10px;

  font-size: 16px;
  font-weight: bold;
  color: $color-font-interactive;
}

.competence__card-feature-description {
  color: #000
}

.competence__template-cards-item {
  width: 30%;
  max-width: 320px;
  min-width: 220px;
}

.competence__simple-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;

  font-family: inherit;
  font-size: 16px;
  font-weight: normal;

  border: 2px solid $color-font-interactive;
  border-radius: 5px;
  background-color: $color-background-card;
  outline: none;

  transition: $default-transition-settings;
  cursor: pointer;

  svg {
    stroke: #000;

    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    color: #fff;

    border-color: $color-font-interactive-dark;
    background-color: $color-background-notification;

    svg {
      stroke: #fff;
    }
  }

  &--dragged {
    color: $color-font-passive;

    border: 2px dashed $color-font-passive;
    background-color: transparent;

    svg {
      stroke: $color-font-passive;
    }

    &:hover,
    &:focus {
      color: $color-font-passive;

      border: 2px dashed $color-font-passive;
      background-color: transparent;
  
      svg {
        stroke: $color-font-passive;
      }
    }
  }
}

/* ----------- competence-template styles ----------- */

.competence__template {
  display: flex;
  flex-direction: column;
  padding: 15px;

  border: none;
  border-radius: 5px;
  background-color: $color-background-card;
  box-shadow: 4px 4px 3px 1px rgba(34, 60, 80, 0.2);

  outline: none;
}

.competence__template-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;

  & > h4 {
    margin: 0;

    font-size: 16px;
    color: $color-font-interactive;
  }
}

.competence__template-actions-container {
  display: flex;
}

.competence__template-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
  padding: 5px;

  font-size: 14px;

  border-radius: 5px;
}

.competence__template-desciption-text {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin: 0;
  margin-top: 8px;
  padding: 5px 8px;

  text-align: left;

  background-color: #fff;
}

.competence__template-decoration-container {
  display: flex;
  align-items: center;
}

.competence__template-decoration {
  display: block;
  width: 15px;
  height: 15px;
  margin-right: 10px;

  border-radius: 50%;
  border: 1px solid $color-timetable-border;
}

.competence__template-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  padding: 2rem;

  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.competence__template-container {
  display: flex;
  flex-direction: column;
  width: 35vw;
  min-width: 350px;
  max-width: 750px;
  margin-right: 3rem;

  background-color: $color-background-card;
  border-radius: 5px;
  border: 2px solid $color-font-interactive-dark;

  overflow: hidden;

  & > h3 {
    padding: 1rem;

    border-bottom: 1px solid $color-font-passive;
  }

  & > button {
    display: none;
  }

  & .competence__template-cards-empty {
    margin: 1rem auto;
  }
}

.competence__template-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.competence__template-fieldset {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 1rem;

  border: none;
  background-color: #fff;
}

.competence__template-download-container {
  display: flex;
  align-items: center;
  margin-left: auto;

  & > span {
    display: none;
  }
}

.competence__template-input {
  width: 75%;
  margin-bottom: 1rem;
  padding: 8px 10px;

  font-size: 18px;
  font-weight: bold;
  color: $color-font-interactive;

  border: none;
  background-color: transparent;
  border-bottom: 2px solid $color-font-passive;

  outline: none;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    border-color: $color-font-interactive-dark;
  }

  &::placeholder {
    color: $color-font-passive;
  }

  &:valid:not(:placeholder-shown) {
    border-color: $color-font-interactive;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.15s all ease-in-out;
  }

  &--error {
    border-color: $color-font-error;

    &::placeholder {
      color: $color-font-error;
      opacity: 0.6;
    }
  }

  &--textarea {
    width: 90%;
    margin-left: auto;

    font-size: 16px;
    font-weight: normal;

    resize: none;
  }
}

.competence__template-select {
  border-color: red;
}

.competence__select-container {
  width: 55%;

  label {
    display: block;
    margin-bottom: 5px;
    padding-left: 5px;

    font-size: 12px;
  }
}

.competence__template-list-container {
  position: relative;
  max-height: 65vh;

  overflow: scroll;
  overflow-x: hidden;

  &--dragged {
    background-color: $color-font-interactive-hover;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.competence__template-list {
  width: 100%;
  flex-grow: 1;

  transition: $default-transition-settings;
}

.competence__template-item {
  display: flex;
  align-items: stretch;
  width: 100%;

  background-color: $color-background-salary;
  border-top: 1px solid $color-font-passive;

  &:last-child {
    border-bottom: 1px solid $color-font-passive;
  }
}

.competence__template-card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  border: 1px solid $color-font-passive;
  border-radius: 5px;
  background-color: $color-background-card;
  overflow: hidden;
}

.competence__template-accordion-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;

  cursor: pointer;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    background-color:#D4E2ED;
  }

  h4 {
    margin: 0;

    color: #000;
  }

  svg {
    margin-right: 1rem;

    stroke: #000;
  }
}

.competence__template-checkmark {
  position: relative;

  display: block;
  width: 30px;
  height: 30px;

  &::before {
    content: "+";

    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    font-size: 20px;
    font-weight: bold;
    color: $color-font-interactive;

    transition: $default-transition-settings;
  }
}

.competence__template-accordion {
  position: absolute;
  margin: 0;

  opacity: 0;

  &:checked ~ .competence__template-features {
    display: block;
    animation: fadeIn 0.7s;
    max-height: 100em;
    padding: 15px;

    opacity: 1;
    pointer-events: auto;
  }

  &:checked ~ .competence__template-checkmark {
    &::before {
      content: "-";

      color: $color-font-interactive-dark;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.competence__template-features {
  display: none;
  max-height: 0;

  opacity: 0;
  pointer-events: none;

  transition: $default-transition-settings;
}

.competence__template-feature {
  position: relative;
  margin-bottom: 10px;
  padding-left: 25px;

  color: $color-font-aside;

  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    content: "";

    position: absolute;
    left: 0;
    top: 50%;

    display: block;
    width: 10px;
    height: 10px;

    border-radius: 50%;
    background-color: #0978BF;

    transform: translateY(-50%);
  }
}

.competence__template-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: auto;
  padding: 0;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  transition: $default-transition-settings;

  svg {
    width: 80%;
    margin: 0;

    fill: #000;
    transition: $default-transition-settings;
  }

  &--sleep {
    margin-left: 1rem;
    border: none;

    svg {
      transform: rotate(90deg);
    }
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive-dark;
    }
  }
}

.competence__template-cards-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 35vw;

  text-transform: capitalize;

  & > label {
    margin-bottom: 5px;

    text-align: right;
    font-size: 22px;
    font-weight: bold;
    color: $color-font-interactive-dark;
  }

  & > h3 {
    margin-bottom: 1rem;

    font-size: 24px;
    color: $color-font-interactive-dark;
  }

  & > p {
    margin-bottom: 1rem;
  }

  & > button {
    width: 25%;
    max-width: 400px;
    margin-left: auto;
  }
}

.competence__template-cards-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  margin-left: auto;
  padding-right: 8px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.competence__template-submit {
  min-width: 260px;
  margin-top: 1rem;
}

.competence__template-cards-item {
  margin-left: 1rem;
  margin-bottom: 15px;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.competence__template-cards-empty {
  display: block;
  width: 90%;
  max-width: 800px;
  margin-left: auto;
  padding: 2rem;

  font-size: 20px;
  font-weight: bold;
  color: $color-status-progress;
  text-align: center;

  border: 2px dashed $color-status-progress;
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .competence__template-container {
    min-width: 340px;
    max-height: none;
    margin-right: 1.5rem;
  }

  .competence__select-container {
    width: 100%;
  }

  .competence__template-card-header {
    padding: 8px;
  
    h4 {
      font-size: 14px;
    }
  
    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  .competence__card-container {
    padding: 10px;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .competence {
    height: 100%;

    & > .loading-container {
      position: absolute;
      top: 60%;
    }
  }

  .competence__template-form {
    flex-wrap: wrap;
    height: auto;
    padding: 15px;
  }

  .competence__template-cards-container {
    order: 0;
  
    width: 100%;
    margin-bottom: 1rem;

    & > label {
      text-align: center;
    }

    & > button {
      display: none;
    }
  }

  .competence__template-input {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;

    &--textarea {
      text-align: center;
    }
  }

  .competence__template-cards-list {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .competence__template-cards-item {
    width: 100%;
    min-width: 100%;
    margin: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .competence__template-cards-empty {
    width: 100%;
    margin: 0;
  }

  .competence__template-submit {
    margin: 0 auto;
  }

  .competence__template-container {
    order: 1;
  
    width: 100%;
    height: auto;
    max-width: 100%;
    min-width: 250px;
    margin: 0;

    border: none;
    background-color: transparent;
    box-shadow: none;

    overflow: hidden;

    & > button {
      display: flex;
    }
  }

  .competence__template-card-header {
    padding: 8px;
  
    h4 {
      font-size: 13px;
    }
  
    svg {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }

  .competence__template-card-button {
    width: 32px;
  }

  .competence__template-list {
    margin-bottom: 1rem;
  }

  .competence-section {
    padding-bottom: 0;
  }

  .competence__management-button {
    width: 50%;
    margin: 0;

    order: -1;
  }

  .competence__input-container {
    width: 100%;
    margin: 0;
    padding: 0 1rem;

    & > label {
      text-align: center;
    }
  }

  .competence__header-link {
    margin: 0 auto;
  }

  .competence__cards-list {
    justify-content: center;
    padding: 0 16px;
  }

  .competence__cards-item {
    flex-grow: 1;
    min-width: 300px;
    margin: 0;

    & > .competence__template {
      width: 100%;
    }
  }

  .competence__card {
    width: 100%;

    box-shadow: none;
  }
  
  .competence__simple-card {
    max-width: 100%;
  }

  .competence__card-name {
    width: 100%;

    text-align: center;
  }

  .competence__template-fieldset {
    margin-bottom: 1rem;
    padding: 0;
  }

  .competence__template-download-container {
    margin: 0 auto;

    & > span {
      display: block;
      margin-right: 1rem;

      font-weight: bold;
    }
  }

  .competence__card-form {
    flex-wrap: wrap;
    padding-top: 10px;
  }

  .competence__card-enter {
    width: 100%;
    margin-bottom: 1rem;
  }

  .competence__enter-container {
    & > button {
      margin: 0 auto;
    }
  }

  .competence__card-container {
    order: 2;

    width: 100%;
    max-height: none;
    margin: 0;

    border: none;
  }

  .competence__submit-button {
    margin: 1rem auto;
  }

  .competence__header-switch-container {
    width: 100%;
    order: -1;
  }
}
