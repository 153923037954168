/* ---------------------------------- desktop ---------------------------------- */

.semester-picker__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin-top: auto;
  padding: 5px;
  padding-bottom: 0;

  border: 2px solid $color-font-calendar-disabled;
  border-radius: 5px;

  & > span {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 5px 0;

    border-top: 1px solid $color-font-interactive;
  }
}

.semester-picker {
  width: 100%;
  height: 30px;
  padding-left: 0;

  font-size: 16px;
  text-align: center;
  color: $color-font-interactive;
  font-weight: bold;
  line-height: 30px;
  color: transparent;

  border: none;
  outline: none;

  cursor: pointer;
  transition: $default-transition-settings;
}

.semester-picker__content-container {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 50%;

  & > svg {
    position: absolute;
    top: 50%;
    left: calc(50% - 35px);

    height: 18px;
    width: auto;

    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .react-datepicker {
    box-shadow: (0px 0px 12px rgba(14, 81, 170, 0.3));
  }

  .react-datepicker-popper {
    width: 150px;
  }

  .react-datepicker {
    display: flex;
    width: 100%;
  }

  .react-datepicker-popper {
    top: 100% !important;
    left: 50% !important;

    transform: translateX(-50%) !important;
  }

  .react-datepicker__header {
    padding: 10px 0;

    font-size: 17px;
    color: $color-font-interactive;

    background-color: $color-background-card;
  }

  .react-datepicker__navigation {
    top: 4.5px;

    &--previous {
      left: -2px;
      border-right-color: $color-font-passive;

      &:hover,
      &:focus {
        border-right-color: $color-font-interactive;
      }
    }

    &--next {
      right: -2px;
      border-left-color: $color-font-passive;

      &:hover,
      &:focus {
        border-left-color: $color-font-interactive;
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__year--container {
    width: 100%;
  }

  .react-datepicker__year-wrapper {
    display: flex;
    justify-content: center;
    max-width: none;
    gap: 5px;
  }

  .react-datepicker__year-text {
    width: auto;
    margin: 0;
    padding: 2px 5px;

    font-size: 14px;

    transition: $default-transition-settings;

    &--selected {
      background-color: $color-font-interactive;
      font-weight: bold;
    }
  }
}

.semester-picker__content-decoration {
  position: absolute;

  font-weight: bold;
  color: $color-font-interactive;
  pointer-events: none;
}

.semester-picker__horizontal-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  font-size: 0;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;
  transition: $default-transition-settings;

  & > svg {
    height: 12px;
    width: auto;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-font-interactive;
    }
  }

  svg {
    fill: $color-font-main;
    transition: $default-transition-settings;
  }

  &:disabled {
    svg {
      fill: $color-font-calendar-disabled;
    }

    pointer-events: none;
  }

  &--prev {
    transform: rotate(180deg);
  }
}