/* ---------------------------------- desktop ---------------------------------- */

.desk-order-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;

  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;
  transition: $default-transition-settings;

  & > svg {
    width: 100%;
    height: auto;

    transition: $default-transition-settings;
  }

  &--down {
    transform: rotate(180deg) scale(-1, 1);
  }

  &:hover,
  &:focus {
    & > svg {
      stroke: $color-font-interactive;
    }
  }
}