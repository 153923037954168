/* ---------------------------------- desktop ---------------------------------- */

.timetable-section {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100%;

  overflow: hidden;
}

.timetable-section__header {
  display: flex;
  justify-content: flex-end;
}

.timetable-section__buttons-container {
  display: flex;

  & > .button {
    &:nth-child(2n) {
      border: 2px solid $color-font-calendar-disabled;
      border-top: none;
      border-bottom: none;
    }

    &--disabled:nth-child(2n) {
      background-color: $color-font-interactive;
    }
  }
}

.timetable-section__button {
  min-width: 235px;
}

.timetable__popup {
  display: flex;
  flex-direction: column;
  min-width: 960px;
  min-height: 720px;

  overflow: hidden;
}

.timetable__popup-list {
  display: flex;
  flex-wrap: wrap;
}

.timetable__input-item {
  min-width: 175px;
  width: 150px;
  margin-right: 25px;
  margin-bottom: 15px;

  &:first-child {
    width: 250px;
  }

  &--short {
    width: 175px;
  }

  &--time {
    margin-right: 1rem;
    width: 155px;
  
    .react-datepicker {
      & > .react-datepicker__triangle {
        &::before {
          display: none;
        }
      }
    }

    .react-datepicker__time-container {
      width: 155px;

      .react-datepicker__header {
        background-color: $color-background-input;
      }
  
      .react-datepicker__time-box {
        width: 100%;

        ul.react-datepicker__time-list {
          max-height: 100px;
          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }
        
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
        
          &::-webkit-scrollbar-thumb {
            background: $color-font-interactive;
          }
        
          &::-webkit-scrollbar-thumb:hover {
            background: $color-font-interactive;
          }
          
          li.react-datepicker__time-list-item {
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              transition: $default-transition-settings;
              background-color: $color-font-interactive-hover;
            }
          }

          li.react-datepicker__time-list-item--disabled {
            display: none;
          }

          li.react-datepicker__time-list-item--selected {
            background-color: $color-font-interactive;

            &:hover {
              background-color: $color-font-interactive;
            }
          }
        }
      }
    }
  }
}

.timetable__popup-from {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 520px;
  height: fit-content;
  min-width: 900px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }

  overflow-x: hidden;
}

.timetable__form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;

  font-size: 18px;
  font-weight: bold;
}

.timetable__fieldset-container {
  flex-grow: 1;
  margin-bottom: auto;
}

.timetable__secondary-fieldset-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.timetable__lesson-description {
  margin-bottom: 1rem;
  padding: 10px;

  border: 2px solid $color-font-warning;

  &::first-word {
    font-weight: bold;
  }
}

.timetable__lesson-info {
  width: 100%;
}

.timetable__checkbox-container {
  width: 100%;
  margin-bottom: 1rem;

  label {
    margin: 0;

    span {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.timetable__popup-fieldset {
  flex-grow: 1;
  max-width: 440px;
  padding: 1rem;

  border: 2px solid $color-font-interactive;

  legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: fit-content;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0 10px;

    font-size: 16px;
    text-transform: initial;
    font-weight: bold;
    color: $color-font-interactive;

    span {
      margin: 0 0.5rem;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &--general {
    width: 100%;
    max-width: 100%;
  }

  &--long {
    max-width: 800px;
  }

  &--class {
    max-width: 700px;
  }

  &--hidden {
    display: none;
  }
}

.timetable__button-container {
  display: flex;

  button {
    &:first-child {
      margin-right: 1rem;
    }
  }
}

.timetable-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.timetable-primary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}

.timetable-primary-exit {
  position: absolute;
}

.timetable-primary-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
}

.timetable-secondary-enter {
  transform: translateX(110%);
}

.timetable-secondary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}

.timetable-secondary-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}

.timetable__lesson-format-container {
  display: flex;
  flex-wrap: wrap;

  transition: $default-transition-settings;

  &--hidden {
    opacity: 0;
    pointer-events: none;
  }

  &--disabled {
    pointer-events: none;

    & > button:not(:disabled) {
      opacity: 0.5;
    }
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .timetable__popup {
    display: flex;
    flex-direction: column;
    min-width: auto;
    max-width: 600px;
    width: 80%;
    min-height: 730px;
  }

  .timetable__popup-from {
    min-width: auto;
    flex-grow: 1;
  }

  .timetable__popup-fieldset {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .timetable-section {
    margin-bottom: 50px;
    padding: 0;
  }
  
  .timetable-section__header {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .timetable__form-header {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-bottom: 1rem;

    span {
      width: 100%;
      text-align: center;
    }
  }

  .timetable-section__buttons-container {
    margin: 0;
    width: 100%;
    
    a,
    button {
      height: 58px;
      margin: 0;
      flex-grow: 1;
    }
  }

  .timetable__popup {
    width: 100%;
    min-height: 100%;
    height: 100vh;
    max-width: 420px;
    padding-bottom: 0;
  }

  .timetable__input-item {
    width: 100%;
    margin-right: 0;

    &:first-child {
      width: 100%;
    }
  }

  .timetable__secondary-fieldset-container {
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .timetable__fieldset-container {
    padding: 0 15px;
  }

  .timetable__lesson-format-container {
    width: 100%;

    button {
      flex-grow: 1;
      min-width: auto;
    }
  }

  .timetable__popup-fieldset {
    width: 100%;

    legend {
      justify-content: center;
      margin-bottom: 1rem;

      text-align: center;

      span {
        &:nth-child(2n) {
          margin: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 3rem;
    }
  }

  .timetable__button-container {
    justify-content: center;
  }

  .timetable__popup-from {
    padding: 0;
    padding-bottom: 25px;
  }

  .timetable__checkbox-container {
    label {
      margin: 0 auto;
    }
  }
}
