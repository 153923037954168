/* ---------------------------------- desktop ---------------------------------- */

.table {
  width: 100%;
  border-spacing: 0;

  tbody {
    .table__tr {
      transition: 0.3s ease-in-out all;

      &:nth-child(2n + 1) {
        background-color: $color-background-datepicker-disabled;
      }
    
      &:hover {
        background-color: $color-font-interactive-hover;
      }
    }
  }
}

.table__th {
  width: 25%;
  height: 52px;
  padding: 0 5px;

  color: $color-font-interactive;
  text-align: left;
  font-weight: bold;
  text-transform: capitalize;

  overflow: hidden;
  text-overflow: ellipsis;

  border-bottom: 1px solid $color-table-border;

  &:last-child {
    text-align: right;
  }
}

.table__th-button {
  display: flex;
  align-items: center;

  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;

  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    .table__th-direction {
      border-top: 6px solid $color-font-interactive;
    }
  }
}

.table__th-direction {
  width: 0;
  height: 0;
  margin-top: 2px;
  margin-left: 0.5rem;
  padding: 0;

  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid $color-font-passive;
  border-bottom: none;
  outline: none;
  background-color: transparent;

  transition: $default-transition-settings;

  &--reverse {
    transform: rotate(180deg);
  }
}

.table__td {
  height: 52px;
  padding: 0 5px;

  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid $color-table-border;

  span {
    display: block;
    max-width: 250px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--notcap {
    text-transform: none;
    max-width: 150px;
  }

  &:last-child {
    text-align: right;
    max-width: 50px;

    .table__actions-sample {
      text-align: end;
    }
  }
}

.table__checkbox {
  &:focus + label {
    span {
      color: $color-font-interactive;
    }

    .svg-checkbox__box {
      stroke: $color-font-interactive;
    }
  }

  &:checked + label {
    .svg-checkbox__box {
      fill: $color-font-interactive;
      stroke-dashoffset: 320;
    }
  
    .svg-checkbox__check {
      stroke-dashoffset: 0;
    }
  }
}

.table__checkbox-label {
  font-size: 0;

  &:hover {
    .svg-checkbox__box {
      stroke: $color-font-interactive;
    }
  }
}

.table__checkbox-svg {
  width: 30px;
  height: 30px;

  cursor: pointer;

  .svg-checkbox__box {
    fill: #ffffff;
    stroke: $color-font-passive;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 320;
    stroke-dashoffset: 0;

    transition-delay: 0s, 0s, 0.3s;
    transition-property: stroke, stroke-dashoffset, fill;
    transition-duration: 0.3s, 0.45s, 0.4s;
  }

  .svg-checkbox__check {
    fill: none;
    stroke: #ffffff;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 80;
    stroke-dashoffset: 80;

    transition: stroke-dashoffset 0.2s linear;
  }
}

.table__color-block {
  position: relative;

  display: flex;
  width: 32px;
  height: 32px;

  font-size: 0;

  border: 1px solid $color-font-passive;
  border-radius: 50%;
}

.table__actions-sample {
  position: relative;

  display: flex;
  justify-content: flex-end;
}

.table__sample-burger {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    fill: $color-font-interactive;
    transition: $default-transition-settings;
  }

  &:hover {
    svg {
      fill: $color-input-hover;
    }
  }

  &:focus {
    svg {
      circle {
        r: 3.7;
        fill: $color-input-hover;
        transition: $default-transition-settings;
      }
    }
  }
}

.table__sample-list {
  position: absolute;
  top: -250%;
  right: 40px;

  margin: 0;
  padding: 0;

  list-style: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;
}

.table__sample-item {
  display: flex;
  align-items: center;
  width: 170px;
  height: 42px;
  padding: 8px 11px;

  border-bottom: 1px solid $color-table-border;
  background-color: #ffffff;

  &:last-child {
    border-bottom: none;
  }
}

.table__action-button {
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  color: black;
  font-size: 14px;

  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  transition: $default-transition-settings;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    
    fill: $color-font-passive;

    transition: $default-transition-settings;
  }

  span {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:hover,
  &:focus {
    color: $color-font-interactive;

    svg {
      fill: $color-font-interactive;
    }
  }

  &--stroke {
    svg {
      stroke: $color-font-passive;
      fill: transparent;
    }

    &:hover,
    &:focus {
      color: $color-font-interactive;
  
      svg {
        stroke: $color-font-interactive;
        fill: transparent;
      }
    }
  }
}

.table__date-screen {
  display: flex;
  flex-direction: column;

  span {
    &:first-child {
      display: block;
      margin-bottom: 5px;

      font-weight: bold;
    }
  }
}

.table__button-cancel {
  padding: 0;

  font-size: 16px;
  font-weight: bold;
  color: $color-require;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  transition: $default-transition-settings;

  &:hover,
  &:focus {
    color: $color-warning;
  }

  &:disabled {
    color: $color-font-calendar-disabled;
    pointer-events: none;
  }
}

/*________ CSSTransition classes  ________*/

.table__tr-animation-enter {
  opacity: 0;
}

.table__tr-animation-enter-active {
  opacity: 1;
  transition: all 0.5s ease;
}

.table__tr-animation-exit-active {
  opacity: 0;
  
  transition: all 0.5s ease;
}

.table__tr-animation-exit-done {
  opacity: 0;
}

.table__action-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  text-align: right;

  color: $color-font-interactive;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
  }

  .table__actions-sample {
    position: initial;
    padding: 0;
  }

  .table__sample-list {
    position: absolute;
    top: -100%;
    left: 50%;
  
    width: 280px;
    margin: 0;
    padding: 0;
  
    list-style: none;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .table__sample-item {
    width: 100%;
  }

  thead .table__tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table__tr {
    display: block;
    width: 100%;
    max-width: 100%;

    &:nth-child(2n) {
      background-color: $color-background-salary;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .table__td {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    max-width: 100%;
    height: auto;
    padding: 5px 0;
    padding-left: 50%;
    padding-right: 5px;

    text-align: center;
  
    border-bottom: 1px solid $color-table-border;

    &::before {
      content: attr(data-name);

      position: absolute;
			top: 0;
			left: 15px;

      display: flex;
      align-items: center;
			width: 45%;
      height: 100%;
			padding-right: 10px;
			white-space: nowrap;
    }

    &:last-child {
      max-width: 100%;
      padding-right: 0;
    }
  }
}
