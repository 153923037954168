/* ---------------------------------- desktop ---------------------------------- */

.global-section {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 2rem;

  & > .loading-container {
    left: 50%;
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .global-section {
    padding: 1rem;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .global-section {
    padding: 0;

    & > .loading-container {
      top: 50%;
    }
  }
}