/* ---------------------------------- desktop ---------------------------------- */

.timetable-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.timetable-section__buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.timetable-section__management-container {
  display: flex;
}

.timetable__download-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: 5px;
  padding-left: 0;
  padding-right: 17px;

  overflow: hidden;
  z-index: 0;
}

.timetable__status {
  max-width: 90px;
  padding: 5px 8px;
  border-radius: 17.5px;
  text-align: center;
}

.isConfirmed {
  background-color: #1EC103;
}

.inProgress {
  background-color: #E09400;
}

.archived {
  background-color: #BFBFBF;
}

.timetable__table-th {
  width: 50%;
}

.timetable__popup-fieldset {
  width: 100%;
  max-width: 100%;
}

.formError {
  padding: 10px 17px;
  background-color: #EED3D3;
  margin-bottom: 15px;
}

.plan-lesson__popup {
  z-index: 10;
  min-height: 750px;
}

.lessonPlan__input-list {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}

.lessonPlan__input-list-item {
  width: 40%;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:last-child {
    margin-right: 0;
  }
}

.lessonPlan__input-item {
  margin-bottom: 15px;

  svg {
    fill: $color-font-passive
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.room-input-item {
  width: 60%;
}

.lessonPlan__input-time-list {
  display: flex;
}

.lessonPlan__input-time-item {
  width: calc(50% - 10px);
  margin-right: 20px;
  margin-bottom: 0;

  &:last-child {
    margin-right: 0;
  }

  .react-datepicker__time-list-item--disabled {
    display: none;
  }
}

.lessonPlan__input-item {
  position: relative;
}


.input-container__clock-svg {
  position: absolute;
  width: 25px;
  height: 25px;
  right: 10px;
  top: 50%;
  transform: translateY(-10%);
}

/*header*/


.timetable-section__timetable-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}

.timetable-section__timetable-header-filter {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.timetable-section__timetable-selects {
  margin-bottom: 20px;
}

.timetable-section__input-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: 30px;


}

.timetable-section__input-item {
  min-width: 200px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

.timetable-section__timetable-week-btns-list {
  display: flex;
  margin-right: 10px;
  flex-wrap: wrap;
}

.timetable-section__timetable-week-btn {
  font-size: 14px;
  padding: 7px 12px;
  background: #fff;
  outline: 1px solid $color-icon-common;
  border: none;
}

.timetable-section__timetable-week-btns-item {
  margin-bottom: 20px;

  &:nth-child(5) {
    margin-right: 20px;
  }
}


.timetable-section__timetable-week-btn-active {
  background: $color-background-input;
}

.timetable-section__timetable-header-cofirm-btn {
  margin-bottom: 20px;
}


.timetable-section__timetable-view {
  font-size: 14px;
  color: $color-font-interactive;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.timetable-section__timetable-pdf {
  margin-right: 25px;
}

.timetable-section__timetable-pdf, .timetable-section__timetable-copy {
  align-items: center;
  display: flex;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.timetable-section__timetable-copy-text, .timetable-section__timetable-pdf-text {
  margin-left: 10px;
}


/*table*/

.timetable__table {
  width: 100%;
}


.timetable__table-day-of-week {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
  height: auto;
}

.timetable__table-day__time-list, .timetable__table-day__class-lessons-list {
  border-right: 1px solid $color-timetable-border;
  width: 106px;
}

.timetable__table-day-name, .timetable__table-day__class-name {
  border-top: 1px solid $color-timetable-border;
  border-bottom: 1px solid $color-timetable-border;
  height: 25px;
  padding-left: 15px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.timetable__table-day-name {
  color: #FFF;
  background-color: $color-background-table-day-header;
}

.timetable__table-day__class-name {
  color: #000;
  background-color: $color-background-table-class-header;
}

.timetable__table-day__lessons {
  background: #EEEEF8;
  border-right: 1px solid $color-timetable-border;
  width: 106px;
  height: 100%;
}

.timetable__table-lesson {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.empty-header {
  flex: 1;
  min-width: 0;
  background: #EEEEF8;

  div {
    padding: 0;
  }
}

.timetable__table-day-time {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  background-color: $color-background-table-hours;
  height: 30px;
  border-bottom: 1px solid black;

  &:last-child {
    border-bottom: 0;
  }
}

.timetable__table-scroll-wrap {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-background-table-day-header;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-background-table-day-header;
  }
}

.timetable__table-day__lessons {
  position: relative;
  height: calc(100% - 25px);
}

.timetable__table-day__lesson-info {
  font-size: 14px;
  width: 90%;
  height: 90%;
  background: white;
  opacity: 0.5;
  overflow: hidden;
  padding: 3px;

  p {
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.timetable__table-day__lesson-subject {
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;

  filter: saturate(15);
}

.timetable__table-day__lesson-group {
  margin-bottom: 10px;
  color: #555555;

}

.timetable__table-day__lesson-teacher.inSample {
  margin-bottom: 10px;
}

.timetable__table-day__lesson-teacher, .timetable__table-day__lesson-time, .timetable__table-day__lesson-time, .timetable__table-day__lesson-group {
  color: #555555;

  span {
    color: #000
  }
}


/* ------- info sample ---------*/


.lesson__info-sample {
  font-size: 12px;
  z-index: 7;
  cursor: default;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  max-width: 540px;
  background: #fff;
  box-shadow: 0 0 10px rgb(0, 0, 0 / 50%);
}

.lesson__info-sample-header {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #EEEEEE;
}

.lesson__info-sample-icons {
  display: flex;
  align-items: flex-end;
}

.lesson__info-sample-icons-btn-container {
  position: relative;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  .lesson__info-sample-icons-btn {

    &:focus {
      svg {
        fill: $color-font-interactive;
      }
    }

    svg {
      &:hover {
        transition: 0.5s;
        fill: $color-font-interactive;
      }
    }
  }

  button {
    background: none;
    border: none;
    width: min-content;
    height: min-content;
    padding: 0;
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #555555;

  }
}

.lesson__info-sample-content {
  padding: 5px 0 20px 20px;
}

.lesson__info-sample-edit-sample {
  font-size: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: #fff;
  width: 110px;
  position: absolute;
  top: 100%;
  left: -50%;
  z-index: 2;
}

.lesson__info-sample-edit-sample-list {
  width: 100%
}

.lesson__info-sample-edit-sample-item {
  width: 100%;
  border-bottom: 1px solid $color-popup-border;

  &:hover {
    background: $color-sample-font;
  }

  &:last-child {
    border-bottom: 0;
  }

  .lesson__info-sample-edit-sample-button {
    width: 100%;
    padding: 8px;
    text-align: start;
    color: $color-sample-font;

    &:hover {
      color: #fff;
    }
  }
}


/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .timetable-section__management-container {
    margin-bottom: 1rem;
  }

  .lessonPlan__input-list-item {
    width: calc(50% - 15px);

    &:first-child {
      margin-right: 30px;
    }
  }

}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .timetable-section {
    padding: 10px;
  }

  .lesson__info-sample {
    width: 100%;
  }

  .timetable-section__buttons-container {
    margin-bottom: 1rem;

    & > button {
      margin-left: 1rem;

      font-size: 12px;
    }
  }

  .lessonPlan__input-list {
    flex-direction: column;
  }

  .room-input-item {
    width: 100%;
  }

  .lessonPlan__input-list-item {
    width: 100%;

    &:first-child {
      margin-right: 0;
    }
  }

  .timetable-section__management-container {
    button {
      font-size: 14px;
    }
  }

  .timetable__download-container {
    position: relative;
    top: 0;

    align-items: center;
    margin-bottom: 1rem;
  }
}