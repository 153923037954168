/* ---------------------------------- desktop ----------------- */

.aside {
  position: relative;

  display: flex;
  align-items: stretch;
  width: var(--aside-max-width);
  max-width: var(--aside-max-width);
  color: $color-font-aside;
}

.aside__navigation {
  position: relative;

  width: 100%;
  flex-shrink: 0;
  margin: 0;
  padding: 0;

  background-color: #fff;
  border-right: 2px solid #eeeeee;
}

.aside__navigation-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aside__navigation-item {
  width: 100%;

  &:first-child {
    margin-bottom: 1rem;
  }
}

.aside__navigation-button {
  position: relative;

  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin: 0;
  padding: 0.5rem 1rem;

  border: 2px solid transparent;
  border-left: none;
  border-right: none;
  outline: none;
  background-color: transparent;
  color: $color-font-interactive;

  cursor: pointer;
  transition: 0.25s ease all;

  &:hover,
  &:focus-visible
  {
    border-color: $color-font-interactive-hover;
  }

  & > svg {
    width: 40px;
    height: auto;

    fill: #000;
    transition: 0.25s ease all;
  }

  &:disabled,
  &--open {
    border-color: $color-font-interactive;

    &:hover,
    &:focus-visible,
    &:focus-within {
      border-color: $color-font-interactive;
    }

    & > svg {
      fill: $color-font-interactive;
    }
  }

  &--active {
    border-color: $color-font-interactive-hover;

    &::after {
      content: "";

      position: absolute;
      right: 10px;
      top: 50%;

      display: block;
      width: 2px;
      height: 80%;

      background-color: $color-icon-active;
      transform: translateY(-50%);
    }
  }

  &--hidden {
    display: none;
  }

  & > span {
    font-size: 17px;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.aside__content-container {
  position: relative;

  width: 100%;
  max-height: 0;
  padding: 0;

  color: #000;

  background-color: #fff;

  transition: $default-transition-settings;
  overflow: hidden;

  &--open {
    max-height: 480px;
  }

  &:focus-within {
    max-height: 480px;
  }
}

.aside__nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.aside__menu-button {
  display: none;
}

.aside__logo-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 10px;

  font-size: 18px;
  font-weight: bold;

  border-bottom: 2px solid #eeeeee;
  background-color: rgba(23, 115, 237, 0.05);

  & > svg {
    width: 42px;
    height: auto;
    margin: 0;
  }
}

.aside__logo {
  width: 32px;
  height: auto;
  margin-right: 15px;
}

.aside__list-container {
  padding: 1rem 10px;

  & > h3 {
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;

    text-transform: capitalize;
    color: $color-font-interactive;
    font-size: 18px;
  }
}

.aside__list {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  gap: 1rem;
  margin: 0;
  padding: 0;
  padding-left: 1rem;

  list-style: none;

  &::after {
    content: "";

    position: absolute;
    left: 5px;
    top: 0;

    display: block;
    width: 3px;
    height: 100%;

    background-color: $color-background-table-hours;
  }
}

.aside__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.7rem;
  width: 100%;

  & > h4 {
    margin: 0;
    margin-bottom: 5px;

    text-transform: capitalize;
    color: $color-font-interactive;
  }

  & > a {
    padding-left: 5px;

    font-size: 15px;
  }
}

.aside__link {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 5px;
  gap: 1rem;
  
  color: $color-font-main;
  text-transform: capitalize;

  outline: none;
  transition: ease-in-out all 0.25s;

  &::after {
    content: "";

    position: absolute;
    left: -11px;
    top: 0;

    display: block;
    width: 3px;
    height: 100%;

    background-color: transparent;
    transition: $default-transition-settings;
    z-index: 2;
  }

  svg {
    width: 32px;
    height: auto;
    max-height: 32px;

    fill: $color-font-main;
    stroke-width: 10;
    transition: $default-transition-settings;
  }

  &:hover,
  &:focus-visible {
    svg {
      fill: $color-icon-active;
    }
  }

  &--active {
    background-color:rgba(23, 115, 237, 0.08);
    
    & > span::after {
      width: 100%;
    }

    & > svg {
      fill: $color-font-interactive;
    }

    &:hover,
    &:focus-visible {
      & > svg {
        fill: $color-font-interactive;
      }
    }

    &::after {
      background-color: $color-font-interactive;
    }
  }
}

.aside__link-span {
  position: relative;

  display: block;
  max-width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: $default-transition-settings;

  &::after {
    content: "";

    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;

    display: block;
    width: 0%;
    height: 2px;
    background-color: #fff;
  }
}

/* ----------------------------------  768px - 1024px (tablet) ---------------------------------- */

@media (max-width: ($viewport--lg - 1)) {
  .aside {
    flex-shrink: 0;
    min-height: 100%;
    padding: 0;

    transition: $default-transition-settings;
    overflow: visible;
    z-index: 10;
  }

  .aside__logo-container {
    justify-content: center;
    height: 100%;

    & > svg {
      width: 80%;
      flex-shrink: 0;
    }

    & > span {
      display: none;
    }
  }

  .aside__navigation-item {
    position: relative;

    &:first-child {
      height: 76px;
      margin-bottom: 0;
    }
  }

  .aside__navigation-button {
    justify-content: center;
    padding: 1rem 0;

    & > span {
      display: none;
      font-size: 0;
    }

    &--active {
      border-color: $color-input-hover;
      &::after {
        display: none;
      }
    }

    &--hidden {
      display: flex;
    }
  
  }

  .aside__content-container {
    position: relative;
  
    width: 220px;
    box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
    pointer-events: none;
  
    color: #000;
  
    background-color: #fff;
  
    transition: $default-transition-settings;
    overflow: hidden;
  
    &--open {
      max-height: 480px;
      pointer-events: all;
    }
  }

  .aside__list-container {
    padding: 10px 5px;
  }

  .aside__list {
    gap: 10px;
  }

  .aside__item {
    width: fit-content;
    gap: 5px;
  }

}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .aside {
    position: absolute;

    display: block;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    max-width: 100%;
    padding: 0;

    transition: ease-in-out 0.4s all;
    transform: translateY(-120%);

    overflow: auto;
    background-color: $color-background-card;

    &--open {
      top: 0;

      transform: translateY(0);
    }
  }

  .aside__menu-button {
    position: absolute;
    top: 50%;
    right: 5px;
    z-index: 10;

    display: block;
    width: 42px;
    height: 42px;
    padding: 0;
  
    font-size: 0;
  
    border: none;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;

    transform: translateY(-50%);

    & > svg {
      fill: $color-font-interactive;
    }
  }

  .aside__logo-container {
    justify-content: flex-start;
    height: 100%;

    & > svg {
      height: 100%;
      width: auto;
      flex-shrink: 0;
    }

    & > span {
      display: inline-block;
    }
  }

  .aside__navigation-item {
    border-bottom: 1px solid #eeeeee;

    &:first-child {
      height: 76px;
      margin-bottom: 0;
    }
  }

  .aside__content-container {
    width: 100%;
    max-width: 100%;
    box-shadow: none;
  }

  .aside__item {
    width: 100%;
  }
}
