/* ---------------------------------- desktop ---------------------------------- */

.notification-section {
  display: flex;
  height: 100%;
  padding-bottom: 2rem;
}

.notification-container {
  position: relative;

  display: flex;
  align-items: center;
  margin-right: 25px;
}

.notification-button {
  position: relative;

  padding: 0;
  margin: 0;
  padding: 5px;

  font-size: 0;

  border-radius: 50%;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  transition: $default-transition-settings;

  span {
    position: absolute;
    top: -2px;
    right: -2px;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 14px;
    height: 14px;
    padding: 2px;

    font-size: 9px;
    font-weight: bold;
    color: #fff;

    border-radius: 50%;
    background-color: $color-font-interactive;

    transition: $default-transition-settings;
  }

  svg {
    fill: $color-icon-common;
    transition: $default-transition-settings;
  }

  &:hover {
    background-color: rgba(9%, 45%, 93%, 0.1);
  }
  
  &:focus {
    background-color: $color-background-disabled;

    svg {
      fill: $color-font-interactive;
    }

    span {
      opacity: 0;
    }
  }

  &--active {
    background-color: $color-background-disabled;

    svg {
      fill: $color-font-interactive;
    }

    span {
      opacity: 0;
    }
  }
}

.notification__overpopup {
  position: absolute;
  top: 50px;
  left: -166px;

  display: block;
  width: 360px;
  padding: 1rem;

  border-radius: 10px;
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  z-index: 6;

  &::after {
    content: "";

    position: absolute;
    left: 50%;
    top: -10px;

    display: block;
    width: 30px;
    height: 30px;

    border-radius: 50%;
    background-color: #fff;

    transform: translateX(-50%);
    z-index: -1;
  }
}

.notification__overpopup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 10px;
  border-bottom: 4px solid $color-background-input;

  h3 {
    margin: 0;
    padding: 0;


    font-size: 14px;
    text-transform: capitalize;
  }
}

.notification__read-button {
  color: $color-font-warning-main;

  border: none;
  outline: none;
  background-color: transparent;

  cursor: pointer;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    color: $color-font-interactive;
  }
}

.notification__list {
  max-height: 190px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-font-interactive;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-font-interactive;
  }
}

.notifications__content-card {
  position: relative;
}

.notification__empty {
  padding-top: 10px;

  font-size: 12px;
  text-align: center;
}

.notifications__link {
  position: relative;

  display: flex;
  align-items: inherit;
  padding: 10px 0;
  padding-left: 10px;

  color: inherit;

  border-bottom: 2px solid $color-background-input;
  transition: $default-transition-settings;
  outline: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    background-color: $color-table-border;
  }

  &::after {
    content: "";

    position: absolute;
    left: 0;
    top: 50%;

    display: block;
    width: 3px;
    height: 70%;

    transform: translateY(-50%);
  }

  &--progress {
    .notifications__content-status {
      svg {
        fill: $color-font-warning;
      }
    }
  }

  &--progress::after {
    background-color: $color-font-warning;
  }

  &--decline {
    .notifications__content-status {
      svg {
        fill: transparent;
      }
    }
  }

  &--decline::after {
    background-color: $color-font-error;
  }

  &--finished {
    .notifications__content-status {
      svg {
        fill: $color-status-confirmed;
      }
    }
  }

  &--finished::after {
    background-color: $color-status-confirmed;
  }
}

.notifications__content-status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    width: 35px;
    height: auto;
  }
}

.notifications__content-data {
  font-size: 12px;
  font-weight: bold;
  color: $color-font-interactive;
}

.notifications__content-description {
  max-width: 200px;
  max-height: 35px;

  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification__img-card {
  display: flex;
  align-items: flex-start;
  padding: 25px;

  border-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  background: rgb(124,136,184);
  background: linear-gradient(66deg, rgba(124,136,184,1) 15%, rgba(9,120,191,1) 65%);

  img {
    display: block;
    width: 600px;
    height: auto;
  }
}

.notification__time-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;

  background-color: $color-background-notification;
}

.notifications__details-day {
  font-size: 46px;
  font-weight: 600;
  color: #fff;

  opacity: 0.6;
}

.notifications__details-data {
  font-size: 36px;
  color: #fff;
}

.notification__details-card {
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 15px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  text-transform: inherit;

  button {
    margin: 0 auto;
    margin-top: 1rem;
    width: 40%;
    max-width: 350px;
    min-width: 200px;
  }
}

.notification__details-header {
  max-width: 450px;
  margin: 0 auto;
  margin-top: auto;
  padding: 5px;

  font-size: 32px;
  font-weight: bold;
  text-align: center;

  &--progress {
    color: $color-font-warning;
  }

  &--decline {
    color: $color-font-error;
  }

  &--finished {
    color: $color-status-confirmed;
  }
}

.notifications__details-status {
  margin-bottom: auto;
  padding: 1rem;
  text-align: center;

  svg {
    width: 65px;
    height: auto;
  }

  &--progress {
    svg {
      fill: $color-font-warning;
    }
  }

  &--decline {
    svg {
      fill: $color-font-error;
    }
  }

  &--finished {
    svg {
      fill: $color-status-confirmed;
    }
  }
}

.notification__problem-container {
  width: 90%;
  margin: 0 auto;
  margin-bottom: auto;
  padding: 15px;
}

.notification__student-card {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 25px;

  border-left: none;
  border-right: none;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;

    border-top: 1px dotted $color-font-passive;

    &:first-child {
      border-bottom: 1px dotted $color-font-passive;
    }

    &:last-child {
      margin-bottom: 0;

      font-weight: bold;
      border-bottom: 1px dotted $color-font-passive;
    }

    span {
      width: 42%;

      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        color: $color-font-interactive;
        text-transform: capitalize;
      }

      &:last-child {
        text-align: end;
      }
    }
  }
}

.notification__student-data {
  position: absolute;
  top: 0;
  right: 0;

  color: $color-font-interactive;
  font-weight: bold;
}

.notifications__read-button {
  position: absolute;
  top: 15px;
  right: 15px;

  svg {
    width: 15px;
    height: 15px;
  }

  z-index: 1;
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .notification-container {
    position: inherit;
  }

  .notification__problem-container {
    width: auto;
    margin: 0;
  }

  .notification__student-card {
    width: 100%;
    margin: 0;
    margin-bottom: 2rem;
  }

  .notification__overpopup {
    position: absolute;
    top: 55px;
    left: 50%;
  
    display: block;
    width: 80vw;
    padding: 1rem;
  
    border-radius: 5px;
    background: #fff;
  
    &::after {
      display: none;
    }

    transform: translateX(-50%);
  }

  .notification__list {
    max-height: 350px;
  }
}
