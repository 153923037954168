/* ---------------------------------- desktop ---------------------------------- */

.input-container {
  position: relative;

  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 4px;

    font-size: 12px;
    text-transform: capitalize;
    color: $color-font-interactive-dark;
    font-weight: bold;
  }

  &--capacity {
    max-width: 125px;
    margin-left: 25px;
  }

  &--code {
    margin-left: 25px;
  }

  &--color {
    position: relative;

    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;

    button {
      display: flex;
    }
  }

  &--datepicker {
    min-height: 90px;
  }
}

.input-container__requre {
  color: $color-require;
}

.input-container__input {
  width: 100%;
  height: 42px;
  padding: 0 1em;

  font-size: 14px;
  color: inherit;

  border: 2px solid $color-font-calendar-disabled;
  border-radius: 5px;
  outline: none;

  transition: $default-transition-settings;

  &:disabled {
    pointer-events: none;

    &:hover {
      border-color: $color-font-passive;
    }
  }

  &:hover {
    border-color: $color-input-hover;
  }

  &:read-only {
    background-color: rgba(239, 239, 239, 0.3);
    color: $color-font-passive;

    &:hover {
      border: 2px solid $color-font-passive;
    }

    &:focus {
      border-color: $color-font-passive;
    }
  }

  &:focus {
    border-color: $color-font-interactive;
  }

  &::placeholder {
    color: $color-font-passive;
  }

  &:valid:not(:placeholder-shown) {
    border-color: $color-confirme;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.15s all ease-in-out;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &--error {
    border-color: $color-require;

    &:hover,
    &:focus {
      border-color: $color-require;
    }

    &:valid:not(:placeholder-shown) {
      border-color: $color-require;
    }
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.input-container__validation {
  position: absolute;
  bottom: 10px;
  left: 5px;

  display: flex;
  align-items: center;
  width: fit-content;
  min-height: 22px;
  padding: 2px 8px;

  font-size: $font-size-small;
  color: #ffffff;

  background-color: $color-require;
  border-radius: 5px;
}

.input-container__textarea {
  width: 100%;
  height: 42px;
  padding: 10px 1em;

  border: 2px solid $color-font-calendar-disabled;
  outline: none;
  resize: none;

  font-family: inherit;
  font-size: 14px;
  color: inherit;
  font-weight: inherit;

  transition: $default-transition-settings;

  &::placeholder {
    color: $color-font-passive;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.15s all ease-in-out;
  }

  &:hover {
    border-color: $color-input-hover;
  }

  &:focus {
    border-color: $color-font-interactive;
  }

  &:valid:not(:placeholder-shown) {
    border-color: $color-confirme;
  }
}

.search-users__container {
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;

  svg {
    position: absolute;
    top: 50%;
    left: 12px;

    display: block;
    width: 18px;
    height: 18px;

    transform: translateY(-50%);
    stroke: $color-icon-common;
    transition: $default-transition-settings;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    min-width: 90px;

    text-transform: capitalize;

    &:disabled {
      background-color: $color-table-border;
      color: $color-icon-common;

      pointer-events: none;
    }
  }
}

.search-users__button {
  height: 42px;
  min-width: 90px;
  text-transform: capitalize;

  &:disabled {
    background-color: $color-table-border;
    color: $color-icon-common;

    pointer-events: none;
  }
}

.search-users__buttons-container {
  display: flex;

  button {
    padding: 0 8px;
    max-width: 125px;
  }
}

.search-users__input-container {
  flex-grow: 1;
}

.search-users__input {
  width: 100%;
  height: 42px;
  padding-left: 45px;

  font-size: $font-size-normal;

  border: 2px solid $color-font-calendar-disabled;
  border-radius: 5px;
  outline: none;

  transition: $default-transition-settings;

  &:disabled {
    background-color: $color-table-border;
    border-color: $color-table-border;

    pointer-events: none;

    &::placeholder {
      color: $color-icon-common;
    }
  }

  &:hover {
    border-color: $color-input-hover;
  }

  &:hover + svg {
    stroke: $color-input-hover;
  }

  &:focus {
    border-color: $color-font-interactive;
  }

  &:focus + svg {
    stroke: $color-font-interactive;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  &::placeholder {
    color: $color-font-passive;
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: 0.15s all ease-in-out;
  }

  &::-webkit-search-cancel-button{
    position:relative;
    right: 10px;

    cursor: pointer;
  }
}

// --- react-select

.input__selector-container {
  position: relative;

  width: 100%;
  min-height: 42px;

  &:valid:not(:placeholder-shown) {
    border-color: $color-confirme;
  }

  & .input-select__control {
    min-height: 42px;
    border: 2px solid $color-font-calendar-disabled;
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    transition: $default-transition-settings;

    &:hover {
      border-color: $color-input-hover;
    }

    &:focus {
      border-color: $color-font-interactive;
    }

    &:focus-within {
      border-color: $color-font-interactive;
    }
  }

  &--full > .input-select__control {
    border-color: $color-status-confirmed;
  }

  &--error > .input-select__control {
    border-color: $color-require;

    &:hover,
    &:focus {
      border-color: $color-require;
    }

    &:valid:not(:placeholder-shown) {
      border-color: $color-require;
    }
  }

  &--full-template > .input-select__control {
    border-color: $color-font-interactive;
  }

  & > .input-select__control--is-focused {
    outline: none;
    box-shadow: none;

    &:hover {
      border-color: $color-font-interactive;
    }

    &:focus {
      border-color: $color-font-interactive;
    }
  }

  &--error > .input-select__control--is-focused {
    outline: none;

    &:hover {
      border-color: $color-font-interactive;
    }

    &:focus {
      border-color: $color-font-interactive;
    }
  }

  &--round {
    .input-select__control {
      border-radius: 6px;
    }

    .input-select__value-container {
      padding-right: 3rem;

      .input-select__single-value {
        max-width: 82%;
      }
    }
  
    .input-select__indicator {
      svg {
        fill: $color-font-interactive;
        opacity: 0.7;
      }
    }
  }
}

.input-select--is-disabled {
  .input-select__control--is-disabled {
    & > .input-select__indicators {
      svg {
        fill: $color-calendar-switcher-disabled;
      }
    }
  }
}

.input-select__value-container {
  & > .input-select__placeholder {
    font-size: 14px;
    color: $color-font-passive;
  }
}

.input-select__indicators {
  position: absolute;
  top: 50%;
  right: 0;
  padding-right: 8px;

  transform: translate(0, -50%);

  & > .input-select__indicator {
    padding: 0;
  }
}

.input-select__single-value {
  font-size: 14px;
  color: $color-font-main;
  text-transform: capitalize;
}

.input-select__indicator-separator {
  display: none;
}

.input-select__menu {
  margin: 0;
  padding: 0;
  outline: none;

  & > .input-select__menu-list {
    max-height: 120px;
    margin: 0;
    padding: 0;

    z-index: 100;
    transition: $default-transition-settings;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $color-font-interactive;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: $color-font-interactive;
    }

    & > .input-select__option {
      cursor: pointer;

      text-transform: capitalize;
    }
  }

  .input-select__option {
    width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: $default-transition-settings;

    &--is-selected {
      background-color: $color-font-interactive;
      pointer-events: none;
    }
  }
}

.input-select__option {
  cursor: pointer;
}

// --- react-datepicker and BirtdayPicker component

.input-container__calendar-svg {
  position: absolute;
  top: 50%;
  right: 10px;

  display: block;
  width: 20px;
  height: 20px;

  transform: translate(0, -50%);
  transition: $default-transition-settings;
  pointer-events: none;
}

.input__datepicker-birtday-field {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;

  button {
    width: 25px;
    height: 100%;

    font-size: 16px;
    font-weight: bold;

    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: $color-font-interactive;
    }
  }

  select {
    height: 100%;
  }
}

.input-checkbox {
  &:focus + label {
    span {
      color: $color-font-interactive;
    }

    .svg-checkbox__box {
      stroke: $color-font-interactive;
    }
  }

  &:checked + label {
    .svg-checkbox__box {
      fill: $color-font-interactive;
      stroke-dashoffset: 320;
    }

    .svg-checkbox__check {
      stroke-dashoffset: 0;
    }
  }
}

.input-checkbox-label {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  font-size: 14px;
  color: $color-font-additional;

  span {
    display: block;
    margin-left: 1rem;
    max-width: 640px;
  }

  &:hover {
    .svg-checkbox__box {
      stroke: $color-font-interactive;
    }
  }
}

.input__checkbox-svg {
  width: 30px;
  height: 30px;

  cursor: pointer;

  .svg-checkbox__box {
    fill: #ffffff;
    stroke: $color-font-passive;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 320;
    stroke-dashoffset: 0;

    transition-delay: 0s, 0s, 0.3s;
    transition-property: stroke, stroke-dashoffset, fill;
    transition-duration: 0.3s, 0.45s, 0.4s;
  }

  .svg-checkbox__check {
    fill: none;
    stroke: #ffffff;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 80;
    stroke-dashoffset: 80;

    transition: stroke-dashoffset 0.2s linear;
  }
}

/* ----------------------------------  320px - 768px (mobile) ---------------------------------- */

@media (max-width: ($viewport--md - 1)) {
  .search-users__buttons-container {
    display: flex;
    justify-content: center;
  }

  .search-users__container {
    position: relative;

    display: flex;
    width: 100%;
    max-width: 100%;
    margin-right: 0;

    svg {
      position: absolute;
      top: 50%;
      left: 12px;

      display: block;
      width: 18px;
      height: 18px;

      transform: translateY(-50%);
      stroke: $color-icon-common;
      transition: $default-transition-settings;

    }
  }

  .search-users__input {
    width: 100%;
    border: none;
    border-bottom: 1px solid gainsboro;
  }
}
