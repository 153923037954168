/* ---------------------------------- desktop ---------------------------------- */

:root {
  --progress: 0%;
}

.download-button__info {
  display: flex;
  align-items: center;

  text-align: center;
}

.download-button__download-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;

  overflow: hidden;
}

/* CSSTransition classes  */

.download-button-animation-enter {
  position: absolute;

  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s ease;
}

.download-button-animation-enter-active {
  position: absolute;

  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease;
}

.download-button-animation-exit-active {
  position: absolute;

  transform: translateY(60px);
  transition: all 0.3s ease-out;
}

.download-button-animation-exit-done {
  top: 0;
  opacity: 0;
}

.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;

  color: $color-font-interactive;
  font-size: 0;

  border: none;
  background-color: transparent;
  outline: none;

  cursor: pointer;
  transition: $default-transition-settings;

  &:hover,
  &:focus {
    color: $color-font-interactive-dark;

    svg {
      fill: $color-font-interactive-dark;
    }
  }

  &:active {
    filter: blur(1px);
  }

  svg {
    width: 35px;
    height: auto;

    fill: $color-icon-common;

    transition: $default-transition-settings;
  }

  &:disabled {
    color: $color-font-passive;
    opacity: 0.5;

    pointer-events: none;

    svg {
      fill: $color-font-passive;
    }
  }
}

.download-button__persentage-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  font-size: 12px;
  font-weight: bold;
  color: #fff;

  border-radius: 50%;
  background-color: $color-font-aside;

  overflow-x: hidden;
  z-index: 1;

  &::after {
    content: '';

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    width: var(--progress);
    height: 100%;
    background-image: linear-gradient(-45deg, #92CE72 25%, #70C542 25%, #70C542 50%, #92CE72 50%, #92CE72 75%, #70C542 75%, #70C542);
    background-size: 50px 50px;
    border-radius: 25px;
    transition: all .6s cubic-bezier(0.4, 0.0, 0.2, 1);

    z-index: 0;
  }

  &--error {
    background-color: $color-font-error;
    transition: $default-transition-settings;

    &::after {
      display: none;
    }
  }

  & > span {
    &:first-child {
      text-align: center;
    }
  }
}

.download-button__persentage {
  position: absolute;
  left: 50%;
  top: 50%;

  width: 100px;
  margin-right: 10px;
  
  color: #fff;
  font-weight: bold;
  text-align: center;

  background-color: transparent;

  transform: translate(-50%, -50%);
  z-index: 1;
}

.download-button__status {
  margin-bottom: 5px;

  text-decoration:rgba(0, 0, 0, 0.5);

  &--error {
    font-weight: bold;
    color: $color-font-error;
  }
}