/* ---------------------------------- desktop ---------------------------------- */

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 18px;
  min-height: 42px;
  min-width: 110px;
  font-size: $font-size-regular;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: $color-font-interactive;
  cursor: pointer;

  transition: $default-transition-settings;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 2px white inset, 0px 0px 0px 3px $color-font-interactive;
  }

  &:active {
    box-shadow: 0px 0px 0px 2px white inset, 0px 0px 0px 4px $color-font-interactive;
  }

  &--cancel {
    background-color: $color-font-passive;
  }

  &--cancel:hover,
  &--cancel:focus {
    box-shadow: 0px 0px 0px 2px white inset, 0px 0px 0px 3px $color-font-passive;
  }

  &--cancel:active {
    box-shadow: 0px 0px 0px 2px white inset, 0px 0px 0px 4px $color-font-passive;
  }

  &--empty {
    color: inherit;

    border: 1px solid $color-icon-common;
    background-color: #ffffff;
  }

  &--empty:hover,
  &--empty:focus {
    box-shadow: 0px 0px 0px 1px white inset, 0px 0px 0px 3px $color-font-warning-main;
  }

  &--empty:active {
    box-shadow: 0px 0px 0px 2px white inset, 0px 0px 0px 4px $color-font-warning-main;
  }

  &--delete {
    min-width: 110px;
    background-color: $color-warning;
  }

  &--delete:hover,
  &--delete:focus {
    box-shadow: 0px 0px 0px 2px white inset, 0px 0px 0px 3px $color-warning;
  }

  &--confirm {
    background-color: $color-background-table-confirm-btn;
  }

  &--confirm:hover,
  &--confirm:focus {
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px white inset, 0px 0px 0px 3px $color-background-table-confirm-btn;
  }

  /*&--delete:disabled:hover,
  &--delete:disabled:focus {
  }*/

  &--delete:active {
    box-shadow: 0px 0px 0px 2px white inset, 0px 0px 0px 4px $color-font-warning-main;
  }


  &--management {
    position: relative;

    padding: 20px 12px;

    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    text-align: center;
    color: $color-font-disabled;

    border-radius: 0;
    background-color: $color-background-disabled;

    transition: $default-transition-settings;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  &--management::before,
  &--management::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: 1;
    transition: $default-transition-settings;
  }

  &--management::before {  
    opacity: 0;
    background: rgba($color-font-interactive, 0.1);
    transform: scale(0.1, 1);
    z-index: 1;
  }

  &--management:focus::before,
  &--management:hover::before {
    opacity: 1;
    transform: scale(1, 1);
  }

  &--management:active::before {
    background: rgba($color-font-interactive, 0.4);
  }

  &:disabled {
    color: $color-font-disabled;
    background-color: $color-font-warning-main;
    cursor: auto;

    &:hover {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &:disabled:hover::before {
    background: rgba($color-font-interactive, 0);
  }

  &--management:disabled {
    color: #ffffff;
    background-color: $color-font-interactive;
  }

  &--disabled {
    color: #ffffff;
    background-color: $color-font-interactive;

    cursor: auto;
    pointer-events: none;
  }
}